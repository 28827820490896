'use strict';
import {Map} from 'immutable';
import keymirror from 'keymirror';
import {FileModel} from 'lib/models';


export const constants = keymirror({
    FILES_STORE_FILE: null
});

let defaults = Map({
    file: null,
    file_name: null
});

export default (state = defaults, action) => {
    switch (action.type) {
        // add File to store
        case constants.FILES_STORE_FILE:
            // store file and file name without extension
            return state.set('file', action.file).set(
                'file_name', action.file ? new FileModel().getNameWithoutExtension(action.file.name) : null);

        default:
            return state;
    }
};
