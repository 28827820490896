'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUITable from '@material-ui/core/Table';


const styles = theme => ({
    // wraps the whole table
    tableWrapper: {
        overflowX: 'auto', // responsive scrollbar at the bottom
        // get rid of last bottom border of content (because Card have one)
        '& table tbody tr:last-child td': {
            borderBottom: '0'
        },
        '&.sticky': {
            position: 'relative',
            zIndex: '500'
        }
    },
    table: {
        tableLayout: 'fixed',
        minWidth: (props) => props.size ? `${props.size * 200}px` : '840px',
        '& th': {
            width: '100%',
            minWidth: '200px'
        }
    },
    sortable: {
        borderCollapse: 'separate' // separate border for visibility during drag
    }
});

/**
 * Extends Table component to change style
 */
class Table extends React.Component {
    render() {
        // Split some stuff from props
        let {size, sortable, classes, _classes, className, sticky, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, wrapper, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            classes.table, root, className, sortable ? classes.sortable : ''
        ].filter(Boolean).join(' ');
        let wrapperClasses = [
            classes.tableWrapper, wrapper, sticky ? 'sticky' : ''
        ].filter(Boolean).join(' ');

        return <div className={wrapperClasses}>
            <MUITable
                classes={{
                    root: rootClasses,
                    ...rest_of_classes
                }}
                {...rest_of_props} />
        </div>;
    }
}

Table = withStyles(styles)(Table);
export default Table;
