'use strict';

const messages = {
    // Errors
    'errors.general.title': 'Something went wrong',
    'errors.general.text': 'We already noticed the issue and we will try to fix it as soon as possible. For now you can try to refresh page.',
    'errors.unavailable.title': 'Portal is currently unavailable',
    'errors.unavailable.text': 'Please try to reload this page after a second.',
    'errors.notfound.title': '#404 Page Not Found',
    'errors.notfound.text': 'Sorry, but the page you are looking for was either not found or does not exist. Check your URL for errors or select other Page from the menu.',
    'errors.inapp.title': 'A problem has occurred!',
    'errors.inapp.description': 'There was an unexpected error in {error_identifier}(). We already noticed the issue and we will try to fix it as soon as possible.',
    'errors.inapp.action': 'Some data may be not properly displayed. You can try to refresh this page or close this dialog and continue using the app.',
    'errors.refresh': 'Refresh page',
    'errors.validator.required': 'Value is required',
    'errors.validator.url': 'Must be a valid URL',
    'errors.validator.email': 'Please enter a valid e-mail',
    'errors.validator.number': 'Must be a number',
    'errors.validator.hexcolor': 'Must be a valid hex color',
    'errors.validator.ipaddress': 'Must be a valid IP Address (IPv4 or IPv6)',
    'errors.validator.url_list_1': 'Must be a valid URL',
    'errors.validator.url_list_2': 'One of the URLs is not valid',
    'errors.validator.slug': 'Value must be URL-friendly slug',
    'errors.validator.datetime': 'Invalid date time',
    'errors.validator.generic': 'Value is invalid',
    // Actions
    'actions.more': 'More',
    'actions.create': 'Add',
    'actions.edit': 'Edit',
    'actions.upload': 'Upload',
    'actions.delete': 'Delete',
    'actions.remove': 'Remove',
    'actions.copy': 'Copy',
    'actions.logout': 'Logout',
    'actions.save': 'Save',
    'actions.select': 'Select',
    'actions.accept': 'Accept',
    'actions.download': 'Download',
    'actions.cancel': 'Cancel',
    'actions.close': 'Close',
    'actions.discard': 'Discard',
    'actions.back': 'Back',
    'actions.next': 'Next',
    'actions.resize': 'Resize',
    'actions.export_all': 'Export all',
    'actions.export_selected': 'Export selected',
    'actions.search': 'Search',
    // DropFile
    'dropfile.howto': 'Click or Drag & Drop File',
    'dropfile.rejected': 'File rejected, probably a bad file type',
    // Header
    'header.headline': 'Customer & Partner Center',
    'header.language': 'Language',
    'header.more.manage_profile': 'Manage Profile',
    'header.more.manage_users': 'Manage Users',
    'header.more.manage_company': 'Manage Company',
    // Menu
    'menu.preview': '(page preview)',
    // Sorting
    'sorting.undo': 'Revert changes',
    'sorting.save': 'Save changes',
    // Fields - ToSField (terms of services)
    'tosfield.description': 'I accept {link}*',
    'tosfield.description.link': 'Terms of Service',
    'tosfield.error.required': 'You have to accept Terms of Service',
    // Fields - IconField
    'iconfield.description': 'Take a look at all available icons on the official website: {link}',
    'iconfield.description.link': 'Material Design Icons',
    // Fields - SocialField
    'socialfield.link.facebook': 'Facebook',
    'socialfield.link.instagram': 'Instagram',
    'socialfield.link.linkedin': 'LinkedIn',
    'socialfield.link.medium': 'Medium',
    'socialfield.link.salesforce': 'Salesforce',
    'socialfield.link.twitter': 'Twitter',
    'socialfield.link.youtube': 'Youtube',
    // Fields - ColorField
    'colorfield.color.unknown': 'Custom color',
    'colorfield.color.red': 'Red',
    'colorfield.color.pink': 'Pink',
    'colorfield.color.purple': 'Purple',
    'colorfield.color.deep_purple': 'Deep Purple',
    'colorfield.color.indigo': 'Indigo',
    'colorfield.color.blue': 'Blue',
    'colorfield.color.light_blue': 'Light Blue',
    'colorfield.color.cyan': 'Cyan',
    'colorfield.color.teal': 'Teal',
    'colorfield.color.green': 'Green',
    'colorfield.color.light_green': 'Light Green',
    'colorfield.color.lime': 'Lime',
    'colorfield.color.orange': 'Orange',
    'colorfield.color.deep_orange': 'Deep Orange',
    'colorfield.color.brown': 'Brown',
    'colorfield.color.grey': 'Grey',
    'colorfield.color.blue_grey': 'Blue Grey',
    // Fields - PortletField
    'portletfield.type.html': 'HTML',
    'portletfield.type.social': 'Social',
    'portletfield.type.news': 'News',
    'portletfield.type.contact_us': 'Contact Us',
    'portletfield.type.contacts': 'Contacts',
    'portletfield.type.google_calendar': 'Google Calendar',
    'portletfield.type.gallery': 'Gallery',
    // Fields - Markdown
    'markdownfield.controls.headline': 'Headline',
    'markdownfield.controls.bold': 'Bold',
    'markdownfield.controls.italic': 'Italic',
    'markdownfield.help': 'This field uses Markdown, take a look at {link} to learn more about it.',
    'markdownfield.help.link': 'Markdown guide',
    'markdownfield.preview': 'Preview:',
    // Pagination
    'pagination.label.of': 'of', // e.g. 1-5 {of} 15 records
    'pagination.label.records': 'records',
    // Filters
    'fitlers.title': 'Filters',
    'filters.none': 'None',
    // Footer
    'footer.rights': 'All rights reserved',
    'footer.tos': 'Terms of Service',
    // Delete Dialog
    'deletedialog.title': 'Delete {item}',
    'deletedialog.description': 'Are you sure you want to delete {item}?',
    'deletedialog.description2': 'This action is permanent and cannot be undone.',
    'deletedialog.item': 'item',
    'deletedialog.multiple.title': 'Delete {items}',
    'deletedialog.multiple.description': 'Are you sure you want to delete {items}?',
    'deletedialog.multiple.items': 'items',
    // Terms of Services (ToS)
    'tos.notfound': 'We were not able to fetch Terms of Services from server. Please, try it later.',
    'tos.update.description': 'Our Terms of Service has changed. Please, review them before continuing on.',
    // AUTH - Login
    'login.title': 'Login',
    'login.subheader': 'To access Portal please fill your credentials.',
    'login.form.fields.email': 'E-mail',
    'login.form.fields.password': 'Password',
    'login.form.submit': 'Login',
    'login.forgottenpassword': 'Did you forget your password?',
    'login.register.title': "Don't have account?",
    'login.register.subheader': 'Simply click on button to join the Portal.',
    'login.register': 'Register',
    'login.error.invalid_credentials': 'Wrong email or password provided',
    // AUTH - Logout
    'logout.success': 'You have been successfully logged out. We hope to see you back soon!',
    'logout.expired': 'Your Login session has expired. Please login again.',
    // AUTH - Forgotten Password
    'forgottenpassword.title': 'Forgotten password',
    'forgottenpassword.subheader.1': 'Please fill your email address to reset your password',
    'forgottenpassword.subheader.2': 'Please enter and re-enter new password to your account',
    'forgottenpassword.form.fields.email': 'E-mail',
    'forgottenpassword.form.fields.password': 'New Password',
    'forgottenpassword.form.fields.password2': 'Confirm Password',
    'forgottenpassword.form.submit.1': 'Request password reset',
    'forgottenpassword.form.submit.2': 'Reset password',
    'forgottenpassword.success.title': 'Success!',
    'forgottenpassword.success.subheader': "Now it's time to check your inbox. You should get a link to create a new password shortly.",
    'forgottenpassword.success.login': 'Your password was successfully changed. We automatically sign you in!',
    'forgottenpassword.error.no_match': 'Passwords do not match',
    'forgottenpassword.error.invalid_token': 'The reset token was either invalid, or expired. Please, request a new one.',
    // AUTH - Registration
    'register.title': 'Registration',
    'register.subheader': 'Please fill some basic information about yourself and your company.',
    'register.section.account': 'Account',
    'register.section.company': 'Company',
    'register.section.secure': 'Secure your Account',
    'register.form.fields.email': 'E-mail',
    'register.form.fields.first_name': 'First name',
    'register.form.fields.last_name': 'Last name',
    'register.form.fields.company.name': 'Company',
    'register.form.fields.company.type': 'Company type',
    'register.form.fields.password': 'Password',
    'register.form.fields.password2': 'Confirm Password',
    'register.form.submit': 'Register',
    'register.success.title': 'Success!',
    'register.success.subheader': "Now it's time to check your inbox. You should get a confirmation link shortly.",
    'register.success.login': 'Your account was successfully confirmed. We automatically sign you in!',
    'register.error.no_match': 'Passwords do not match',
    'register.error.invalid_token': 'Registration token was either invalid, or expired. Register again or contact support.',
    // AUTH - Invitation
    'invitation.title': 'You have been invited to join Portal',
    'invitation.subheader': 'Please fill your details and we are ready to go!',
    'invitation.form.fields.first_name': 'First name',
    'invitation.form.fields.last_name': 'Last name',
    'invitation.form.fields.email': 'E-mail',
    'invitation.form.fields.emailconfirm.info': 'You will need to confirm your new e-mail address via link we sent you.',
    'invitation.form.fields.company': 'Company',
    'invitation.form.fields.password': 'Password',
    'invitation.form.fields.password2': 'Confirm Password',
    'invitation.form.submit': 'Finish registration',
    'invitation.error.no_match': 'Passwords do not match',
    'invitation.error.invalid_token': 'The invitation token was either invalid, or expired. Please, request a new invitation.',
    'invitation.error.used_token': "The invitation token was already used. Please reset your password with forgotten password if you don't remember your credentials.",
    'invitation.success': 'You are successfully registered into the Portal! We automatically sign you in!',
    // Remote Action
    'remoteaction.success': 'The remote action has been executed successfully.',
    'remoteaction.failure': 'The submitted remote action token is invalid. Please, request a new one.',
    'remoteaction.failure_used': 'The submitted remote action token has already been used. Please, request a new one.',
    'remoteaction.failure_expired': 'The submitted remote action token has expired and cannot be used. Please, request a new one.',
    // User - Detail
    'users.detail.title': 'Your Profile',
    'users.detail.subheader': 'Manage your User account.',
    'users.detail.tab.one': 'About',
    'users.detail.tab.two': 'Password',
    'users.detail.basic.title': 'Basic Information',
    'users.detail.basic.subheader': 'Please tell us something about yourself.',
    'users.detail.basic.form.fields.email': 'E-mail',
    'users.detail.basic.form.fields.first_name': 'First name',
    'users.detail.basic.form.fields.last_name': 'Last name',
    'users.detail.basic.form.fields.phone': 'Phone',
    'users.detail.basic.emailconfirm.info': 'You will need to confirm your new e-mail address via link we sent you.',
    'users.detail.basic.emailconfirm.success': 'We successfully changed your e-mail address.',
    'users.detail.basic.emailconfirm.failure': 'The email confirmation token was either invalid, or expired. Please, request a new one.',
    'users.detail.more.title': 'Additional Information',
    'users.detail.more.subheader': 'Optional more information about yourself.',
    'users.detail.more.form.fields.skype': 'Skype',
    'users.detail.more.form.fields.twitter': 'Twitter',
    'users.detail.more.form.fields.linkedin': 'LinkedIn',
    'users.detail.more.form.fields.xing': 'Xing',
    'users.detail.password.title': 'Manage Password',
    'users.detail.password.subheader': 'Change your current password.',
    'users.detail.password.form.fields.current_password': 'Current Password',
    'users.detail.password.form.fields.password': 'New Password',
    'users.detail.password.form.fields.password2': 'Confirm Password',
    'users.detail.password.form.submit': 'Change password',
    'users.detail.password.error.no_match': 'Passwords do not match',
    'users.detail.avatar.title': 'User Avatar',
    'users.detail.avatar.subheader': 'Add/change your profile picture.',
    // User - List
    'users.list.title': 'Users management',
    'users.list.subheader': 'Manage Users in your company.',
    'users.list.table.name': 'Name',
    'users.list.table.email': 'E-mail',
    'users.list.table.created_at': 'Created',
    'users.list.table.last_access': 'Last Logged',
    'users.list.table.last_access.never': 'Never',
    'users.list.table.admin': 'Admin',
    'users.list.table.actions': 'Actions',
    'users.list.invite.title': 'Invite people',
    'users.list.invite.subheader': 'Invite people to join Portal.',
    'users.list.invite.form.fields.email': 'E-mail',
    'users.list.invite.form.fields.first_name': 'First name',
    'users.list.invite.form.fields.last_name': 'Last name',
    'users.list.invite.form.submit': 'Invite User',
    'users.list.success': 'We have successfully invited user to join the Portal. He will receive e-mail with access link where he can set up his password and login.',
    'users.list.deletedialog.item': 'User',
    'users.list.deletedialog.items': 'Users',
    // Company - Detail
    'company.detail.title': 'Company Detail',
    'company.detail.subheader': 'Manage your company details.',
    'company.detail.basic.form.fields.name': 'Name',
    'company.detail.basic.form.fields.email': 'E-mail',
    'company.detail.basic.form.fields.phone': 'Phone',
    'company.detail.basic.form.fields.website_url': 'Website URL',
    'company.detail.basic.form.fields.vat_number': 'VAT ID',
    'company.detail.address.title': 'Address',
    'company.detail.address.subheader': 'Headquarters of your company.',
    'company.detail.address.form.fields.country': 'Country',
    'company.detail.address.form.fields.city': 'City',
    'company.detail.address.form.fields.street': 'Street',
    'company.detail.address.form.fields.code': 'ZIP Code',
    'company.detail.logo.title': 'Company Logo',
    'company.detail.logo.subheader': 'Add/change your company Logo.',
    // Portlets General
    'portlets.general.fetch_error': 'We were not able to fetch data.',
    // Portlets - Calendar
    'portlets.calendar.today': 'Today',
    'portlets.calendar.calendar': 'Events from calendar:',
    'portlets.calendar.calendars': 'Events from calendars:',
    'portlets.calendar.events.upcoming': 'Latest 5 events since {date} to the end of the month',
    'portlets.calendar.events.detail.time': 'Time',
    'portlets.calendar.events.detail.location': 'Location',
    'portlets.calendar.events.detail.description': 'Information',
    'portlets.calendar.events.detail.link': 'Open in Google Calendar',
    'portlets.calendar.events.empty': 'There are no events.',
    // Portlets - Social
    'portlets.social.network.facebook': 'Facebook',
    'portlets.social.network.instagram': 'Instagram',
    'portlets.social.network.linkedin': 'LinkedIn',
    'portlets.social.network.medium': 'Medium',
    'portlets.social.network.salesforce': 'Salesforce',
    'portlets.social.network.twitter': 'Twitter',
    'portlets.social.network.youtube': 'Youtube',
    // Portlets - Files
    'files.list.empty.title': 'This Folder is empty',
    'files.list.empty.description': 'There are no Files in this Folder!',
    // Portlets - Partner Level
    'portlets.partner_level.title': 'Your Partner Level is:',
    // Portlets - Leads
    'portlets.leads.table.first_name': 'First name',
    'portlets.leads.table.last_name': 'Last name',
    'portlets.leads.table.created_at': 'Created at',
    'portlets.leads.table.location': 'Location',
    'portlets.leads.table.company': 'Company',
    'portlets.leads.table.contact_made': 'Contacted',
    'portlets.leads.table.empty': 'No leads available in your area at this time. Please, check back again later.',
    'portlets.leads.dialog.title': 'Contact Lead',
    'portlets.leads.dialog.info': 'You are contacting {name} from {location}.',
    'portlets.leads.dialog.form.fields.text': 'Message',
    'portlets.leads.dialog.submit': 'Send Message',
    'portlets.leads.dialog.success': 'Your message has been successfully sent!',
    // Portlets - Submissions
    'portlets.submissions.list.title': 'Submissions',
    'portlets.submissions.list.subheader': 'List of your Submissions.',
    'portlets.submissions.list.table.name': 'Transaction Number',
    'portlets.submissions.list.table.created_at': 'Created',
    'portlets.submissions.list.table.status': 'Status',
    'portlets.submissions.list.table.status.approved': 'Approved',
    'portlets.submissions.list.table.status.pending': 'Pending',
    'portlets.submissions.list.table.status.declined': 'Declined',
    'portlets.submissions.list.table.rebate': 'Rebate',
    'portlets.submissions.detail.title': 'Add Submission',
    'portlets.submissions.detail.subheader': 'Apply for a rebate.',
    'portlets.submissions.detail.step.phones': 'Choose Phones',
    'portlets.submissions.detail.step.invoice': 'Upload your Invoice',
    'portlets.submissions.detail.step.confirm': 'Confirmation',
    'portlets.submissions.detail.phones.distributor': '- Select your Distributor',
    'portlets.submissions.detail.phones.form.fields.distributor': 'Distributor',
    'portlets.submissions.detail.phones.products': '- Add Phones',
    'portlets.submissions.detail.phones.form.fields.products.product': 'Phone',
    'portlets.submissions.detail.phones.form.fields.products.amount': 'Amount',
    'portlets.submissions.detail.phones.form.fields.products.add': 'Add new Phone',
    'portlets.submissions.detail.phones.overview.table.product': 'Phone',
    'portlets.submissions.detail.phones.overview.table.amount': 'Amount',
    'portlets.submissions.detail.phones.overview.table.price': 'Price',
    'portlets.submissions.detail.phones.overview.table.empty': 'Select Phones first',
    'portlets.submissions.detail.phones.overview.title': 'Overview',
    'portlets.submissions.detail.phones.overview.total': 'Total: {value} €',
    'portlets.submissions.detail.invoice.form.fields.invoice.number': 'Invoice Number',
    'portlets.submissions.detail.invoice.form.fields.invoice.upload': 'Please upload your Invoice.',
    'portlets.submissions.detail.invoice.warning': 'Please note, only invoices dated 1 March 2018 or later will be accepted.',
    'portlets.submissions.detail.confirm.title': 'Invoice {invoice} from {distributor}',
    'portlets.submissions.detail.confirm.description': 'Please make sure that all data have been entered correctly.',
};

export default messages;
