'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Gold brick icon
 */
export default class Gold extends React.Component {
    static defaultProps = {
        viewBox: '0 0 56.592 56.592',
        color1: '#F3D55B',
        color2: '#F0C419',
        color3: '#F29C1F'
    };

    render() {
        let {color1, color2, color3, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <polygon style={{fill: color1}} points='56.592,18.698 18.931,54.086 18.303,37.431 50.453,8.359' />
            <polygon style={{fill: color2}} points='50.453,8.359 18.303,37.431 5.482,31.921 39.744,2.507' />
            <polygon style={{fill: color3}} points='18.931,54.086 0,44.146 5.482,31.921 18.303,37.431' />
        </SvgIcon>;
    }
}
