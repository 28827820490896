'use strict';
import {Map} from 'immutable';
import keymirror from 'keymirror';
import {listAsModel, User} from 'lib/models';


export const constants = keymirror({
    USER_USERS: null
});

let defaults = Map({
    users: null // null states indicates 'not loaded yet'
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets / removes Users
        case constants.USER_USERS:
            return state.set('users', action.data ? listAsModel(User, action.data) : defaults.get('users'));

        default:
            return state;
    }
};
