'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import CircularProgress from 'components/core/ui/mui/CircularProgress';
import MUIButton from '@material-ui/core/Button';


const styles = theme => ({
    buttonRoot: {
        // increase size
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        '&.outlined': {
            // lower padding due to border
            padding: `${theme.spacing(1) - 1}px ${(theme.spacing(2)) - 1}px`
        }
    },
    buttonLabel: {
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: 'none',
        // icon inside
        '& > svg, & > .icon': {
            fontSize: theme.typography.pxToRem(19), // lower size
            marginRight: `${theme.spacing(1)}px`, // space between
            '&:last-child': {
                marginRight: '0',
                marginLeft: `${theme.spacing(1)}px` // space between
            },
            // icon is alone
            '&:first-child:last-child': {
                marginLeft: '0',
                marginRight: '0'
            }
        },
        // loading visual
        '&.loading': {
            // hide icon and text
            '& > svg, & > .icon, & > span': {
                opacity: '0'
            }
        }
    },
    // .loader
    progressHolder: {
        position: 'absolute',
        top: `${theme.spacing(0.5)}px`,
        left: '0',
        right: '0',
        margin: '0 auto',
        fontSize: '0'
    },
    progress: {
        color: 'inherit'
    }
});

/**
 * Extends Button to simply add space between Icon and text
 */
class Button extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, children, loading, success, failure, postAnimation, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, label, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            classes.buttonRoot, root, className,
            rest_of_props.variant === 'outlined' ? ' outlined' : ''
        ].filter(Boolean).join(' ');
        let labelClasses = [
            classes.buttonLabel, label,
            loading ? 'loading' : '',
        ].filter(Boolean).join(' ');

        return <MUIButton
            classes={{
                root: rootClasses,
                label: labelClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        >
            {children}
            {loading && <CircularProgress
                size={32} _classes={{root: `${classes.progressHolder} loader`, progress: classes.progress}}
                success={success} failure={failure} postAnimation={postAnimation} />}
        </MUIButton>;
    }
}

Button = withStyles(styles)(Button);
export default Button;
