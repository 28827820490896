'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, initialize as reduxFormInitialize} from 'redux-form';
// Actions
import {backupForms} from 'actions/app';


/**
 * Hackish component to restore destroyed Forms after Language switch
 */
class FormRestoration extends React.Component {
    /**
     * Watch for filled backupForms and removed restorationForm from store
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // check if we have forms to restore
        if (this.props.backupedForms !== null && !Object.keys(this.props.forms).includes('restorationForm')) {
            Object.keys(this.props.backupedForms).map(form => {
                if (this.props.backupedForms[form].values) {
                    this.props.reduxFormInitialize(form, this.props.backupedForms[form].values);
                }
            });
            this.props.backupForms();
        }
    }

    render() {
        return <div />;
    }
}

FormRestoration = reduxForm({
    form: 'restorationForm',
    initialValues: {backup: 'ready'}
})(FormRestoration);

FormRestoration = connect((state) => ({
    forms: state.form,
    backupedForms: state.app.get('backupForms')
}), (dispatch) => bindActionCreators({
    backupForms,
    reduxFormInitialize
}, dispatch))(FormRestoration);

export default FormRestoration;
