'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import InvisibleEnterSubmit from 'components/core/ui/InvisibleEnterSubmit';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        margin: `0 -${theme.spacing(1)}px -${theme.spacing(1)}px`,
        '&.center': {
            justifyContent: 'center'
        }
    }
});

/**
 * When you need fields, you also need form.
 *
 * Usage like <Form onSubmit={this.props.handleSubmit}>...</Form>
 *
 * Form handles disabled status and enter submit
 */
class Form extends React.Component {
    render() {
        // Split some stuff from props
        let {alignCenter, onSubmit, dispatch, state, children, classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let rootClasses = [classes.root, className, alignCenter ? 'center' : ''].filter(Boolean).join(' ');

        return <form onSubmit={(e) => {
            e.preventDefault();
            if (state === null && onSubmit) {
                onSubmit();
            }
        }}
                     className={rootClasses}
                     {...rest_of_props}>
            {children}
            <InvisibleEnterSubmit />
        </form>;
    }
}

Form = withStyles(styles)(Form);
Form = connect((state) => ({
    state: state.app.get('state')
}))(Form);

export default Form;
