'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Salesforce icon
 */
export default class Salesforce extends React.Component {
    static defaultProps = {
        viewBox: '0 0 1000 1000',
        color: '#00a1e0',
        textColor: '#ffffff'
    };

    render() {
        let {noText, color, textColor, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <g transform='translate(125 117.52)'>
                <g transform='translate(0 -149.88)'>
                    <g transform='matrix(14.231 0 0 -14.231 291.22 259)'>
                        <path style={{fill: color}} d='m0 0c2.264 2.359 5.416 3.822 8.902 3.822 4.634 0 8.677-2.584 10.83-6.42 1.871 0.836 3.942 1.301 6.121 1.301 8.358 0 15.134-6.835 15.134-15.266 0-8.432-6.776-15.267-15.134-15.267-1.02 0-2.017 0.102-2.981 0.297-1.896-3.382-5.509-5.667-9.656-5.667-1.736 0-3.378 0.401-4.84 1.114-1.922-4.521-6.4-7.691-11.619-7.691-5.435 0-10.067 3.439-11.845 8.262-0.777-0.165-1.582-0.251-2.408-0.251-6.471 0-11.717 5.3-11.717 11.839 0 4.382 2.357 8.208 5.859 10.255-0.721 1.659-1.122 3.49-1.122 5.415 0 7.52 6.105 13.616 13.635 13.616 4.421 0 8.35-2.102 10.841-5.359' />
                    </g>
                    {!noText && <g>
                        <g transform='matrix(14.231 0 0 -14.231 20.196 545.35)'>
                            <path style={{fill: textColor}} d='m0 0c-0.044-0.115 0.016-0.139 0.03-0.159 0.132-0.096 0.266-0.165 0.401-0.242 0.716-0.38 1.392-0.491 2.099-0.491 1.44 0 2.334 0.766 2.334 1.999v0.024c0 1.14-1.009 1.554-1.956 1.853l-0.123 0.04c-0.714 0.232-1.33 0.432-1.33 0.902v0.025c0 0.402 0.36 0.698 0.918 0.698 0.62 0 1.356-0.206 1.83-0.468 0 0 0.139-0.09 0.19 0.045 0.028 0.072 0.268 0.718 0.293 0.788 0.027 0.076-0.021 0.132-0.07 0.162-0.541 0.329-1.289 0.554-2.063 0.554l-0.144-0.001c-1.318 0-2.238-0.796-2.238-1.937v-0.024c0-1.203 1.015-1.593 1.966-1.865l0.153-0.047c0.693-0.213 1.29-0.396 1.29-0.884v-0.024c0-0.446-0.388-0.778-1.014-0.778-0.243 0-1.018 0.005-1.855 0.534-0.101 0.059-0.16 0.102-0.238 0.149-0.041 0.026-0.144 0.071-0.189-0.065l-0.284-0.788z' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 320.19 545.35)'>
                            <path style={{fill: textColor}} d='m0 0c-0.044-0.115 0.016-0.139 0.03-0.159 0.132-0.096 0.266-0.165 0.401-0.242 0.716-0.38 1.392-0.491 2.099-0.491 1.44 0 2.334 0.766 2.334 1.999v0.024c0 1.14-1.009 1.554-1.956 1.853l-0.123 0.04c-0.714 0.232-1.33 0.432-1.33 0.902v0.025c0 0.402 0.36 0.698 0.918 0.698 0.62 0 1.356-0.206 1.83-0.468 0 0 0.139-0.09 0.19 0.045 0.028 0.072 0.268 0.718 0.293 0.788 0.027 0.076-0.021 0.132-0.07 0.162-0.541 0.329-1.289 0.554-2.063 0.554l-0.144-0.001c-1.318 0-2.238-0.796-2.238-1.937v-0.024c0-1.203 1.015-1.593 1.966-1.865l0.153-0.047c0.693-0.213 1.291-0.396 1.291-0.884v-0.024c0-0.446-0.389-0.778-1.015-0.778-0.243 0-1.018 0.005-1.855 0.534-0.101 0.059-0.161 0.1-0.237 0.149-0.026 0.017-0.148 0.064-0.19-0.065l-0.284-0.788z' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 525 510.98)'>
                            <path style={{fill: textColor}} d='m0 0c0-0.697-0.13-1.246-0.386-1.634-0.253-0.384-0.636-0.571-1.17-0.571-0.535 0-0.916 0.186-1.165 0.571-0.252 0.387-0.38 0.937-0.38 1.634 0 0.696 0.128 1.244 0.38 1.628 0.249 0.38 0.63 0.565 1.165 0.565 0.534 0 0.917-0.185 1.171-0.565 0.255-0.384 0.385-0.932 0.385-1.628m1.202 1.292c-0.118 0.399-0.302 0.751-0.547 1.044-0.245 0.294-0.555 0.53-0.923 0.702-0.367 0.171-0.801 0.258-1.288 0.258-0.488 0-0.922-0.087-1.289-0.258-0.368-0.172-0.678-0.408-0.924-0.702-0.244-0.294-0.428-0.646-0.547-1.044-0.117-0.397-0.176-0.831-0.176-1.292s0.059-0.896 0.176-1.292c0.119-0.398 0.302-0.75 0.548-1.044 0.245-0.294 0.556-0.529 0.923-0.696 0.368-0.167 0.801-0.252 1.289-0.252 0.487 0 0.92 0.085 1.288 0.252 0.367 0.167 0.678 0.402 0.923 0.696 0.245 0.293 0.429 0.645 0.547 1.044 0.118 0.397 0.177 0.832 0.177 1.292s-0.059 0.895-0.177 1.292' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 682.57 539.71)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c-0.04 0.117-0.153 0.073-0.153 0.073-0.175-0.067-0.361-0.129-0.559-0.16-0.201-0.031-0.422-0.047-0.659-0.047-0.582 0-1.044 0.173-1.375 0.515-0.332 0.342-0.518 0.895-0.516 1.643 0.002 0.681 0.166 1.193 0.461 1.583 0.293 0.388 0.739 0.587 1.334 0.587 0.496 0 0.874-0.057 1.27-0.182 0 0 0.095-0.041 0.14 0.083 0.105 0.292 0.183 0.501 0.295 0.822 0.032 0.091-0.046 0.13-0.074 0.141-0.156 0.061-0.524 0.16-0.802 0.202-0.26 0.04-0.564 0.061-0.902 0.061-0.505 0-0.955-0.086-1.34-0.258-0.384-0.171-0.71-0.407-0.968-0.701s-0.454-0.646-0.585-1.044c-0.13-0.397-0.196-0.833-0.196-1.294 0-0.997 0.269-1.803 0.8-2.393 0.532-0.592 1.331-0.893 2.373-0.893 0.616 0 1.248 0.125 1.702 0.304 0 0 0.087 0.042 0.049 0.143l-0.295 0.815z' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 712.5 501.48)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c0.057 0.387 0.164 0.709 0.329 0.96 0.249 0.381 0.629 0.59 1.163 0.59s0.887-0.21 1.14-0.59c0.168-0.251 0.241-0.587 0.27-0.96h-2.902zm4.047 0.851c-0.102 0.385-0.355 0.774-0.521 0.952-0.262 0.282-0.518 0.479-0.772 0.589-0.332 0.142-0.73 0.236-1.166 0.236-0.508 0-0.969-0.085-1.343-0.261-0.375-0.176-0.69-0.416-0.937-0.715-0.247-0.298-0.433-0.653-0.551-1.056-0.119-0.401-0.179-0.838-0.179-1.299 0-0.469 0.062-0.906 0.185-1.299 0.124-0.396 0.322-0.745 0.59-1.034 0.267-0.291 0.611-0.519 1.023-0.678 0.409-0.158 0.906-0.24 1.477-0.239 1.175 0.004 1.794 0.266 2.049 0.407 0.045 0.025 0.088 0.069 0.034 0.195l-0.266 0.745c-0.04 0.111-0.153 0.07-0.153 0.07-0.291-0.108-0.705-0.302-1.67-0.3-0.631 0.001-1.099 0.187-1.392 0.478-0.301 0.298-0.448 0.736-0.474 1.354l4.069-0.004s0.107 0.002 0.118 0.106c0.004 0.044 0.14 0.836-0.121 1.753' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 248.76 501.48)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c0.058 0.387 0.164 0.709 0.329 0.96 0.249 0.381 0.629 0.59 1.163 0.59s0.887-0.21 1.141-0.59c0.167-0.251 0.24-0.587 0.269-0.96h-2.902zm4.046 0.851c-0.102 0.385-0.354 0.774-0.52 0.952-0.262 0.282-0.518 0.479-0.772 0.589-0.332 0.142-0.73 0.236-1.166 0.236-0.507 0-0.969-0.085-1.343-0.261-0.375-0.176-0.69-0.416-0.937-0.715-0.247-0.298-0.433-0.653-0.551-1.056-0.118-0.401-0.179-0.838-0.179-1.299 0-0.469 0.062-0.906 0.185-1.299 0.124-0.396 0.322-0.745 0.59-1.034 0.267-0.291 0.611-0.519 1.023-0.678 0.409-0.158 0.906-0.24 1.477-0.239 1.175 0.004 1.794 0.266 2.049 0.407 0.045 0.025 0.088 0.069 0.034 0.195l-0.265 0.745c-0.041 0.111-0.154 0.07-0.154 0.07-0.291-0.108-0.704-0.302-1.671-0.3-0.63 0.001-1.098 0.187-1.391 0.478-0.301 0.298-0.448 0.736-0.474 1.354l4.069-0.004s0.107 0.002 0.118 0.106c0.004 0.044 0.14 0.836-0.122 1.753' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 123.6 539.39)'>
                            <path style={{fill: textColor}} d='m0 0c-0.159 0.127-0.181 0.159-0.235 0.241-0.08 0.125-0.121 0.303-0.121 0.529 0 0.358 0.118 0.615 0.363 0.788-0.003-0.001 0.35 0.305 1.18 0.294 0.583-0.008 1.104-0.094 1.104-0.094v-1.85h0.001s-0.517-0.111-1.099-0.146c-0.828-0.05-1.196 0.239-1.193 0.238m1.619 2.859c-0.165 0.012-0.379 0.019-0.635 0.019-0.349 0-0.686-0.044-1.002-0.129-0.318-0.085-0.604-0.218-0.85-0.394-0.247-0.177-0.446-0.403-0.59-0.671s-0.217-0.584-0.217-0.938c0-0.36 0.062-0.673 0.186-0.929 0.124-0.257 0.303-0.471 0.531-0.636 0.226-0.165 0.505-0.286 0.829-0.359 0.319-0.073 0.681-0.11 1.077-0.11 0.417 0 0.833 0.034 1.236 0.103 0.399 0.068 0.889 0.167 1.025 0.198 0.135 0.032 0.285 0.073 0.285 0.073 0.101 0.025 0.093 0.133 0.093 0.133l-0.002 3.721c0 0.816-0.218 1.421-0.647 1.796-0.427 0.374-1.056 0.563-1.869 0.563-0.305 0-0.796-0.042-1.09-0.101 0 0-0.889-0.172-1.255-0.458 0 0-0.08-0.05-0.036-0.162l0.288-0.774c0.036-0.1 0.133-0.066 0.133-0.066s0.031 0.012 0.067 0.033c0.783 0.426 1.773 0.413 1.773 0.413 0.44 0 0.778-0.088 1.006-0.263 0.222-0.17 0.335-0.427 0.335-0.969v-0.172c-0.35 0.05-0.671 0.079-0.671 0.079' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 613.67 468.87)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c0.031 0.092-0.034 0.136-0.061 0.146-0.069 0.027-0.415 0.1-0.682 0.117-0.511 0.031-0.795-0.055-1.049-0.169-0.252-0.114-0.532-0.298-0.688-0.507v0.495c0 0.069-0.049 0.124-0.117 0.124h-1.043c-0.068 0-0.117-0.055-0.117-0.124v-6.069c0-0.068 0.056-0.124 0.124-0.124h1.069c0.068 0 0.123 0.056 0.123 0.124v3.032c0 0.407 0.045 0.813 0.135 1.068 0.088 0.252 0.208 0.454 0.356 0.599 0.149 0.144 0.318 0.245 0.503 0.302 0.189 0.058 0.398 0.077 0.546 0.077 0.213 0 0.447-0.055 0.447-0.055 0.078-0.009 0.122 0.039 0.148 0.11 0.07 0.186 0.268 0.743 0.306 0.854' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 470.87 428.84)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c-0.13 0.04-0.248 0.067-0.402 0.096-0.156 0.028-0.342 0.042-0.553 0.042-0.736 0-1.316-0.208-1.723-0.618-0.405-0.408-0.68-1.029-0.818-1.846l-0.05-0.275h-0.924s-0.112 0.004-0.136-0.118l-0.151-0.847c-0.011-0.08 0.024-0.131 0.132-0.131h0.899l-0.912-5.092c-0.071-0.41-0.153-0.747-0.244-1.003-0.089-0.252-0.176-0.441-0.284-0.579-0.104-0.132-0.202-0.23-0.372-0.287-0.14-0.047-0.302-0.069-0.479-0.069-0.098 0-0.229 0.016-0.326 0.036-0.096 0.019-0.147 0.04-0.22 0.071 0 0-0.105 0.04-0.147-0.065-0.033-0.087-0.273-0.746-0.302-0.827-0.028-0.081 0.012-0.144 0.063-0.163 0.12-0.042 0.209-0.07 0.372-0.109 0.226-0.053 0.417-0.056 0.596-0.056 0.374 0 0.716 0.053 0.999 0.155 0.284 0.103 0.532 0.282 0.752 0.524 0.237 0.262 0.386 0.536 0.528 0.911 0.141 0.37 0.262 0.83 0.358 1.366l0.917 5.187h1.34s0.113-0.004 0.136 0.119l0.152 0.846c0.01 0.081-0.024 0.131-0.133 0.131h-1.301c0.007 0.029 0.066 0.487 0.215 0.918 0.064 0.183 0.184 0.332 0.285 0.434 0.1 0.1 0.215 0.171 0.341 0.212 0.129 0.042 0.276 0.062 0.437 0.062 0.122 0 0.243-0.014 0.334-0.033 0.126-0.027 0.175-0.041 0.208-0.051 0.133-0.04 0.151-0.001 0.177 0.063l0.311 0.854c0.032 0.092-0.047 0.131-0.075 0.142' />
                        </g>
                        <g transform='matrix(14.231 0 0 -14.231 212.19 554.08)'>
                            <path style={{fillRule: 'evenodd', fill: textColor}} d='m0 0c0-0.068-0.049-0.123-0.117-0.123h-1.079c-0.068 0-0.116 0.055-0.116 0.123v8.684c0 0.068 0.048 0.123 0.116 0.123h1.079c0.068 0 0.117-0.055 0.117-0.123v-8.684z' />
                        </g>
                    </g>}
                </g>
            </g>
        </SvgIcon>;
    }
}
