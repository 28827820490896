'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Folder, FileModel} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {setPortletState, setSelected} from 'actions/portlets/general';
import {fetchItem, fetchItems} from 'actions/shared';
// Components
import FilesRenderer from 'components/modules/portlets/files/Files';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import Icon from '@material-ui/core/Icon';


const styles = theme => ({
    // Download File link in List
    listItemLink: {
        // regular color (not link color from global styles)
        '&, &:hover': {
            color: theme.palette.text['primary']
        }
    },
    // File type icon
    listItemIcon: {
        minWidth: '0', // get rid of not necessary min-width
        marginRight: `${theme.spacing(2)}px` // space between icon and text
    }
});

/**
 * Renders Files Portlet - Folder with Files to view/download
 */
class Files extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedFiles: [], // sorted Files by the name
            sortedFolders: [] // sorted (child) Folders by the name
        };
    }

    /**
     * During initialization fetch Folder and then Files
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready') {
            // start fetching steps
            this.fetchFolder();
        } else {
            if (this.props.childFolders_loaded) {
                this.initialSortItems(this.props.childFolders, 'sortedFolders');
            }
            this.initialSortItems(this.props.files, 'sortedFiles');
        }
    }

    /**
     * Watch for Selected Folder changes
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        if (prevProps.selectedFolder && prevProps.selectedFolder.get('id') !== this.props.selectedFolder.get('id')) {
            // start fetching steps
            this.fetchFolder();
        }
    }

    /**
     * Set portlet state to 'fetching', Fetch Folder and call fetchChildFolders()
     */
    fetchFolder() {
        this.props.setPortletState(this.props.portlet, 'fetching');
        // we don't have root folder, initial loading
        if (!this.props.rootFolder) {
            this.props.fetchItem(Folder, 'folders', this.props.portlet.getIn(['config', 'portlet_data', 'folder']), {affect_state: false}).then(() => {
                this.fetchChildFolders();
            });
        } else {
            this.fetchChildFolders();
        }
    }

    /**
     * Fetch Child Folders, sort them to Component state and call fetchFiles()
     */
    fetchChildFolders() {
        // do we even have child Folders to fetch?
        if (!this.props.childFolders_loaded && this.props.selectedFolder.get('has_subfolders')) {
            this.props.fetchItems(Folder, `folders-${this.props.selectedFolder.get('id')}`, this.props.selectedFolder.get('folder_set'), null, null, {affect_state: false}).then(() => {
                this.initialSortItems(this.props.childFolders, 'sortedFolders');
                this.fetchFiles();
            });
        } else {
            this.initialSortItems(this.props.childFolders, 'sortedFolders');
            this.fetchFiles();
        }
    }

    /**
     * Fetch Selected Folders Files, sort them to Component state and mark Portlet 'ready'
     */
    fetchFiles() {
        if (this.props.files_loaded === false) {
            this.props.fetchItems(FileModel, `files-${this.props.selectedFolder.get('id')}`, this.props.selectedFolder.get('file_set'), null, null, {affect_state: false}).then(() => {
                this.initialSortItems(this.props.files, 'sortedFiles');
                this.props.setPortletState(this.props.portlet, 'ready');
            });
        } else {
            this.initialSortItems(this.props.files, 'sortedFiles');
            this.props.setPortletState(this.props.portlet, 'ready');
        }
    }

    /**
     * Initially sort items by name
     *
     * @param items - Fetched items from store, e.g. this.props.products_items
     * @param placement - placement in state, e.g. sortedProducts
     */
    initialSortItems(items, placement) {
        let sortedItems = items.toArray();
        sortedItems = sortedItems.slice().sort((a, b) => a.get('name').toLowerCase() === b.get('name').toLowerCase() ? 0 : a.get('name').toLowerCase() < b.get('name').toLowerCase() ? -1 : 1);
        this.setState({[placement]: sortedItems});
    }

    /**
     * Handle clicking on Folder
     *
     * @param folder - Folder to be selected
     */
    onFolderClick(folder) {
        this.props.setSelected(this.props.portlet, [...this.props.selectedFolderPath, folder]);
    }

    /**
     * Handle clicking on Back Folder
     */
    onBackFolderClick() {
        this.props.setSelected(this.props.portlet, this.props.selectedFolderPath.slice(0, this.props.selectedFolderPath.length - 1));
    }

    render() {
        // shortcut to make code slightly more readable
        let type = this.props.portlet.getIn(['config', 'portlet_data', 'display_type']);

        return type === 1 // list
            ? <React.Fragment>
            {this.props.portletState === 'fetching'
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'ready'
                ? <CardContent>
                <List disablePadding={true} component='div'>
                    {this.props.selectedFolder.get('id') !== this.props.rootFolder.get('id') && <ListItem
                        button onClick={() => this.onBackFolderClick()}>
                        <ListItemIcon className={this.props.classes.listItemIcon}>
                            <Icon>arrow_back</Icon>
                        </ListItemIcon>
                        <ListItemText primary={<FormattedMessage id='actions.back' />} />
                    </ListItem>}
                    {(this.state.sortedFolders.length === 0 && this.state.sortedFiles.length === 0)
                        ? <ListItem component='div'>
                        <ListItemText primary={<FormattedMessage id='files.list.empty.title' />}
                                      secondary={<FormattedMessage id='files.list.empty.description' />} />
                    </ListItem>
                        : <React.Fragment>
                        {this.state.sortedFolders.map((folder, idx) => <ListItem
                            key={idx} button onClick={() => this.onFolderClick(folder)}>
                            <ListItemIcon className={this.props.classes.listItemIcon}>
                                <Icon>folder</Icon>
                            </ListItemIcon>
                            <ListItemText primary={folder.get('name')} />
                        </ListItem>)}
                        {this.state.sortedFiles.map((file, idx) => <ListItem
                            key={idx} button href={file.get('file')} target='_blank' component='a'
                            className={this.props.classes.listItemLink}>
                            <ListItemIcon className={this.props.classes.listItemIcon}>
                                <Icon>{file.getIcon()}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={file.get('name')} />
                        </ListItem>)}
                    </React.Fragment>}
                </List>
            </CardContent>
                : <div />}
        </React.Fragment>
            : type === 2 // grid
            ? <CardContent>
            <FilesRenderer loading={this.props.portletState === 'fetching'}
                           loaded={this.props.portletState === 'ready'}
                           selectedFolder={this.props.selectedFolder}
                           folders={this.props.childFolders}
                           onFolderClick={folder => this.onFolderClick(folder)}
                           showBackFolderButton={this.props.rootFolder && this.props.selectedFolder.get('id') !== this.props.rootFolder.get('id')}
                           onBackFolderClick={() => this.onBackFolderClick()}
                           files={this.state.sortedFiles}
                           link
                           selectImageOnly={false} massSelecting={false} selectFile={(item) => {}} selectedItems={[]} />
        </CardContent>
            : <div />;
    }
}

Files = withStyles(styles)(Files);
Files = connect((state, props) => {
    let rootFolder = state.shared.getIn(['items', 'folders']).find(el => el.get('url') === props.portlet.getIn(['config', 'portlet_data', 'folder']));
    let selectedFolderPath = state.portlets_general.getIn(['selected', props.portlet.getIn(['config', 'id'])]) || [];
    let selectedFolder = selectedFolderPath[selectedFolderPath.length - 1] || rootFolder;

    return {
        portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
        rootFolder: rootFolder,
        selectedFolderPath: selectedFolderPath,
        selectedFolder: selectedFolder,
        childFolders_loaded: !!state.shared.getIn(['loaded', `folders-${selectedFolder ? selectedFolder.get('id') : 'unknown'}`]),
        childFolders: state.shared.getIn(['items', `folders-${selectedFolder ? selectedFolder.get('id') : 'unknown'}`]) || ImmutableList(),
        files_loaded: !!state.shared.getIn(['loaded', `files-${selectedFolder ? selectedFolder.get('id') : 'unknown'}`]),
        files: state.shared.getIn(['items', `files-${selectedFolder ? selectedFolder.get('id') : 'unknown'}`]) || ImmutableList()
    };
}, (dispatch) => bindActionCreators({
    setPortletState,
    fetchItem,
    fetchItems,
    setSelected
}, dispatch))(Files);

export default injectIntl(Files);
