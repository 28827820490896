'use strict';
import React from 'react';


/**
 * Empty Box indicating that there are no Files
 */
export default class NoFiles extends React.Component {
    static defaultProps = {
        viewBox: '0 0 495.2 495.2'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: '#D38C0D'}} points='325.6,224.4 495.2,126 325.6,28.4 156.8,127.6' />
            <polygon style={{fill: '#EFBB67'}} points='170.4,224.4 338.4,126.8 170.4,28.4 0,126.8' />
            <polygon style={{fill: '#EFBB67'}} points='416,368.4 248,466.8 80,368.4 80,172.4 248,74 416,172.4' />
            <polyline style={{fill: '#D38C0D'}} points='248,74 416,172.4 416,368.4 248,466.8' />
            <polygon style={{fill: '#EFBB67'}} points='326.4,314.8 495.2,218 325.6,119.6 156,218' />
            <polygon style={{fill: '#D38C0D'}} points='170.4,316.4 339.2,217.2 170.4,119.6 0,218' />
            <polygon style={{fill: '#704A0E'}} points='248.8,270.8 416,172.4 248.8,74 78.4,172.4' />
            <polyline style={{fill: '#513307'}} points='248.8,270.8 416,172.4 248.8,74' />
            <polygon style={{fill: '#2D1C05'}} points='248.8,270.8 284.8,249.2 248.8,228.4 212.8,249.2' />
        </svg>;
    }
}
