'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        margin: '0 auto', // center-align
        padding: `0 ${theme.spacing(2)}px`,
        maxWidth: '1920px', // limit size
        width: '100%',
        // lower size
        '&.limited': {
            maxWidth: `${(theme.spacing(4)) + 688}px` // limit size
        },
        // full-size (can be used just for padding)
        '&.full': {
            maxWidth: 'none'
        },
        // responsive
        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(1)}px`
        }
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '&.v-center': {
            justifyContent: 'center'
        },
        '&.h-center': {
            alignItems: 'center'
        },
        '&.h-bottom': {
            alignItems: 'flex-end'
        },
        '&.stretch': {
            alignItems: 'stretch'
        },
        '&.space-between': {
            alignItems: 'space-between'
        },
        margin: `0 -${theme.spacing(1)}px`,
        '&.nospace': {
            margin: '0 !important',
            '& > div': {
                padding: '0 !important'
            },
            '& > div > .colwrap': {
                left: '0 !important',
                right: '0 !important'
            }
        },
        '&.undivider': {
            marginBottom: `-${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                marginBottom: `-${theme.spacing(1)}px`
            }
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            '&.wrap': {
                '& > div': {
                    flexBasis: '100%',
                    width: '100% !important',
                    maxWidth: '100% !important',
                    minWidth: '100% !important',
                    marginBottom: `${theme.spacing(2)}px`,
                    '&:last-child': {
                        marginBottom: '0'
                    }
                },
                '&.switch > div': {
                    '&:nth-last-child(2)': {
                        marginBottom: '0'
                    },
                    '&:last-child': {
                        marginBottom: `${theme.spacing(2)}px`,
                        order: '-1'
                    }
                }
            }
        },
        [theme.breakpoints.down('xs')]: {
            margin: `0 -${theme.spacing(1)}px`,
            '&.wrap': {
                '& > div': {
                    marginBottom: `${theme.spacing(1)}px`,
                    '&:last-child': {
                        marginBottom: '0'
                    }
                },
                '&.switch > div': {
                    '&:nth-last-child(2)': {
                        marginBottom: '0'
                    },
                    '&:last-child': {
                        marginBottom: `${theme.spacing(1)}px`
                    }
                }
            }
        }
    },
    col: {
        flexGrow: '1',
        maxWidth: '100%',
        width: '100px',
        padding: `0 ${theme.spacing(1)}px`,
        position: 'relative',
        // responsive
        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(1)}px`
        },
        '&.h-divider': {
            marginBottom: `${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                marginBottom: `${theme.spacing(1)}px !important`
            }
        }
    },
    colWrap: {
        position: 'absolute',
        bottom: '0',
        top: '0',
        left: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            left: `${theme.spacing(1)}px`,
            right: `${theme.spacing(1)}px`
        }
    }
});

/**
 * Page content holder
 */
class Container extends React.Component {
    render() {
        let classes = [
            this.props.classes.container,
            this.props.limited ? 'limited' : this.props.full ? 'full' : '',
        ].filter(Boolean).join(' ');

        return <div className={classes}>
            {this.props.children}
        </div>;
    }
}

/**
 * Row for Grid system
 *
 * Props:
 *  wrap        - Responsive wrap (cols become 100%)
 *  wrapSwitch  - Responsive wrap (cols became 100% and switch places - last become first)
 *  nospace     - no space between cols
 *  vCenter     - Vertical center
 *  hCenter     - Horizontal center
 *  hBottom     - Horizontal bottom
 *  center      - Center
 *  stretch     - Cols will stretch (flexbox). Not working with 'hCenter' or 'center'
 *  undivider   - Nulls 'divider' (h-divider) on last column
 */
class Row extends React.Component {
    render() {
        let classes = [this.props.classes.row, this.props.className].filter(Boolean).join(' ');
        if (this.props.wrap || this.props.wrapSwitch) {
            classes = `${classes} wrap`;
            if (this.props.wrapSwitch) {
                classes = `${classes} switch`;
            }
        }
        if (this.props.nospace) {
            classes = `${classes} nospace`;
        }
        if (this.props.undivider) {
            classes = `${classes} undivider`;
        }
        if (this.props.center) {
            classes = `${classes} v-center h-center`;
        } else {
            // vertical
            if (this.props.vCenter) {
                classes = `${classes} v-center`;
            } else if (this.props.stretch) {
                classes = `${classes} stretch`;
            } else if (this.props.spaceBetween) {
                classes = `${classes} space-between`;
            }
            // horizontal
            if (this.props.hCenter) {
                classes = `${classes} h-center`;
            } else if (this.props.hBottom) {
                classes = `${classes} h-bottom`;
            }
        }

        return <div className={classes}>
            {this.props.children}
        </div>;
    }
}

/**
 * Col (should be used as Row children)
 *
 * Props:
 *  width       - Specify width either with PXs or %
 *  maxWidth    - limit size
 *  minHeight   - limit size
 *  relative    - Make Col absolute to Col. Works great with <Row stretch />
 *  divider     - Height divider bellow
 */
class Col extends React.Component {
    render() {
        let classes = [this.props.classes.col, this.props.className].filter(Boolean).join(' ');
        let style = {};
        if (this.props.width) {
            style['minWidth'] = this.props.width;
            style['flexGrow'] = 0;
        }
        if (this.props.maxWidth) {
            style['maxWidth'] = this.props.maxWidth;
        }
        if (this.props.minHeight) {
            style['minHeight'] = this.props.minHeight;
        }
        if (this.props.divider) {
            classes = `${classes} h-divider`;
        }

        return <div className={classes} style={style}>
            {this.props.relative
                ? <div className={`${this.props.classes.colWrap} colwrap`}>
                {this.props.children}
            </div>
                : this.props.children}
        </div>;
    }
}

Container = withStyles(styles)(Container);
Row = withStyles(styles)(Row);
Col = withStyles(styles)(Col);

export {Container, Row, Col};
