'use strict';
import {Map, fromJS} from 'immutable';
import keymirror from 'keymirror';


export const constants = keymirror({
    PORTLET_STATE: null,
    PORTLET_RELATED_DATA: null,
    PORTLET_SELECTED: null
});

let defaults = Map({
    state: Map(),
    related_data: Map(),
    selected: Map()
});

export default (state = defaults, action) => {
    switch (action.type) {
        // set specific Portlet state or remove all of them
        case constants.PORTLET_STATE:
            return !action.portlet && !action.state
                ? state.set('state', defaults.get('state'))
                : state.setIn(['state', action.portlet.get('url')], action.state);

        // set specific Portlet related data or remove all of them
        case constants.PORTLET_RELATED_DATA:
            return !action.portlet && !action.data
                ? state.set('state', defaults.get('state'))
                : state.setIn(['related_data', action.portlet.getIn(['config', 'id'])], fromJS(action.data));

        // set selected item for Portlet
        case constants.PORTLET_SELECTED:
            return !action.portlet && !action.item
                ? state.set('selected', defaults.get('state'))
                : state.setIn(['selected', action.portlet.getIn(['config', 'id'])], action.item);

        default:
            return state;
    }
};
