'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import storage from 'store';
import Moment from 'moment';
// Actions
import {updateIntl} from 'react-intl-redux';
import {backupForms} from 'actions/app';
// Strings
import enTexts from 'locale/en';
// import csTexts from 'locale/cs';
// import deTexts from 'locale/de';
// import esTexts from 'locale/es';
// import frTexts from 'locale/fr';
// import ruTexts from 'locale/ru';
// material-ui
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Icons
import EnFlag from 'components/core/icons/flags/en';
// import CsFlag from 'components/core/icons/flags/cs';
// import DeFlag from 'components/core/icons/flags/de';
// import EsFlag from 'components/core/icons/flags/es';
// import FrFlag from 'components/core/icons/flags/fr';
// import RuFlag from 'components/core/icons/flags/ru';


/**
 * Menu which gives ability to switch language
 */
class LanguageSwitcherMenu extends React.Component {
    /**
     * Renders MenuItem and handle it's click
     *
     * @param name - localized name of language, e.g. 'Čeština'
     * @param locale - language, e.g. 'cs'
     * @param messages - messages for translation, e.g. 'czechTexts'
     * @param icon - Material flag icon, e.g. '<CSFlag />'
     */
    _renderMenuItem(name, locale, messages, icon) {
        return <MenuItem disabled={this.props.state !== null}
                         selected={this.props.intl.locale === locale || (locale === 'keys' && this.props.intl.locale === 'en-gb')}
                         onClick={this.props.state === null ? () => {
                             // save choice to storage
                             storage.set(`__datastore-${process.env.STORAGE_PREFIX}-lang`, locale);
                             // special case for INTL keys
                             if (locale === 'keys') {
                                 messages = Object.keys(enTexts).reduce((o, key) => Object.assign(o, {[key]: key}), {});
                                 locale = 'en-gb';
                             }
                             // localize moment (so we have localized client, dates, etc.)
                             Moment.locale(locale);
                             // backup forms so we can set their values back after language switch
                             this.props.backupForms(this.props.forms);
                             // support for missing translation string
                             // messages = locale === 'en' ? messages : {...enTexts, messages};
                             // change INTL of application
                             this.props.updateIntl({
                                 locale: locale,
                                 messages: messages
                             });
                         } : () => {}}>
            {icon && <ListItemIcon className='icon'>{icon}</ListItemIcon>}
            <ListItemText className='text'>{name}</ListItemText>
        </MenuItem>;
    }

    render() {
        // {this._renderMenuItem('Čeština', 'cs', csTexts, <CsFlag />)}
        // {this._renderMenuItem('Française', 'fr', frTexts, <FrFlag />)}
        // {this._renderMenuItem('Deutsch', 'de', deTexts, <DeFlag />)}
        // {this._renderMenuItem('Español', 'es', esTexts, <EsFlag />)}
        // {this._renderMenuItem('Pусский', 'ru', ruTexts, <RuFlag />)}

        return <Menu open={this.props.open}
                     anchorEl={this.props.anchorEl}
                     onClose={() => this.props.onClose()}>
            {this._renderMenuItem('English', 'en', enTexts, <EnFlag />)}
            {process.env.ENVIRONMENT !== 'production' && this._renderMenuItem('INTL Keys', 'keys', enTexts)}
        </Menu>;
    }
}

LanguageSwitcherMenu = connect((state) => ({
    state: state.app.get('state'),
    forms: state.form
}), (dispatch) => bindActionCreators({
    updateIntl,
    backupForms
}, dispatch))(LanguageSwitcherMenu);

export default injectIntl(LanguageSwitcherMenu);
