'use strict';
import React from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import SuccessIcon from '@material-ui/icons/Done';
import FailureIcon from '@material-ui/icons/Close';


const animationLength = 500; // duration of success animation in MS
const styles = theme => ({
    root: {
        // same size as CardAction
        paddingTop: '26px',
        paddingBottom: '26px !important',
        '&.dialogactions': {
            // same size as DialogActions with buttons
            paddingLeft: `${(theme.spacing(1))}px`,
            paddingRight: `${(theme.spacing(1))}px`
            // top and bottom matches CardActions (56px)
        }
    },
    // progress bar itself
    progress: {
        width: '100%',
        // allow animation
        transition: theme.transitions.create(['width', 'margin', 'background'],
            {duration: animationLength}),
        '&.anim': {
            // hide
            width: '0',
            // hide it to right side
            marginLeft: 'auto',
            // move into icon
            marginRight: '14px',
            // make it full loaded
            background: theme.palette.primary[500],
        },
        '&.center.anim': {
            // center
            marginRight: 'auto'
        }
    },
    iconHolder: {
        display: 'block',
        position: 'relative',
        marginTop: '-21px',
        marginBottom: '21px'
    },
    // success or failure icon
    icon: {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        // enlarge
        fontSize: theme.typography.pxToRem(34),
        // hide by default
        opacity: '0',
        // allow animation
        transition: theme.transitions.create(['opacity', 'color'],
            {duration: theme.transitions.duration.shortest}),
        transitionDelay: `${animationLength - theme.transitions.duration.shortest}ms`,
        // loader color
        color: theme.palette.primary[500],
        '&.anim': {
            // display icon
            opacity: '1',
            // recolor
            '&.success': {
                color: theme.palette.success[500]
            },
            '&.failure': {
                color: theme.palette.danger[500]
            }
        },
        '&.center': {
            left: '0',
            margin: '0 auto'
        }
    }
});

/**
 * Loader to replace buttons in <CardAction />
 */
class CardActionsLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false, // successfully finished
            failure: false // not successfully finished
        };
    }

    /**
     * Check if we should immediately run animation
     */
    componentDidMount() {
        // in case we already have success true, run animation
        if (this.props.success) {
            setTimeout(() => {
                this.setState({success: true});
            }, 300); // react too fast
            setTimeout(() => {
                this.props.postAnimation(true);
            }, (animationLength + 1300));
        } else if (this.props.failure) {
            setTimeout(() => {
                this.setState({failure: true});
            }, 300); // react too fast
            setTimeout(() => {
                this.props.postAnimation(false);
            }, (animationLength + 1300));
        }
    }

    /**
     * Run animation when success or failure change in props
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // loading finished, run animation
        if (!prevProps.success && this.props.success) {
            this.setState({success: true});
            setTimeout(() => {
                this.props.postAnimation(true);
            }, (animationLength + 1000));
        } else if (!prevProps.failure && this.props.failure) {
            this.setState({failure: true});
            setTimeout(() => {
                this.props.postAnimation(false);
            }, (animationLength + 1000));
        }
    }

    render() {
        // Split some stuff from props
        let {classes, _classes, className, dialogActions, center, success, failure, postAnimation, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, progress, icon, ...rest_of_classes} = _classes || {};

        let rootClasses = [
            classes.root, root,
            dialogActions ? 'dialogactions' : ''
        ].filter(Boolean).join(' ');

        let progressClasses = [
            classes.progress, progress,
            center ? 'center' : ''
        ].filter(Boolean).join(' ');

        let iconClasses = [
            classes.icon, icon,
            center ? 'center' : ''
        ].filter(Boolean).join(' ');

        // success and failure with animation
        let successIconClasses = iconClasses;
        let failureIconClasses = iconClasses;
        if (this.state.success) {
            progressClasses = `${progressClasses} anim`;
            successIconClasses = `${successIconClasses} anim success`;
        } else if (this.state.failure) {
            progressClasses = `${progressClasses} anim`;
            failureIconClasses = `${failureIconClasses} anim failure`;
        }

        return <CardContent className={rootClasses}>
            <LinearProgress className={progressClasses} {...rest_of_props} />
            {this.props.postAnimation && <div className={this.props.classes.iconHolder}>
                <MuiThemeProvider theme={altTheme}>
                    <SuccessIcon className={successIconClasses} />
                    <FailureIcon className={failureIconClasses} />
                </MuiThemeProvider>
            </div>}
        </CardContent>;
    }
}

CardActionsLoader = withStyles(styles)(CardActionsLoader);
export default CardActionsLoader;
