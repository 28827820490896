'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CircularProgress from 'components/core/ui/mui/CircularProgress';
import Typography from '@material-ui/core/Typography';
// icons
import NoFilesIcon from 'components/core/icons/fileexplorer/NoFiles';
import BackFolderIcon from 'components/core/icons/fileexplorer/BackFolder';
import FolderIcon from 'components/core/icons/fileexplorer/Folder';
import FileTypeArchive from 'components/core/icons/fileexplorer/filetypes/Archive';
import FileTypeDocument from 'components/core/icons/fileexplorer/filetypes/Document';
import FileTypeFile from 'components/core/icons/fileexplorer/filetypes/File';
import FileTypeMusic from 'components/core/icons/fileexplorer/filetypes/Music';
import FileTypeVideo from 'components/core/icons/fileexplorer/filetypes/Video';
import FileTypeWeb from 'components/core/icons/fileexplorer/filetypes/Web';


const styles = theme => ({
    // holder of everything
    holder: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: `${theme.spacing(1)}px`
    },
    // holder of button
    buttonHolder: {
        // proportion
        width: '11.1111%',
        minWidth: '140px',
        maxWidth: '180px',
        padding: `${theme.spacing(1)}px`,
        // mobile responsive
        [theme.breakpoints.down('xs')]: {
            width: '50%'
        }
    },
    // holder of item
    button: {
        // proportion
        display: 'block',
        width: '100%',
        padding: `${theme.spacing(1)}px`,
        // style for selected item
        '&.selected': {
            backgroundColor: theme.palette.grey[300]
        },
        // regular color (not link color from global styles)
        '&, &:hover': {
            color: theme.palette.text['primary']
        }
    },
    // file or folder (button Label)
    item: {
        display: 'block',
        // 1:1 ratio
        paddingTop: '100%',
        position: 'relative',
        '& .preview': {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: `${theme.spacing(5)}px`, // text space
            // style for background preview
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // icon itself
            '& svg': {
                display: 'block',
                maxHeight: '100%'
            }
        },
        '& .text-holder': {
            // bellow
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            height: `${theme.spacing(4)}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .text': {
            lineHeight: `${theme.spacing(2)}px`,
            // style
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
        }
    },
    // Files are loading
    loading: {
        padding: `${theme.spacing(4)}px 0`,
        textAlign: 'center'
    },
    // no files in the folder
    noFiles: {
        padding: `${theme.spacing(1)}px 0`,
        flexGrow: '1',
        textAlign: 'center',
        color: theme.palette.text['secondary'],
        '& svg': {
            width: '250px',
            height: '250px',
            display: 'block',
            margin: '0 auto'
        }
    }
});

/**
 * Get correct File Icon
 *
 * @param file - File which we are searching for the icon
 */
export function getLargeIcon(file) {
    switch (file.getType()) {
        case 'music':
            return <FileTypeMusic />;
        case 'video':
            return <FileTypeVideo />;
        case 'web':
            return <FileTypeWeb />;
        case 'document':
            return <FileTypeDocument />;
        case 'archive':
            return <FileTypeArchive />;
        case 'file':
            return <FileTypeFile />;
    }
}

/**
 * Renders Folder or File item
 */
class Item extends React.Component {
    render() {
        return <div className={this.props.classes.buttonHolder} key={this.props.key}>
            <Button _classes={{root: `${this.props.classes.button}${this.props.selected ? ' selected' : ''}`, label: this.props.classes.item}}
                    disabled={this.props.disabled}
                    onClick={this.props.link ? undefined : this.props.onClick}
                    href={this.props.link ? this.props.link_url : undefined}
                    target={this.props.link ? '_blank' : undefined}
                    component={this.props.link ? 'a' : undefined}>
                <div className='preview' style={{backgroundImage: this.props.previewImage ? `url("${this.props.previewImage}")` : 'none'}}>
                    {this.props.preview}
                </div>
                <div className='text-holder'>
                    <div className='text'>{this.props.text}</div>
                </div>
            </Button>
        </div>;
    }
}
Item = withStyles(styles)(Item);

/**
 * Files and Folders in List
 */
class Files extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.loading
                ? <div className={this.props.classes.loading}>
                <CircularProgress size={160} thickness={0.9} />
            </div>
                : this.props.loaded || !this.props.selectedFolder
                ? <div className={this.props.classes.holder}>
                {this.props.showBackFolderButton && <Item
                    text={<FormattedMessage id='actions.back' />}
                    preview={<BackFolderIcon />}
                    onClick={() => this.props.onBackFolderClick()}
                />}
                {this.props.folders && this.props.folders.map((folder, idx) => <Item
                    key={idx} text={folder.get('name')}
                    preview={<FolderIcon />}
                    onClick={() => this.props.onFolderClick(folder, idx)}
                />)}
                {this.props.files && this.props.files.map((file, idx) => <Item
                    key={idx} text={file.get('name')}
                    preview={file.getType() !== 'image' && getLargeIcon(file)}
                    previewImage={file.getType() === 'image' && file.get('file')}
                    link={this.props.link} link_url={file.get('file')}
                    onClick={this.props.link ? undefined : () => this.props.massSelecting
                        ? this.props.selectFile(file)
                        : !this.props.selectImageOnly || file.getType() === 'image'
                        ? this.props.onFileClick(file)
                        : {}}
                    disabled={file.getType() !== 'image' && this.props.selectImageOnly}
                    selected={this.props.selectedItems.includes(file)}
                />)}
                {(this.props.loaded && this.props.files.length === 0 && this.props.folders.size === 0) && <React.Fragment>
                    <div className={this.props.classes.noFiles}>
                        <NoFilesIcon />
                        <SpaceDivider />
                        <Typography variant='h4'><FormattedMessage id='files.list.empty.title' /></Typography>
                        <SpaceDivider />
                        <Typography variant='subtitle1'>
                            <FormattedMessage id='files.list.empty.description'
                                              values={{link: <a href='#' className='hover-border' onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({detailDialogOpen: true});
                                              }}>
                                                  <FormattedMessage id='files.list.empty.description.link' />
                                              </a>}} />
                        </Typography>
                    </div>
                    <div className={this.props.classes.buttonHolder} />
                </React.Fragment>}
            </div>
                : <div />}
        </React.Fragment>;
    }
}

Files = withStyles(styles)(Files);
export default injectIntl(Files);
