'use strict';
import React from 'react';
// Components
import HTMLPortlet from 'components/modules/portlets/HTML';
import SocialPortlet from 'components/modules/portlets/Social';
import NewsPortlet from 'components/modules/portlets/News';
import ContactsPortlet from 'components/modules/portlets/Contacts';
import ContactUsPortlet from 'components/modules/portlets/ContactUs';
import CalendarPortlet from 'components/modules/portlets/Calendar';
import GalleryPortlet from 'components/modules/portlets/Gallery';
import FilesPortlet from 'components/modules/portlets/Files';
import PartnerLevelPortlet from 'components/modules/portlets/PartnerLevel';
import LeadsPortlet from 'components/modules/portlets/Leads';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
// icons
import Icon from '@material-ui/core/Icon';


/**
 * Base of all portlets
 *
 * this.props.portlet from DynamicPage.js
 */
class Base extends React.Component {
    /**
     * Renders specific Portlet
     */
    getPortlet() {
        let props = {portlet: this.props.portlet};
        let portlet_type = this.props.portlet.getIn(['config', 'type']);

        switch (portlet_type) {
            case 'html':
                return <HTMLPortlet {...props} />;
            case 'social':
                return <SocialPortlet {...props} />;
            case 'news':
                return <NewsPortlet {...props} />;
            case 'contact_us':
                return <ContactUsPortlet {...props} />;
            case 'contacts':
                return <ContactsPortlet {...props} />;
            case 'google_calendar':
                return <CalendarPortlet {...props} />;
            case 'gallery':
                return <GalleryPortlet {...props} />;
            case 'files':
                return <FilesPortlet {...props} />;
            case 'partner_level':
                return <PartnerLevelPortlet {...props} />;
            case 'leads':
                return <LeadsPortlet {...props} />;
            default:
                console.error(`unknown portlet ${portlet_type}`);
                return <div />;
        }
    }

    /**
     * Get CardHeader if Title, Subheader or Icon provided in Portlet
     */
    getPortletHeader() {
        return this.props.portlet.getIn(['config', 'title']) || this.props.portlet.getIn(['config', 'subheader']) || this.props.portlet.getIn(['config', 'title_icon'])
            ? <CardHeader
            title={this.props.portlet.getIn(['config', 'title'])
                ? this.props.portlet.getIn(['config', 'title'])
                : undefined}
            subheader={this.props.portlet.getIn(['config', 'subheader'])
                ? this.props.portlet.getIn(['config', 'subheader'])
                : undefined}
            action={this.props.portlet.getIn(['config', 'title_icon'])
                ? <ActionButton iconButton disabled>
                <Icon>{this.props.portlet.getIn(['config', 'title_icon'])}</Icon>
            </ActionButton>
                : undefined} />
            : undefined;
    }

    render() {
        if (this.props.portlet.getIn(['config', 'type']) === 'html' && !this.props.portlet.getIn(['config', 'portlet_data', 'card'])) {
            return <div>
                {this.getPortletHeader()}
                {this.getPortlet()}
            </div>;
        } else {
            return <Card>
                {this.getPortletHeader()}
                {this.getPortlet()}
            </Card>;
        }
    }
}

export default Base;
