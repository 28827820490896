'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {FileModel} from 'lib/models';
// Components
import Dropzone from 'react-dropzone';
// material-ui
import CircularProgress from 'components/core/ui/mui/CircularProgress';
// Icons
import UploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/LibraryAdd';
import ErrorIcon from '@material-ui/icons/Warning';
import Icon from '@material-ui/core/Icon';


const styles = theme => ({
    root: {
        // limit size
        maxWidth: '250px',
        margin: '0 auto'
    },
    contentHolder: {
        position: 'relative',
        // proportion (square)
        width: '100%',
        paddingBottom: '100%',
        // visual
        border: `1px solid ${theme.palette.grey[300]}`,
        // highlight 'success'
        '&.active': {
            borderColor: theme.palette.success['main']
        },
        // highlight error
        '&.reject': {
            borderColor: theme.palette.danger['main'],
            '& $messageHolder': {
                color: theme.palette.danger['main']
            }
        },
    },
    content: {
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        top: '0',
        // visual
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text['secondary'],
        // avatar nice fit
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // different avatar fit (no square)
        '&.nocropavatar': {
            // don't crop and dont repeat image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        },
        // shared
        '&, $hoverBlock': {
            // center with flex
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            textAlign: 'center',
        },
        // display hoverBlock on :hover
        '&:hover $hoverBlock': {
            'opacity': 1
        },
    },
    messageHolder: {
        background: 'rgba(255, 255, 255, 0.75)',
        borderRadius: theme.shape.borderRadius,
        padding: `${theme.spacing(2)}px`
    },
    iconHolder: {
        marginBottom: `${theme.spacing(1)}px`,
        // icon itself
        '& svg': {
            fontSize: '30px'
        },
        // variant without text
        '&.solo': {
            marginBottom: '0',
            // icon itself
            '& svg': {
                fontSize: '40px'
            },
        },
    },
    hoverBlock: {
        // by default hidden
        opacity: 0,
        // smooth
        transition: theme.transitions.create('opacity',
            {duration: theme.transitions.duration.shortest}),
        // full-size position
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        // visual
        background: theme.fileDrop.background,
        color: theme.palette.common.white
    },
    dropzone: {
        // full-size position
        position: 'absolute !important', // element 'relative' by default
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        // cursor like link
        cursor: 'pointer'
    }
});

/**
 * Basically File field
 */
class DropFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isReject: false
        };
    };

    render() {
        // Split classes stuff from props
        let {classes, _classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, contentHolder, ...rest_of_classes} = _classes || {};
        let rootClasses = [classes.root, root, className].filter(Boolean).join(' ');
        let contentHolderClasses = [classes.contentHolder, contentHolder].filter(Boolean).join(' ');

        return <div className={rootClasses}>
            <div className={`${contentHolderClasses}${this.state.isActive ? ' active' : this.state.isReject ? ' reject' : ''}`}>
                {this.props.loading
                    ? <div className={classes.content}>
                    <CircularProgress size={80} />
                </div>
                    : <div className={`${classes.content}${this.props.noCropAvatar ? ' nocropavatar' : ''}`}
                           style={this.props.avatar ? {backgroundImage: `url("${this.props.avatar}")`} : {}}>
                    {this.state.isReject
                        ? <div className={classes.messageHolder}>
                        <div className={classes.iconHolder}><ErrorIcon /></div>
                        <FormattedMessage id='dropfile.rejected' />
                    </div>
                        : this.props.file_name ? <div className={classes.messageHolder}>
                        <div className={classes.iconHolder}><Icon>{new FileModel({file: this.props.file_name}).getIcon()}</Icon></div>
                        <span>{this.props.file_name}</span>
                    </div>
                        : !this.props.avatar && !this.props.disabled ? <div className={classes.messageHolder}>
                        <div className={classes.iconHolder}><AddIcon /></div>
                        <FormattedMessage id='dropfile.howto' />
                    </div>
                        : <div />}
                    {!this.props.disabled && <div className={classes.hoverBlock}>
                        {this.props.avatar || this.props.file_name
                            ? <div>
                            <div className={classes.iconHolder}><UploadIcon /></div>
                            <FormattedMessage id='dropfile.howto' />
                        </div>
                            : <div className={`${classes.iconHolder} solo`}><UploadIcon /></div>
                        }
                    </div>}
                    {!this.props.disabled && <Dropzone className={classes.dropzone}
                                                       maxSize={this.props.maxSize || 5000000} // 5 mb
                                                       onDropAccepted={files => this.props.onDropped(files[0])}
                                                       onDrop={(acceptedFiles, rejectedFiles) =>
                                                           this.setState({'isActive': !!acceptedFiles.length, 'isReject': !!rejectedFiles.length})
                                                       } />}
                </div>}
            </div>
        </div>;
    }
}

DropFile = withStyles(styles)(DropFile);
export default injectIntl(DropFile);
