'use strict';
import React from 'react';


/**
 * Logo of Snom
 */
export default class SnomLogo extends React.Component {
    static defaultProps = {
        viewBox: '0 0 237.5 57.837502',
        color: '#7b7979'
    };

    render() {
        let {color, ...rest_of_props} = this.props;

        return <svg {...rest_of_props}>
            <g transform='matrix(1.25 0 0 -1.25 0 57.838)'>
                <g transform='scale(.1)'>
                    <path style={{fill: color}} d='m13.297 84.258c0.2148 1.1719 1.25 2.0703 2.4844 2.0703h0.082c27.777-0.5468 56.051-0.9531 84.84-1.2265 29.524-0.2891 56.156-0.4219 79.883-0.4219 30.57 0 51.527 3.6523 62.875 10.973 11.328 7.3047 17.004 21.649 17.004 43.039 0 6.747-0.664 13.079-1.977 18.981-1.32 5.902-3.953 11.105-7.91 15.609-3.961 4.496-9.625 8.297-17.004 11.395-7.39 3.086-17.402 5.195-30.054 6.332l-94.907 9.277c-19.511 1.688-36.125 6.035-49.828 13.078-13.719 7.032-24.793 15.746-33.223 26.16-8.4336 10.403-14.625 22.075-18.582 35.02-3.9531 12.937-5.9297 26.16-5.9297 39.66v14.348c0 38.246 12.383 68.203 37.172 89.867 24.777 21.645 65.375 32.484 121.8 32.484h79.086c26.598 0 56.426-0.535 89.496-1.586l0.137-0.023c1.324-0.07 2.375-1.168 2.375-2.512 0-0.16-0.012-0.32-0.043-0.476l-0.011-0.078-13.258-68.352-0.028-0.086c-0.265-1.109-1.257-1.93-2.445-1.93h-0.074l0.07-0.004c-21.289 0.524-40.109 0.93-56.441 1.215-16.887 0.282-31.778 0.426-44.692 0.426h-34.8-28.075c-26.89 0-45.617-3.523-56.156-10.555-10.547-7.039-15.816-19.554-15.816-37.55 0-7.317 0.6562-13.789 1.9804-19.403 1.3047-5.633 3.9532-10.418 7.9063-14.351 3.9531-3.942 9.75-7.313 17.407-10.121 7.632-2.825 17.789-4.789 30.441-5.911l91.746-9.277c23.727-2.262 42.965-7.465 57.735-15.617 14.753-8.156 26.101-18.008 34.007-29.531 7.914-11.539 13.313-23.922 16.211-37.133 2.895-13.223 4.352-26.024 4.352-38.391v-11.816c0-39.949-13.445-71.024-40.332-93.242-26.9-22.235-68.29-33.332-124.18-33.332-23.731 0-51.289 0.1485-82.648 0.4219-30.357 0.277-91.466 1.214-91.466 1.214-1.391 0-2.516 1.141-2.516 2.528 0 0.1719 0.015625 0.3398 0.050781 0.5l13.246 68.328' />
                    <path style={{fill: color}} d='m1030.6 2.5156c0-1.3867-1.1-2.5156-2.5-2.5156 0 0-0.3 0.0039062-0.3 0.0039062-24.6 1.1484-46.16 4.1719-64.63 9.1523-24 6.4648-43.641 17.727-58.926 33.75-15.297 16.035-26.492 37.414-33.613 64.133-7.117 26.719-10.676 60.34-10.676 100.84v46.406c0 40.508 3.559 74.117 10.676 100.84 7.121 26.715 18.316 48.098 33.613 64.133 15.285 16.031 34.926 27.281 58.926 33.75 18.519 4.992 40.158 8.027 64.818 9.164 0 0 0.11 0.004 0.13 0.004 1.39 0 2.52-1.125 2.52-2.516v-67.629c0-1.324-1.02-2.41-2.32-2.508-0.06-0.007-0.28-0.011-0.28-0.011-12.11-0.723-22.56-2.051-31.263-4.012-13.714-3.094-24.39-9.426-32.027-18.984-7.648-9.575-12.93-23.204-15.82-40.922-2.903-17.723-4.348-41.5-4.348-71.309v-46.406c0-29.828 1.445-53.582 4.348-71.309 2.89-17.719 8.172-31.367 15.82-40.922 7.637-9.5781 18.313-15.902 32.027-18.984 8.753-1.9805 19.253-3.3125 31.433-4.0235h-0.09 0.13c1.33-0.0703 2.39-1.1679 2.39-2.5156v-67.613' />
                    <path style={{fill: color}} d='m1073 0.0039062c-1.39 0-2.52 1.1289-2.52 2.5156v67.602c0 1.3633 1.08 2.4687 2.43 2.5156l0.18 0.0196c12.11 0.7109 22.55 2.039 31.27 4.0117 13.7 3.082 24.37 9.4062 32.03 18.984 7.63 9.5547 12.91 23.203 15.81 40.922 2.9 17.727 4.35 41.481 4.35 71.309v46.406c0 29.809-1.45 53.586-4.35 71.309-2.9 17.718-8.18 31.347-15.81 40.922-7.66 9.558-18.33 15.89-32.03 18.984-8.72 1.961-19.18 3.293-31.3 4.012l-0.19 0.019c-1.33 0.067-2.39 1.164-2.39 2.512v67.609c0 1.391 1.13 2.516 2.52 2.516 0.04 0 0.35-0.008 0.35-0.008 24.57-1.144 46.14-4.176 64.62-9.152 23.99-6.469 43.62-17.719 58.92-33.75 15.28-16.035 26.49-37.418 33.61-64.133 7.12-26.723 10.68-60.332 10.68-100.84v-46.406c0-40.504-3.56-74.125-10.68-100.84-7.12-26.719-18.33-48.098-33.61-64.133-15.3-16.027-34.9-27.289-58.9-33.754-18.6-4.988-40.2-8.0115-64.8-9.156l-0.22 0.0039062' />
                    <path style={{fill: color}} d='m1819.4 12.957c-1.39 0-2.52 1.125-2.52 2.5157v287.76c0 14.059-0.67 26.438-1.98 37.133-1.32 10.68-4.75 19.684-10.28 27.004-5.53 7.301-13.71 12.785-24.52 16.449-10.81 3.653-25.97 5.493-45.48 5.493-17.92 0-32.16-1.563-42.7-4.645-10.56-3.101-18.72-7.891-24.52-14.348-5.81-6.468-9.63-14.343-11.47-23.625-1.85-9.281-2.77-19.828-2.77-31.644l0.01-299.57c0-1.3907-1.13-2.5157-2.52-2.5157h-78.01c-1.39 0-2.52 1.125-2.52 2.5157v287.76c0 14.059-0.67 26.438-1.98 37.133-1.32 10.68-4.75 19.684-10.28 27.004-5.53 7.301-13.72 12.785-24.52 16.449-10.81 3.653-25.97 5.493-45.47 5.493-18.98 0-34.01-1.84-45.08-5.493-11.08-3.664-19.26-9.148-24.52-16.449-5.28-7.32-8.58-16.324-9.89-27.004-1.32-10.695-1.98-23.074-1.98-37.133v-287.76c0-1.3907-1.12-2.5157-2.51-2.5157h-78.01c-1.39 0-2.52 1.125-2.52 2.5157v305.48c0 51.754 13.84 88.313 41.52 109.7 27.69 21.367 68.68 32.063 122.99 32.063 27.41 0 52.2-4.364 74.34-13.082 22.14-8.731 38.49-20.672 49.03-35.864 10.55 15.192 26.89 27.133 49.04 35.864 22.15 8.718 46.92 13.082 74.34 13.082 54.3 0 95.43-10.696 123.38-32.063 27.94-21.39 41.92-57.949 41.92-109.7v-305.48c0-1.3907-1.12-2.5157-2.51-2.5157h-78.01' />
                    <path style={{fill: color}} d='m697.18 12.957c-1.387 0-2.516 1.125-2.516 2.5157v287.76c0 14.059-0.789 26.438-2.375 37.133-1.578 10.68-5.539 19.684-11.863 27.004-6.325 7.301-16.09 12.785-29.262 16.449-13.188 3.653-31.379 5.493-54.57 5.493-23.203 0-41.403-1.84-54.575-5.493-13.183-3.664-22.933-9.148-29.265-16.449-6.324-7.32-10.278-16.324-11.86-27.004-1.582-10.695-2.371-23.074-2.371-37.133v-287.76c0-1.3907-1.125-2.5157-2.515-2.5157h-78.02c-1.39 0-2.515 1.125-2.515 2.5157v305.48c0 50.629 14.636 86.918 43.894 108.86 29.262 21.933 75.004 32.906 137.23 32.906 62.207 0 107.95-10.973 137.22-32.906 29.265-21.942 43.898-58.231 43.898-108.86v-305.48c0-1.3907-1.125-2.5157-2.516-2.5157h-78.011' />
                </g>
            </g>
        </svg>;
    }
}
