'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import marked from 'marked';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Moment from 'moment';
import {FileModel} from 'lib/models';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
import {setPortletState} from 'actions/portlets/general';
// Components
import {Row, Col} from 'components/core/ui/Grid';
import Tag from 'components/core/ui/Tag';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import BackIcon from '@material-ui/icons/ChevronLeft';
import TimeIcon from '@material-ui/icons/AccessTime';


const styles = theme => ({
    // preview of post
    post: {
        borderBottom: `1px solid ${theme.palette.divider}`, // border between articles
        // space between articles
        paddingBottom: `${theme.spacing(2)}px`,
        marginBottom: `${theme.spacing(2)}px`,
        '&:last-child': {
            marginBottom: `-${theme.spacing(1)}px`, // fixing proportion (same space top & bottom)
            borderBottom: 'none'
        }
    },
    postImage: {
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        // style
        background: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        // limit size
        maxWidth: '350px',
        margin: '0 auto',
        // 16:9 ratio
        '&:after': {
            content: '""',
            display: 'block',
            width: '100%',
            paddingTop: '56.25%'
        },
        // image itself
        '& > div': {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            // nicely fit
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    },
    postHeadlineLink: {
        // get rid of default link color
        color: theme.palette.text['primary']
    },
    postText: {
        '& p': {
            textAlign: 'justify'
        }
    },
    // Detail of the post
    postDetailHeadline: {
        position: 'relative',
        // text
        '& div': {
            padding: `8px 0 8px ${48 + theme.spacing(1)}px` // manual v-align and space for button
        },
        // back button
        '& button': {
            position: 'absolute',
            left: '0',
            top: '0'
        }
    },
    postDetailImage: {
        overflow: 'hidden',
        // style
        background: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        // image itself
        '& > img': {
            display: 'block',
            margin: '0 auto',
            maxHeight: '450px'
        },
        // mobile image
        '&.mobile': {
            display: 'none',
            marginBottom: `${theme.spacing(1)}px`
        },
        // responsive
        [theme.breakpoints.down('xs')]: {
            display: 'none',
            '&.mobile': {
                display: 'block'
            }
        }
    },
    postDetailText: {
        '& p': {
            textAlign: 'justify'
        }
    }
});

/**
 * Render single News post
 */
class Post extends React.Component {
    /**
     * During initialization fetch Image File
     */
    componentDidMount() {
        // do we need to fetch Avatar?
        if (!this.props.imageFile && this.props.post.get('image')) {
            this.props.fetchItem(FileModel, 'files', this.props.post.get('image'), {affect_state: false});
        }
    }

    renderTags() {
        return <div>
            {this.props.post.get('tags').map((tag, idx) =>
                <Tag color={tag.get('color') || 'primary'} key={idx}>{tag.get('name')}</Tag>
            )}
            <Tag color='transparent'>
                <TimeIcon /><span>{Moment(this.props.post.get('created_at')).format('l')}</span>
            </Tag>
        </div>;
    }

    render() {
        if (this.props.detail) {
            return <div>
                {this.props.imageFile && <div className={`${this.props.classes.postDetailImage} mobile`}>
                    <img src={this.props.imageFile.get('file')} alt={this.props.post.get('headline')} />
                </div>}
                <Typography variant='h5' gutterBottom className={this.props.classes.postDetailHeadline}>
                    <IconButton onClick={() => this.props.setDetail(null)}>
                        <BackIcon />
                    </IconButton>
                    <div>{this.props.post.get('headline')}</div>
                </Typography>
                {this.renderTags()}
                {this.props.imageFile && <div className={this.props.classes.postDetailImage}>
                    <img src={this.props.imageFile.get('file')} alt={this.props.post.get('headline')} />
                </div>}
                <div className={this.props.classes.postDetailText} dangerouslySetInnerHTML={{__html: marked(this.props.post.get('text_short'), {breaks: true})}} />
                <div className={this.props.classes.postDetailText} dangerouslySetInnerHTML={{__html: marked(this.props.post.get('text'), {breaks: true})}} />
            </div>;
        } else {
            return <div className={this.props.classes.post}>
                <Row wrap>
                    <Col width='275px'>
                        <a href='#' onClick={(e) => { e.preventDefault(); this.props.setDetail(this.props.post); }}
                           className={this.props.classes.postImage}>
                            <div style={{backgroundImage: `url("${this.props.imageFile ? this.props.imageFile.get('file') : ''}")`}} />
                        </a>
                    </Col>
                    <Col>
                        <Typography variant='h5' gutterBottom>
                            <a href='#' onClick={(e) => { e.preventDefault(); this.props.setDetail(this.props.post); }}
                               className={this.props.classes.postHeadlineLink}>
                                {this.props.post.get('headline')}
                            </a>
                        </Typography>
                        {this.renderTags()}
                        <div className={this.props.classes.postText} dangerouslySetInnerHTML={{__html: marked(this.props.post.get('text_short'), {breaks: true})}} />
                    </Col>
                </Row>
            </div>;
        }
    }
}

Post = withStyles(styles)(Post);
Post = connect((state, props) => ({
    imageFile: state.shared.getIn(['items', 'files']).find(el => el.get('url') === props.post.get('image'))
}), (dispatch) => bindActionCreators({
    fetchItem
}, dispatch))(Post);

/**
 * News portlet to render News and it's detail
 */
class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null
        };
    }

    /**
     * During initialization fetch Articles
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready' || this.props.loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'related_data_set']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        }
    }

    render() {
        return <React.Fragment>
            {this.props.portletState === 'fetching'
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'error'
                ? <CardContent>
                <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
            </CardContent>
                : this.props.portletState === 'ready'
                    ? this.state.detail === null
                    ? <CardContent>
                    {this.props.items.map((post, idx) =>
                        <Post post={post} setDetail={(post) => this.setState({detail: post})} key={idx} />
                    )}
                </CardContent>
                    : <CardContent>
                    <Post post={this.state.detail} detail={true} setDetail={(post => this.setState({detail: post}))} />
            </CardContent>
                : <div />}
        </React.Fragment>;
    }
}

News = withStyles(styles)(News);
News = connect((state, props) => ({
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]),
    items: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList()
}), (dispatch) => bindActionCreators({
    setPortletState,
    fetchItems
}, dispatch))(News);

export default injectIntl(News);
