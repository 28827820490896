'use strict';
import {Map} from 'immutable';
import keymirror from 'keymirror';
import {listAsModel, TableCountry, TableCompanyType, TableStaticText} from 'lib/models';


export const constants = keymirror({
    TABLES_LINKS: null,
    TABLES_DATA: null
});

let defaults = Map({
    links: null, // null states indicates 'not loaded yet'
    tables: Map({
        // null states indicates 'not loaded yet'
        'countries': null,
        'company-types': null,
        'static-texts': null
    })
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets links to tables resources
        case constants.TABLES_LINKS:
            return state.set('links', new Map(action.links));

        // sets table data
        case constants.TABLES_DATA:
            // defaults
            if (action.data === null && action.which === null) {
                return state.set('tables', defaults.get('tables'));
            } else {
                let tableClass = null;
                switch (action.which) {
                    case 'countries':
                        tableClass = TableCountry;
                        break;
                    case 'company-types':
                        tableClass = TableCompanyType;
                        break;
                    case 'static-texts':
                        tableClass = TableStaticText;
                        break;
                    default:
                        throw new Error(`Unknown table ${action.which}`);
                }
                return state.setIn(['tables', action.which], listAsModel(tableClass, action.data));
            }

        default:
            return state;
    }
};
