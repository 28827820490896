'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FileModel} from 'lib/models';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
import {setPortletState} from 'actions/portlets/general';
// Components
import {Row, Col} from 'components/core/ui/Grid';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import Tag from 'components/core/ui/Tag';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';


const styles = theme => ({
    // holder of whole contact
    contact: {
        position: 'relative',
        marginBottom: `${theme.spacing(4)}px`,
        '&:last-child': {
            marginBottom: '0'
        }
    },
    // picture of person (avatar)
    contactAvatar: {
        position: 'absolute',
        top: '0',
        left: '0',
        // enlarge slighlty
        height: '80px',
        width: '80px'
    },
    // holder of all content (except avatar)
    contactContent: {
        // avatar space
        paddingLeft: `${(theme.spacing(2)) + 80}px`,
    },
    // phone, email, etc.
    contactLinks: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        // extra space (space between contactLink)
        marginRight: `-${theme.spacing(1)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            marginRight: '0'
        }
    },
    contactLink: {
        // ensure that content doesn't overflow
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // allow icon position
        position: 'relative',
        // icon space and space between
        paddingLeft: `${theme.spacing(1) + 24}px`,
        paddingRight: `${theme.spacing(1)}px`,
        // v-align with icon
        lineHeight: '24px',
        // icon
        '& svg': {
            position: 'absolute',
            top: '0',
            left: '0'
        },
        // responsive
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: '100%',
            paddingRight: '0'
        }
    }
});

/**
 * Renders Contact itself
 */
class Contact extends React.Component {
    /**
     * During initialization fetch Avatar File
     */
    componentDidMount() {
        // do we need to fetch Avatar?
        if (!this.props.avatarFile && this.props.contact.get('avatar')) {
            this.props.fetchItem(FileModel, 'files', this.props.contact.get('avatar'), {affect_state: false});
        }
    }

    render() {
        return <div className={this.props.classes.contact}>
            <Avatar alt={this.props.contact.get('name')} src={this.props.avatarFile ? this.props.avatarFile.get('file') : ''}
                    className={this.props.classes.contactAvatar} />
            <div className={this.props.classes.contactContent}>
                <Typography variant='subtitle1' gutterBottom>{this.props.contact.get('name')}</Typography>
                {this.props.contact.get('role') &&
                <div>
                    <Tag color={this.props.contact.get('role_display') || 'primary'}>{this.props.contact.get('role')}</Tag>
                </div>
                }
                <hr />
                <div className={this.props.classes.contactLinks}>
                    <a href={`mailto: ${this.props.contact.get('email')}`} className={`${this.props.classes.contactLink} hover-border`}>
                        <EmailIcon />{this.props.contact.get('email')}
                    </a>
                    <a href={`tel: ${this.props.contact.get('phone')}`} className={`${this.props.classes.contactLink} hover-border`}>
                        <PhoneIcon />{this.props.contact.get('phone')}
                    </a>
                </div>
            </div>
        </div>;
    }
}

Contact = withStyles(styles)(Contact);
Contact = connect((state, props) => ({
    avatarFile: state.shared.getIn(['items', 'files']).find(el => el.get('url') === props.contact.get('avatar'))
}), (dispatch) => bindActionCreators({
    fetchItem
}, dispatch))(Contact);

/**
 * Renders individual Persons from Contacts - with their Phone, Email, Role, etc.
 */
class Contacts extends React.Component {
    /**
     * During initialization fetch Contacts
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready' || this.props.loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'related_data_set']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        }
    }

    render() {
        return <React.Fragment>
            {this.props.portletState === 'fetching'
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'error'
                ? <CardContent>
                <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
            </CardContent>
                : this.props.portletState === 'ready'
                ? <CardContent>
                {['100%', '75%', '66.6667%'].includes(this.props.portlet.getWidth())
                    ? <Row wrap undivider>
                    {this.props.items.map((contact, idx) =>
                        <Col width='50%' divider key={idx}>
                            <Contact contact={contact} />
                        </Col>
                    )}
                </Row>
                    : <div>
                    {this.props.items.map((contact, idx) =>
                        <Contact contact={contact} key={idx} />
                    )}
                </div>}
            </CardContent>
                : <div />}
        </React.Fragment>;
    }
}

Contacts = withStyles(styles)(Contacts);
Contacts = connect((state, props) => ({
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]),
    items: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList()
}), (dispatch) => bindActionCreators({
    setPortletState,
    fetchItems
}, dispatch))(Contacts);

export default injectIntl(Contacts);
