'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// Actions
import {setErrorState as setErrorApiState} from 'actions/api';
// Components
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
    card: {
        // red card
        background: theme.palette.danger['main'],
        border: '0'
    },
    // shared between cardHeader title, cardHeader subheaderText and button icon
    cardText: {
        // readable color
        color: theme.palette.common.white
    }
});

/**
 * Error card displays unhandled API errors, #500, etc.
 */
class Error extends React.Component {
    /**
     * Watch for router location change to close the error (User continues to use the app)
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // check for change
        if (this.props.error_identifier && this.props.location.pathname !== prevProps.location.pathname) {
            // clear error
            this.props.setErrorApiState(null, null, null);
        }
    }

    render() {
        return <div>
            {this.props.error_identifier && <React.Fragment>
                <Card className={this.props.classes.card}>
                    <CardHeader
                        _classes={{
                            title: this.props.classes.cardText,
                            subheaderText: this.props.classes.cardText,
                        }}
                        title={<div>{!isNaN(this.props.error_code) && `#${this.props.error_code} `}<FormattedMessage id='errors.inapp.title' /></div>}
                        subheader={<span>
                                <FormattedMessage id='errors.inapp.description'
                                                  values={{error_identifier: this.props.error_identifier}} />
                                <br />
                            {this.props.error_message && <span>
                                    <em>{this.props.error_message}</em>
                                    <br />
                                </span>}
                            <FormattedMessage id='errors.inapp.action' />
                            </span>}
                        action={<div>
                            <Tooltip title={<FormattedMessage id='actions.close' />}>
                                <ActionButton iconButton onClick={() => this.props.setErrorApiState(null, null, null)}>
                                    <CloseIcon className={this.props.classes.cardText} />
                                </ActionButton>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id='errors.refresh' />}>
                                <ActionButton iconButton onClick={() => window.location.reload()}>
                                    <RefreshIcon className={this.props.classes.cardText} />
                                </ActionButton>
                            </Tooltip>
                        </div>}
                    />
                </Card>
                <SpaceDivider grid />
            </React.Fragment>}
        </div>;
    }
}

Error = withStyles(styles)(Error);
Error = connect((state) => ({
    error_identifier: state.api.get('error_identifier'),
    error_code: state.api.get('error_code'),
    error_message: state.api.get('error_message')
}), (dispatch) => bindActionCreators({
    setErrorApiState
}, dispatch))(Error);

export default injectIntl(withRouter(Error));
