'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Portlet} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import Icon from '@material-ui/core/Icon';


/**
 * Simple Select for Portlets types
 */
class PortletField extends React.Component {
    render() {
        // Split some stuff from props
        let {intl, ...rest_of_props} = this.props;

        return <Field fieldType='Select' {...rest_of_props}>
            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
            {new Portlet().getTypes().map((type, idx) =>
                <MenuItem key={idx} value={type}>
                    <ListItemIcon className='icon'><Icon>{new Portlet({type: type}).getTypeIcon()}</Icon></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id={`portletfield.type.${type}`} /></ListItemText>
                </MenuItem>
            )}
        </Field>;
    }
}

export default injectIntl(PortletField);
