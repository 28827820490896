'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Folder Icon
 */
export default class Folder extends React.Component {
    static defaultProps = {
        viewBox: '0 0 58 58'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: theme.palette.primary[500]}} points='25,10.5 20,3.5 0,3.5 0,10.5 0,54.5 58,54.5 58,17.5 30,17.5 ' />
            <polygon style={{fill: theme.palette.primary[800]}} points='30,17.5 58,17.5 58,10.5 25,10.5 ' />
            <path style={{fill: theme.palette.common.white}} d='m 41.196596,25.500006 c -0.355242,1.552587 -1.012876,2.960536 -1.878627,4.124153 -0.864233,1.165867 -1.931792,2.089571 -3.075907,2.73397 -1.144516,0.645928 -2.363432,1.01134 -3.532055,1.109589 -0.584672,0.04985 -1.156886,0.03401 -1.705796,-0.03964 -0.16373,-0.02196 -0.325065,-0.04969 -0.484484,-0.08169 l 0,-4.150442 L 23.509904,33.27194 16.5,37.348012 23.509904,41.424008 30.519727,45.5 l 0,-4.074949 c 0.151836,-0.03714 0.303433,-0.07574 0.453589,-0.117301 0.504283,-0.139412 0.998509,-0.303267 1.479561,-0.490354 0.962343,-0.374016 1.871682,-0.841054 2.71005,-1.385037 1.678412,-1.087558 3.068005,-2.482321 4.101156,-4.030968 1.03507,-1.548889 1.714899,-3.252305 2.028229,-4.953872 0.315884,-1.70237 0.261522,-3.397585 -0.09572,-4.947519 z' />
        </svg>;
    }
}
