'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Page} from 'lib/models';
// Actions
import {toggleMobileMenu} from 'actions/app';
import {fetchItems} from 'actions/shared';
// Components
import CustomerLogo from 'components/core/ui/CustomerLogo';
import {withRouter} from 'react-router-dom';
// material-ui
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import Icon from '@material-ui/core/Icon';


const styles = theme => ({
    // menu loader
    loaderItem: {
        margin: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
        padding: `0 ${theme.spacing(2)}px`
    },
    // page-preview text
    menuItemTextSecondary: {
        fontStyle: 'italic',
        marginTop: '-5px', // move closer to title
        fontSize: theme.typography.pxToRem(12)
    },
    // menuItemText with page-preview text
    menuItemTextMultiline: {
        // make sure it's same size as one line
        margin: '0'
    },
    // menu itself
    menuList: {
        // different proportion
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        // style (same as card)
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        borderRadius: `${theme.shape.borderRadius}px`
    },
    // style of menu items
    menuItem: {
        // tweak proportions (44px height)
        padding: `6px ${theme.spacing(4)}px`,
        minHeight: 'auto',
        // get rid of disabled style
        opacity: '1 !important'
    },
    menuItemIcon: {
        minWidth: '0', // get rid of not necessary min-width
        marginRight: `${theme.spacing(3)}px` // space between icon and text
    },
    menuDivider: {
        margin: `${theme.spacing(1)}px 0`
    },
    menuItemSubheader: {
        lineHeight: '32px',
        padding: `6px ${theme.spacing(2)}px` // 6px matches menuItem
    },
    // PC menu (fixed)
    desktopMenuHolder: {
        position: 'relative',
        zIndex: theme.zIndex.drawer,
        // size of menu
        width: '280px',
        // display only on PC
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        // customer logo - move into Header
        '& .customer-logo': {
            marginTop: `-${66 - theme.spacing(2)}px` // size of the header
        }
    },
    // Mobile menu (swipeable drawer)
    mobileMenuHolder: {
        position: 'relative',
        zIndex: theme.zIndex.drawer,
        // size of menu
        width: `${280 + theme.spacing(2)}px `,
        maxWidth: '100%',
        minHeight: '100%',
        background: theme.palette.grey[100], // same as application bg
        '& $menuList, & .customer-logo': {
            // fix proportion
            marginRight: `${theme.spacing(2)}px`
        }
    }
});

/**
 * Application Menu for Logged User only
 */
class Menu extends React.Component {
    /**
     * During initialization fetch pages
     */
    componentDidMount() {
        if (this.props.pages_loaded === false) {
            this.props.fetchItems(Page, 'pages', 'pages', null, null, {affect_state: false});
        }
    }

    /**
     * Renders MenuItem for Menu
     *
     * @param page - Page model
     * @param idx - React key
     */
    renderMenuItem(page, idx) {
        return <MenuItem key={idx} button disabled={this.props.state !== null}
                         onClick={this.props.state === null ? () => { this.props.history.push(idx === 0 ? '/' : `/${page.slug}`); this.props.openMobileMenu && this.props.toggleMobileMenu(false); } : () => {}}
                         classes={{root: this.props.classes.menuItem}}
                         selected={(idx === 0 && this.props.location.pathname === '/') || this.props.location.pathname === `/${page.slug}` || this.props.location.pathname.startsWith(`/${page.slug}/`)}>
            <ListItemIcon className={this.props.classes.menuItemIcon}>
                <Icon>{page.get('icon')}</Icon>
            </ListItemIcon>
            <ListItemText classes={{secondary: this.props.classes.menuItemTextSecondary, multiline: this.props.classes.menuItemTextMultiline}}
                          primary={page.get('title')}
                          secondary={page.get('active') === false ? <FormattedMessage id='menu.preview' /> : undefined} />
        </MenuItem>;
    }

    render() {
        let navigation = <MenuList className={this.props.classes.menuList}>
            {this.props.pages_loaded === false
                ? <li className={this.props.classes.loaderItem}>
                <LinearProgress />
            </li>
                : this.props.pages_items.size === 0
                ? <li />
                : this.props.pages_items.map((page, idx) =>
                    this.renderMenuItem(page, idx))
            }
        </MenuList>;

        return <div>
            <div className={this.props.classes.desktopMenuHolder}>
                <CustomerLogo />
                {navigation}
            </div>
            <SwipeableDrawer open={this.props.openMobileMenu}
                             onOpen={() => this.props.toggleMobileMenu(true)}
                             onClose={() => this.props.toggleMobileMenu(false)}>
                <div className={this.props.classes.mobileMenuHolder}>
                    <CustomerLogo onClick={() => this.props.toggleMobileMenu(false)} />
                    {navigation}
                </div>
            </SwipeableDrawer>
        </div>;
    }
}

Menu = withStyles(styles)(Menu);
Menu = connect((state) => ({
    state: state.app.get('state'),
    openMobileMenu: state.app.get('openMobileMenu'),
    pages_loaded: state.shared.getIn(['loaded', 'pages']),
    pages_items: state.shared.getIn(['items', 'pages'])
}), (dispatch) => bindActionCreators({
    toggleMobileMenu,
    fetchItems
}, dispatch))(Menu);

export default withRouter(injectIntl(Menu));
