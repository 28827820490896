'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
// Components
import AwesomeLogo from 'components/core/icons/AwesomeLogo';
import SnomLogo from 'components/core/icons/SnomLogo';
import {Link} from 'react-router-dom';


const styles = theme => ({
    customerLogoHolder: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // style (same as card)
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        borderRadius: `${theme.shape.borderRadius}px`,
        // proportion
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2) + 30}px`, // + size of headline
        // logo itself
        '& svg': {
            height: '30px',
            width: '123px'
        },
        // partner levels style
        '&.partner-level': {
            border: '2px solid transparent',
            boxShadow: 'none',
            // for gradient border
            position: 'relative',
            backgroundClip: 'padding-box',
            // fix style and proportion for headline
            marginBottom: `${theme.spacing(2) + 30 + 2}px`, // + size of headline & border
            paddingBottom: `${theme.spacing(2)}px`,
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
            // gradient border
            '&:before': {
                borderRadius: 'inherit', // radius as parent
                // pseudo element
                content: '""',
                display: 'block',
                // full-size
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                // move to border
                margin: '-2px', // size of border
                zIndex: '-1', // not before background
                // style
                background: (props) => `linear-gradient(to bottom right, ${props.partner_level.get('primary_color')}, ${props.partner_level.get('highlight_color')})`
            }
        },
        // customers specific style
        '&.awesome': {
            '& svg': {
                height: '68px',
                width: '108px'
            }
        },
        '&.snom': {
            '& svg': {
                height: '30px',
                width: '123px'
            }
        },
        '&.asisi': {
            border: `1px solid ${theme.palette.primary[800]}`,
            background: theme.palette.common.black,
            '& img': {
                height: '30px'
            }
        }
    },
    // application Headline to divide Front and MC
    headline: {
        // sitting at the bottom
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        padding: `${theme.spacing(1)}px`,
        // text in center
        textAlign: 'center',
        // style
        background: theme.palette.primary[500],
        color: theme.palette.common.white,
        borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
        borderBottomRightRadius: `${theme.shape.borderRadius}px`,
        // partner level style
        '&.partner-level': {
            bottom: `-${30 + 2}px`, // size of Headline & border
            left: '-2px', // size of border
            right: '-2px', // size of border
            boxShadow: theme.shadows[1]
        },
        // customers specific style
        '&.asisi': {
            background: theme.palette.primary[800]
        }
    }
});

/**
 * Renders logo of Company which owns the Portal
 */
class CustomerLogo extends React.Component {
    render() {
        // Split some stuff from props
        let {dispatch, state, config, partner_level, noPartnerLevel, classes, className, ...rest_of_props} = this.props;
        let rootClasses = [
            className,
            classes.customerLogoHolder,
            config ? config.get('customer') : '',
            // partner_level.get('code') && !noPartnerLevel ? 'partner-level' : '',
            'customer-logo'
        ].filter(Boolean).join(' ');
        let headlineClasses = [
            classes.headline,
            config ? config.get('customer') : '',
            // partner_level.get('code') && !noPartnerLevel ? 'partner-level' : '',
        ].filter(Boolean).join(' ');

        return <Link className={rootClasses} to='/' {...rest_of_props}>
            {config !== null
                ? config.get('customer') === 'awesome'
                    ? <AwesomeLogo />
                : config.get('customer') === 'snom'
                    ? <SnomLogo />
                : config.get('customer') === 'asisi'
                    ? <img alt='Asisi' src={require('img/AsisiLogo.png')} />
                    : <div />
                : <div />}
            <div className={headlineClasses}>
                {config !== null && config.get('customer') === 'asisi'
                    ? 'The world of Yadegar Asisi'
                    : <FormattedMessage id='header.headline' />
                }
            </div>
        </Link>;
    }
}

CustomerLogo = withStyles(styles)(CustomerLogo);
CustomerLogo = connect((state) => ({
    state: state.app.get('state'),
    config: state.api.get('config'),
    partner_level: state.company.get('partner_level')
}))(CustomerLogo);

export default injectIntl(CustomerLogo);
