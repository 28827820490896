'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// Components
import ReactSwipe from 'react-swipe';
import {Row, Col} from 'components/core/ui/Grid';
// material-ui
import MUITabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';


const styles = theme => ({
    // holder of content in tab
    tabContentHolder: {
        // plain holder, so we can apply margin to menuList without breaking react-swipe
        '&.hide': {
            // reduce height of not visible menuList so we also reduce size of browser scrollbar
            height: '1px'
        }
    }
});

/**
 * Integration of react-swipe with @mui tabs
 */
class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: null, // current selected tab (1 admin menu / 0 customer menu)
            sliding: false, // indicator that sliding is in process
            slidingTimer: null // timer to reset sliding
        };
        this.swipeRef = React.createRef(); // ref for react-swipe instance
    };

    render() {
        return <React.Fragment>
            <AppBar position='static' {...this.props.appBarProps}>
                <MUITabs value={this.state.selectedTab === null ? this.props.initialSelectedTab : this.state.selectedTab}
                         onChange={(event, value) => {
                             // clear previous timer
                             if (this.state.slidingTimer) {
                                 clearTimeout(this.state.slidingTimer);
                             }
                             // set timer to clear sliding
                             let timer = setTimeout(() => {
                                 this.setState({sliding: false});
                             }, 300);
                             // change select tab, mark sliding and set timer
                             this.setState({selectedTab: value, sliding: true, slidingTimer: timer}, () => {
                                 // make sure to slide content
                                 this.swipeRef.current.swipe.slide(value);
                             });
                         }}
                         scrollButtons='off' indicatorColor='secondary'
                         {...this.props.tabsProps}>
                    {this.props.tabs}
                </MUITabs>
            </AppBar>
            <Row nospace>
                <Col width='100%'>
                    <ReactSwipe ref={this.swipeRef}
                                swipeOptions={{
                                    startSlide: this.props.initialSelectedTab,
                                    continuous: false,
                                    disableScroll: true
                                }}
                                {...this.props.swipeOptions}>
                        {this.props.content.map((content, idx) => <div key={idx} className={`${this.props.classes.tabContentHolder}${(this.state.selectedTab === null ? this.props.initialSelectedTab : this.state.selectedTab) !== idx && !this.props.sliding ? ' hide' : ''}`}>
                            {content}
                        </div>)}
                    </ReactSwipe>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

Tabs = withStyles(styles)(Tabs);
export default Tabs;
