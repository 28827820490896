'use strict';
import {Map} from 'immutable';
import keymirror from 'keymirror';
import {asModel, Company, PartnerLevel} from 'lib/models';


export const constants = keymirror({
    COMPANY_COMPANY: null,
    COMPANY_PARTNER_LEVEL: null
});

let defaults = Map({
    company: new Company(),
    partner_level: new PartnerLevel()
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets / remove Company
        case constants.COMPANY_COMPANY:
            return state.set('company', action.company ? asModel(Company, action.company) : defaults.get('company'));

        // sets / remove PartnerLevel
        case constants.COMPANY_PARTNER_LEVEL:
            return state.set('partner_level', action.partner_level ? asModel(PartnerLevel, action.partner_level) : defaults.get('partner_level'));

        default:
            return state;
    }
};
