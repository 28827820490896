'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Company} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {updateCompany, updateCompanyLogo} from 'actions/company';
// Components
import PartnerLevel from 'components/modules/portlets/PartnerLevel';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {CountryField} from 'components/core/ui/Field';
import DropFile from 'components/core/ui/DropFile';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// icons
import CompanyIcon from '@material-ui/icons/Business';
import LogoIcon from '@material-ui/icons/Wallpaper';
import SubmitIcon from '@material-ui/icons/Save';


/**
 * Change User Company details
 */
class Detail extends React.Component {
    render() {
        return <Row wrap>
            <Col width='66.6667%'>
                <Card>
                    <CardHeader
                        title={<FormattedMessage id='company.detail.title' />}
                        subheader={<FormattedMessage id='company.detail.subheader' />}
                        action={
                            <ActionButton iconButton disabled>
                                <CompanyIcon />
                            </ActionButton>
                        }
                    />
                    <CardContent>
                        <Form>
                            <Field name='name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.basic.form.fields.name'})}*`} />
                            <Field name='email' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.basic.form.fields.email'})}*`} />
                            <SpaceDivider none />
                            <Field name='phone_nr' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.basic.form.fields.phone'})}*`} />
                            <Field name='website_url' fieldType='URLField' label={`${this.props.intl.formatMessage({id: 'company.detail.basic.form.fields.website_url'})}*`}
                                   change={this.props.change} selectedValue={this.props.formValues ? this.props.formValues.website_url : ''} />
                            <SpaceDivider none />
                            <Field name='vat_number' fieldType='TextField' label={<FormattedMessage id='company.detail.basic.form.fields.vat_number' />} />
                        </Form>
                        <SpaceDivider />
                    </CardContent>
                    <CardHeader
                        title={<FormattedMessage id='company.detail.address.title' />}
                        subheader={<FormattedMessage id='company.detail.address.subheader' />}
                    />
                    <CardContent>
                        <Form>
                            <CountryField name='country' formName='companyForm' label={`${this.props.intl.formatMessage({id: 'company.detail.address.form.fields.country'})}*`} />
                            <Field name='city' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.address.form.fields.city'})}*`} />
                            <SpaceDivider none />
                            <Field name='street' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.address.form.fields.street'})}*`} />
                            <Field name='code' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'company.detail.address.form.fields.code'})}*`} />
                        </Form>
                    </CardContent>
                    {['updating_company', 'updated_company', 'failed_update_company'].includes(this.props.state)
                        ? <CardActionsLoader success={this.props.state === 'updated_company'}
                                             failure={this.props.state === 'failed_update_company'}
                                             postAnimation={success => this.props.setState(null)} />
                        : <MuiThemeProvider theme={altTheme}>
                        <CardActions>
                            <Button disabled={this.props.state !== null}
                                    variant='contained' color='primary' type='submit'
                                    onClick={() => this.props.state === null ? this.props.handleSubmit() : {}}>
                                <SubmitIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </CardActions>
                    </MuiThemeProvider>}
                </Card>
            </Col>
            <Col width='33.3333%'>
                {this.props.company.get('partner_level') && <React.Fragment>
                    <Card>
                        <PartnerLevel />
                    </Card>
                    <SpaceDivider grid />
                </React.Fragment>}
                <Card>
                    <CardHeader
                        title={<FormattedMessage id='company.detail.logo.title' />}
                        subheader={<FormattedMessage id='company.detail.logo.subheader' />}
                        action={
                            <ActionButton iconButton disabled>
                                <LogoIcon />
                            </ActionButton>
                        }
                    />
                    <CardContent>
                        <DropFile avatar={this.props.company.get('logo_url')} noCropAvatar
                                  onDropped={this.props.updateCompanyLogo}
                                  disabled={this.props.state !== null}
                                  loading={this.props.state === 'updating_company_logo' || this.props.state === 'fetching_company'} />
                        <SpaceDivider grid />
                    </CardContent>
                </Card>
            </Col>
        </Row>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'phone_nr', data.phone_nr) &&
    validator.isMobilePhone(null, errors, 'phone_nr', data.phone_nr, 'any');
    validator.isNotNull(null, errors, 'website_url', data.website_url) &&
    validator.isURL(null, errors, 'website_url', data.website_url,
        {protocols: ['http', 'https'], require_protocol: true});

    validator.isNotNull(null, errors, 'country', data.country);
    validator.isNotNull(null, errors, 'city', data.city);
    validator.isNotNull(null, errors, 'street', data.street);
    validator.isNotNull(null, errors, 'code', data.code);

    return errors;
};

Detail = reduxForm({
    form: 'companyForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new Company().popUpFields(values);

        return dispatch(updateCompany(rest_of_data));
    }
})(Detail);

Detail = connect((state) => {
    let item = state.company.get('company');

    return {
        state: state.app.get('state'),
        company: item,
        initialValues: item ? item.toObject() : {},
        formValues: getFormValues('companyForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    updateCompany,
    updateCompanyLogo
}, dispatch))(Detail);

export default injectIntl(Detail);
