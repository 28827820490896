'use strict';
import Moment from 'moment';
import {Map, fromJS} from 'immutable';
import keymirror from 'keymirror';


export const constants = keymirror({
    PORTLET_CALENDAR_DATA: null,
    PORTLET_CALENDAR_DATE: null
});

let defaults = Map({
    calendar_data: Map()
});

/**
 * When adding additional Events to Calendar data (from other Calendar), ensure that we have them correctly ordered
 *
 * @param current_events - Current events already in store
 * @param new_events - Additional events to be merged
 * @return [array] merged_events - Merged and stored events by date
 */
function _mergeEvents(current_events, new_events) {
    // check if we already have current_events to merge
    if (!current_events) {
        return new_events;
    }

    let merged_events = [...current_events, ...new_events];
    merged_events.sort((a, b) => {
        let a_date = Moment(a.getIn(['start', 'dateTime']) || a.getIn(['start', 'date']));
        let b_date = Moment(b.getIn(['start', 'dateTime']) || b.getIn(['start', 'date']));

        if (a_date < b_date) {
            return -1;
        } else if (a_date > b_date) {
            return 1;
        } else {
            return 0;
        }
    });

    return merged_events;
}

export default (state = defaults, action) => {
    switch (action.type) {
        // sets selected date in Calendar
        case constants.PORTLET_CALENDAR_DATE:
            return state.setIn(['calendar_data', action.portlet.get('url'), 'selectedDate'], action.date);

        // sets calendar data for month or remove all of them
        case constants.PORTLET_CALENDAR_DATA:
            if (!action.portlet && !action.date && !action.data) {
                return state.set('calendar_data', defaults.get('calendar_data'));
            } else {
                // process data so we can use functions like .get()
                let calendar_data = fromJS(action.data);
                // get start of month
                let start_of_month = Moment(action.date).startOf('month').format();
                // get current saved calendars
                let calendars = state.getIn(['calendar_data', action.portlet.get('url'), 'calendars']) || [];
                // try to find our calendar in collection
                let calendar = calendars.find(el => el.name === calendar_data.get('summary'));

                // if we don't have calendar or calendar color saved, do it now
                if (!calendar || calendar.color === null) {
                    let color = null;
                    // check if we have at least one item
                    if (calendar_data.get('items').size >= 1) {
                        // try to obtain key, so we can obtain color
                        color = action.calendars.find(el => el.get('calendar_key') === calendar_data.get('items').get(0).getIn(['organizer', 'email'])).get('color');
                    }
                    // check if add new calendar to list or replace
                    if (calendar) {
                        calendars[calendars.indexOf(calendar)] = {
                            name: calendar_data.get('summary'),
                            color: color
                        };
                    } else {
                        // add new calendar to list
                        calendars.unshift({
                            name: calendar_data.get('summary'),
                            color: color
                        });
                    }
                    // set it to state
                    state = state.setIn(['calendar_data', action.portlet.get('url'), 'calendars'], calendars);
                }

                // set events
                return state.setIn(
                    ['calendar_data', action.portlet.get('url'), 'events', start_of_month],
                    _mergeEvents(
                        state.getIn(['calendar_data', action.portlet.get('url'), 'events', start_of_month]),
                        calendar_data.get('items')
                    )
                );
            }
        default:
            return state;
    }
};
