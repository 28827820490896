'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, submit as reduxFormSubmit, formValueSelector} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {User} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {updateProfile, updateProfileAvatar} from 'actions/users';
// Components
import PasswordChange from 'components/modules/users/PasswordChange';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import DropFile from 'components/core/ui/DropFile';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import Tabs from 'components/core/ui/Tabs';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Tab from 'components/core/ui/mui/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// icons
import AvatarIcon from '@material-ui/icons/Portrait';
import AccountIcon from '@material-ui/icons/Assignment';
import Submit1Icon from '@material-ui/icons/Save';
import Submit2Icon from '@material-ui/icons/Lock';
import SkypeIcon from 'components/core/icons/social/Skype';
import TwitterIcon from 'components/core/icons/social/Twitter';
import LinkedinIcon from 'components/core/icons/social/Linkedin';
import XingIcon from 'components/core/icons/social/Xing';


/**
 * Profile Page with User fields, preferences, settings, etc.
 */
class Detail extends React.Component {
    render() {
        return <div>
            <Row wrap>
                <Col width='66.6667%'>
                    <Card>
                        <CardHeader
                            title={<FormattedMessage id='users.detail.title' />}
                            subheader={<FormattedMessage id='users.detail.subheader' />}
                            action={
                                <ActionButton iconButton disabled>
                                    <AccountIcon />
                                </ActionButton>
                            }
                        />
                        <Tabs
                            tabs={[
                                <Tab key={0} label={<FormattedMessage id='users.detail.tab.one' />} />,
                                <Tab key={1} label={<FormattedMessage id='users.detail.tab.two' />} />
                            ]}
                            initialSelectedTab={0}
                            content={[
                                <React.Fragment>
                                    <SpaceDivider />
                                    <CardHeader
                                        title={<FormattedMessage id='users.detail.basic.title' />}
                                        subheader={<FormattedMessage id='users.detail.basic.subheader' />}
                                    />
                                    <CardContent>
                                        <Form onSubmit={this.props.handleSubmit}>
                                            <Field name='first_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.detail.basic.form.fields.first_name'})}*`} />
                                            <Field name='last_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.detail.basic.form.fields.last_name'})}*`} />
                                            <SpaceDivider none />
                                            <Field name='email' type='email' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.detail.basic.form.fields.email'})}*`}
                                                   helperText={this.props.initialValues.email !== this.props.selectedEmail ? <FormattedMessage id='users.detail.basic.emailconfirm.info' /> : undefined}
                                            />
                                            <Field name='phone_nr' fieldType='TextField' label={<FormattedMessage id='users.detail.basic.form.fields.phone' />} />
                                        </Form>
                                        <SpaceDivider />
                                    </CardContent>
                                    <CardHeader
                                        title={<FormattedMessage id='users.detail.more.title' />}
                                        subheader={<FormattedMessage id='users.detail.more.subheader' />}
                                    />
                                    <CardContent>
                                        <Form onSubmit={this.props.handleSubmit}>
                                            <FieldWithIconHolder>
                                                <FieldIcon before disabled><SkypeIcon /></FieldIcon>
                                                <Field name='skype' withIconBefore fieldType='TextField' label={<FormattedMessage id='users.detail.more.form.fields.skype' />} />
                                            </FieldWithIconHolder>
                                            <FieldWithIconHolder>
                                                <FieldIcon before disabled><TwitterIcon /></FieldIcon>
                                                <Field name='twitter' withIconBefore fieldType='TextField' label={<FormattedMessage id='users.detail.more.form.fields.twitter' />} />
                                            </FieldWithIconHolder>
                                            <SpaceDivider none />
                                            <FieldWithIconHolder>
                                                <FieldIcon before disabled><LinkedinIcon /></FieldIcon>
                                                <Field name='linkedin' withIconBefore fieldType='TextField' label={<FormattedMessage id='users.detail.more.form.fields.linkedin' />} />
                                            </FieldWithIconHolder>
                                            <FieldWithIconHolder>
                                                <FieldIcon before disabled><XingIcon /></FieldIcon>
                                                <Field name='xing' withIconBefore fieldType='TextField' label={<FormattedMessage id='users.detail.more.form.fields.xing' />} />
                                            </FieldWithIconHolder>
                                        </Form>
                                    </CardContent>
                                    {['updating_profile', 'updated_profile', 'failed_update_profile'].includes(this.props.state)
                                        ? <CardActionsLoader success={this.props.state === 'updated_profile'}
                                                             failure={this.props.state === 'failed_update_profile'}
                                                             postAnimation={success => this.props.setState(null)} />
                                        : <MuiThemeProvider theme={altTheme}>
                                            <CardActions>
                                                <Button disabled={this.props.state !== null}
                                                        variant='contained' color='primary' type='submit'
                                                        onClick={() => this.props.state === null ? this.props.handleSubmit() : {}}>
                                                    <Submit1Icon />
                                                    <FormattedMessage id='actions.save' />
                                                </Button>
                                            </CardActions>
                                        </MuiThemeProvider>}
                                </React.Fragment>,
                                <React.Fragment>
                                    <SpaceDivider />
                                    <PasswordChange />
                                    {['updating_profile', 'updated_profile', 'failed_update_profile'].includes(this.props.state)
                                        ? <CardActionsLoader success={this.props.state === 'updated_profile'}
                                                             failure={this.props.state === 'failed_update_profile'}
                                                             postAnimation={success => this.props.setState(null)} />
                                        : <MuiThemeProvider theme={altTheme}>
                                            <CardActions>
                                                <Button disabled={this.props.state !== null}
                                                        variant='contained' color='primary' type='submit'
                                                        onClick={() => this.props.state === null ? this.props.reduxFormSubmit('userPasswordForm') : {}}>
                                                    <Submit2Icon />
                                                    <FormattedMessage id='users.detail.password.form.submit' />
                                                </Button>
                                            </CardActions>
                                        </MuiThemeProvider>}
                                </React.Fragment>
                            ]}
                        />
                    </Card>
                </Col>
                <Col width='33.3333%'>
                    <Card>
                        <CardHeader
                            title={<FormattedMessage id='users.detail.avatar.title' />}
                            subheader={<FormattedMessage id='users.detail.avatar.subheader' />}
                            action={
                                <ActionButton iconButton disabled>
                                    <AvatarIcon />
                                </ActionButton>
                            }
                        />
                        <CardContent>
                            <DropFile avatar={this.props.user.get('avatar_url')}
                                      onDropped={this.props.updateProfileAvatar}
                                      disabled={this.props.state !== null}
                                      loading={this.props.state === 'updating_profile_avatar'} />
                            <SpaceDivider grid />
                        </CardContent>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    if (data.phone_nr) {
        validator.isMobilePhone(null, errors, 'phone_nr', data.phone_nr, 'any');
    }

    return errors;
};

Detail = reduxForm({
    form: 'userForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new User().popUpFields(values);

        return dispatch(updateProfile(rest_of_data));
    }
})(Detail);

const selector = formValueSelector('userForm');

Detail = connect((state) => {
    let item = state.auth.get('user');

    return {
        state: state.app.get('state'),
        user: item,
        initialValues: item ? item.toObject() : {},
        selectedEmail: selector(state, 'email')
    };
}, (dispatch) => bindActionCreators({
    setState,
    updateProfile,
    updateProfileAvatar,
    reduxFormSubmit
}, dispatch))(Detail);

export default injectIntl(Detail);
