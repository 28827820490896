'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Field as ReduxField} from 'redux-form';
import {injectIntl, FormattedMessage} from 'react-intl';
import marked from 'marked';
// material-ui
import Button from 'components/core/ui/mui/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
// icons
import HeadlineIcon from '@material-ui/icons/FormatSize';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';


const styles = theme => ({
    // holder of whole field with control and preview
    root: {
        // style taken from Field.js formControl
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10',
        // full-size
        width: '100%',
        // group content
        padding: `${theme.spacing(1)}px`,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius
    },
    // holder of all controls
    controls: {
        // highlight
        padding: `${theme.spacing(1)}px`,
        background: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        // space between
        marginBottom: `${theme.spacing(1)}px`
    },
    // buttons to control input
    controlButton: {
        minWidth: '0', // we don't need minimal size
        padding: `${theme.spacing(0.5)}px`, // lower padding, square like
        // icon inside
        '& svg': {
            marginRight: '0'
        }
    },
    // holder of <input />
    inputHolder: {
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative'
    },
    // input itself
    input: {
        ...theme.typography.body2, // match font
        letterSpacing: 'normal' // letter spacing is ignored in textArea
    },
    // border next to input
    highlightBorder: {
        position: 'absolute',
        left: `-${theme.spacing(1) + 1}px`,
        top: `${theme.spacing(2)}px`, // skip label
        bottom: '0',
        width: '1px',
        background: theme.palette.primary[500]
    },
    // holder of preview box
    previewHolder: {
        margin: `${theme.spacing(2)}px 0 0`,
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    // preview label
    previewLabel: {
        marginTop: `${theme.spacing(2)}px`,
        // label like
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12)
    },
    // preview text itself
    preview: {
        // lower space (first & last-child margin)
        margin: `-${theme.spacing(1)}px 0 -${theme.spacing(2)}px`,
        '& p': {
            letterSpacing: 'normal' // letter spacing is ignored in textArea
        }
    }
});

let markdownUniqueId = 0; // unique ID for component
/**
 * Handles click on ControlButton
 *
 * @param input - Redux Input field
 * @param id - ID of input
 * @param string - markdown string, e.g. '**' for bold
 * @param type - intl human-readable type
 * @param after - string also after (not just before like headline)
 */
function _handleControls(input, id, string, type, after = true) {
    let inputRef = document.getElementById(`markdownField-${id}`);
    let selectionStart = inputRef.selectionStart;
    let selectionEnd = inputRef.selectionEnd;
    let value = input.value;

    if (selectionStart === 0 && selectionEnd === 0) {
        value = `${value}${value ? '\n' : ''}${string}${type}${after ? string : ''}`;
    } else {
        value = `${value.slice(0, selectionStart)}${string}${value.slice(selectionStart, selectionEnd) || type}${after ? string : ''}${value.slice(selectionEnd)}`;
    }
    input.onChange(value);
}

const _renderMarkdownField = ({input, id, name, label, classes, intl, helperText, meta: {touched, error}}) => {
    return <div className={classes.root}>
        <div className={classes.controls}>
            <Tooltip title={<FormattedMessage id='markdownfield.controls.headline' />}>
                <Button className={classes.controlButton} onClick={() => _handleControls(input, id, '##### ', intl.formatMessage({id: 'markdownfield.controls.headline'}), false)}>
                    <HeadlineIcon />
                </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id='markdownfield.controls.bold' />}>
                <Button className={classes.controlButton} onClick={() => _handleControls(input, id, '**', intl.formatMessage({id: 'markdownfield.controls.bold'}))}>
                    <BoldIcon />
                </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id='markdownfield.controls.italic' />}>
                <Button className={classes.controlButton} onClick={() => _handleControls(input, id, '*', intl.formatMessage({id: 'markdownfield.controls.italic'}))}>
                    <ItalicIcon />
                </Button>
            </Tooltip>
        </div>
        <FormControl fullWidth error={Boolean(touched && error)}>
            <div className={classes.inputHolder}>
                <div className={classes.highlightBorder} />
                <InputLabel>{label}</InputLabel>
                <Input name={name}
                       id={`markdownField-${id}`}
                       className={classes.input}
                       multiline rows='2' rowsMax='15'
                       {...input} />
            </div>
            {touched && error
                ? <FormHelperText>{error}</FormHelperText>
                : helperText && <FormHelperText>{helperText}</FormHelperText>}
            <FormHelperText>
                <em>
                    <FormattedMessage id='markdownfield.help' values={{
                        link: <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' className='hover-border'>
                            <FormattedMessage id='markdownfield.help.link' />
                        </a>}} />
                </em>
            </FormHelperText>
        </FormControl>
        {input.value && <div className={classes.previewHolder} >
            <div className={classes.previewLabel}><FormattedMessage id='markdownfield.preview' /></div>
            <div className={classes.preview} dangerouslySetInnerHTML={{__html: marked(input.value, {breaks: true})}} />
        </div>}
    </div>;
};

/**
 * TextArea with additional buttons which supports Markdown
 */
class MarkdownField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0 // unique ID for component
        };
    };

    /**
     * Ensure that ID is unique
     */
    componentDidMount() {
        markdownUniqueId++;
        this.setState({id: markdownUniqueId});
    }

    render() {
        let {name, label, classes, helperText, ...rest_of_props} = this.props;

        return <ReduxField classes={classes}
                           intl={this.props.intl}
                           component={_renderMarkdownField}
                           label={label}
                           name={name}
                           helperText={helperText}
                           id={this.state.id}
                           {...rest_of_props} />;
    }
}

MarkdownField = withStyles(styles)(MarkdownField);
export default injectIntl(MarkdownField);
