'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
// Icons
import WarningIcon from '@material-ui/icons/Warning';


/**
 * Generic #404 page
 */
class NotFoundPage extends React.Component {
    render() {
        return <ErrorCard
            title={<FormattedMessage id='errors.notfound.title' />}
            text={<FormattedMessage id='errors.notfound.text' />}
            icon={<WarningIcon color='secondary' />} />;
    }
}

export default injectIntl(NotFoundPage);
