'use strict';
import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import {intlReducer} from 'react-intl-redux';
// Reducers
import api from './api';
import app from './app';
import auth from './auth';
import shared from './shared';
import tables from './tables';
import users from './users';
import company from './company';
import portlets_general from './portlets/general';
import portlets_calendar from './portlets/calendar';
import files from './files';


export default (history) => combineReducers({
    api,
    app,
    auth,
    shared,
    tables,
    users,
    company,
    portlets_general,
    portlets_calendar,
    files,
    form: formReducer,
    router: connectRouter(history),
    intl: intlReducer
});
