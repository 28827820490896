'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {change, formValueSelector} from 'redux-form';
// Actions
import {fetchTable} from 'actions/tables';
// Components
import Field from 'components/core/ui/Field';
import {List as VirtualizedList} from 'react-virtualized';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
    // fake option to get rid of warning 'value out of range' due to using react-virtualized
    fakeOption: {
        display: 'none !important'
    }
});

/**
 * Redux Field for Country. Fetches Country tables if required
 */
class CountryField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, // opened status of selection
            scrollTo: 0, // controls scroll to searched icon
            search: '', // stores keyboard search string
            searchTimer: null // timer to reset search
        };
    }

    /**
     * Ensure we have Countries fetched
     */
    componentDidMount() {
        // check if we have data loaded
        if (this.props.countries === null) {
            this.props.fetchTable('countries');
        }
    }

    /**
     * Renders menuItems in react-virtualized list
     *
     * @param virtualizedprops - props from react-vritualized
     */
    rowRenderer(virtualizedprops) {
        if (virtualizedprops.index === 0) {
            return <MenuItem
                key={virtualizedprops.key}
                style={virtualizedprops.style}
                onClick={() => {
                    // set's icon field
                    this.props.change(this.props.formName, this.props.name, null);
                    // close selection
                    this.setState({open: false});
                }}>
                <em><FormattedMessage id='filters.none' /></em>
            </MenuItem>;
        }
        let country = this.props.countries.get((virtualizedprops.index - 1));

        return <MenuItem
            key={virtualizedprops.key}
            style={virtualizedprops.style}
            selected={this.props.selectedCountryID === country.get('id')}
            onClick={() => {
                // set's country field
                this.props.change(this.props.formName, this.props.name, country.get('id'));
                // close selection
                this.setState({open: false});
            }}>
            {country.get('name')}
        </MenuItem>;
    }

    render() {
        // Split some stuff from props
        let {change, formName, intl, fetchTable, countries, selectedCountryID, classes, disabled, ...rest_of_props} = this.props;

        return <Field fieldType='Select'
                      disabled={countries === null || disabled}
                      onKeyPress={(e) => {
                          // get current search + new char from keyboard
                          let search = `${this.state.search}${String.fromCharCode(e.charCode).toLowerCase()}`;
                          // set it to store
                          this.setState({search: search});

                          // clear previous timer (to cancel adding keys to search word)
                          if (this.state.searchTimer) {
                              clearTimeout(this.state.searchTimer);
                          }
                          // set timer to clear adding keys to search word
                          let timer = setTimeout(() => {
                              this.setState({search: ''});
                          }, 500);
                          this.setState({searchTimer: timer});

                          // try to find country
                          let foundCountryIndex = countries.findIndex(el => el.get('name').toLowerCase().startsWith(search));
                          if (foundCountryIndex) {
                              this.setState({scrollTo: foundCountryIndex});
                          }
                      }}
                      open={this.state.open}
                      onOpen={() => this.setState({
                          open: true,
                          scrollTo: selectedCountryID ? countries.findIndex(el => el.get('id') === selectedCountryID) : 0
                      })}
                      onClose={() => this.setState({open: false})}
                      renderValue={country_id => countries === null || !countries.find(el => el.get('id') === country_id)
                          ? ''
                          : countries.find(el => el.get('id') === country_id).get('name')
                      }
                      {...rest_of_props}>
            {countries !== null && <VirtualizedList
                rowRenderer={this.rowRenderer.bind(this)}
                rowCount={countries.size + 1}
                style={{margin: '-8px 0', padding: '8px 0'}}
                rowHeight={36}
                width={320}
                height={320}
                scrollToAlignment='center'
                scrollToIndex={this.state.scrollTo}
            />}
            <MenuItem value={selectedCountryID} className={classes.fakeOption} />
        </Field>;
    }
}

CountryField = withStyles(styles)(CountryField);
CountryField = connect((state, props) => ({
    countries: state.tables.getIn(['tables', 'countries']),
    selectedCountryID: formValueSelector(props.formName)(state, props.name)
}), (dispatch) => bindActionCreators({
    fetchTable,
    change
}, dispatch))(CountryField);

export default injectIntl(CountryField);
