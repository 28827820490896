'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUITableHead from '@material-ui/core/TableHead';


const styles = theme => ({
    tableHead: {
        background: theme.palette.common.white
    }
});

/**
 * Basically MUI TableHead with additional style to support react-sticky-el
 */
class TableHead extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            root, className, classes.tableHead
        ].filter(Boolean).join(' ');

        return <MUITableHead
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />;
    }
}

TableHead = withStyles(styles)(TableHead);
export default TableHead;
