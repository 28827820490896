'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {materialIcons} from 'lib/iconslist';
import {change, formValueSelector} from 'redux-form';
// Components
import Field from 'components/core/ui/Field';
import {List as VirtualizedList} from 'react-virtualized';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import Icon from '@material-ui/core/Icon';


const styles = theme => ({
    // fake option to get rid of warning 'value out of range' due to using react-virtualized
    fakeOption: {
        display: 'none !important'
    }
});

/**
 * Redux Field for Icons (from material-ui/icons).
 */
class IconField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, // opened status of selection
            scrollTo: 0, // controls scroll to searched icon
            search: '', // stores keyboard search string
            searchTimer: null // timer to reset search
        };
    }

    /**
     * Renders menuItems in react-virtualized list
     *
     * @param virtualizedprops - props from react-vritualized
     */
    rowRenderer(virtualizedprops) {
        if (virtualizedprops.index === 0) {
            return <MenuItem
                key={virtualizedprops.key}
                style={virtualizedprops.style}
                onClick={() => {
                    // set's icon field
                    this.props.change(this.props.formName, this.props.name, null);
                    // close selection
                    this.setState({open: false});
                }}>
                <em><FormattedMessage id='filters.none' /></em>
            </MenuItem>;
        }
        let icon = materialIcons[(virtualizedprops.index - 1)];

        return <MenuItem
            key={virtualizedprops.key}
            style={virtualizedprops.style}
            selected={this.props.selectedIcon === icon}
            onClick={() => {
                // set's icon field
                this.props.change(this.props.formName, this.props.name, icon);
                // close selection
                this.setState({open: false});
            }}>
            <ListItemIcon className='icon'><Icon>{icon}</Icon></ListItemIcon>
            <ListItemText className='text'><span>{icon}</span></ListItemText>
        </MenuItem>;
    }

    render() {
        // Split some stuff from props
        let {change, formName, intl, selectedIcon, classes, ...rest_of_props} = this.props;
        // MenuProps={{PopoverClasses: {paper: classes.paper}}}
        return <Field fieldType='Select'
                      onKeyPress={(e) => {
                          // get current search + new char from keyboard
                          let search = `${this.state.search}${String.fromCharCode(e.charCode).toLowerCase()}`;
                          // set it to store
                          this.setState({search: search});

                          // clear previous timer (to cancel adding keys to search word)
                          if (this.state.searchTimer) {
                              clearTimeout(this.state.searchTimer);
                          }
                          // set timer to clear adding keys to search word
                          let timer = setTimeout(() => {
                              this.setState({search: ''});
                          }, 500);
                          this.setState({searchTimer: timer});

                          // try to find material-icon
                          let foundIconIndex = materialIcons.findIndex(el => el.toLowerCase().startsWith(search));
                          if (foundIconIndex) {
                              // mark that we should scroll to that index
                              this.setState({scrollTo: foundIconIndex});
                          }
                      }}
                      open={this.state.open}
                      onOpen={() => this.setState({
                          open: true,
                          scrollTo: selectedIcon ? materialIcons.findIndex(el => el === selectedIcon) : 0
                      })}
                      onClose={() => this.setState({open: false})}
                      renderValue={icon => !icon ? '' : <React.Fragment>
                          <ListItemIcon className='icon'><Icon>{icon}</Icon></ListItemIcon>
                          <ListItemText className='text'><span>{icon}</span></ListItemText>
                      </React.Fragment>}
                      helperText={<FormattedMessage id='iconfield.description'
                                                    values={{link: <a href='https://material.io/tools/icons/' target='_blank' className='hover-border'>
                                                        <FormattedMessage id='iconfield.description.link' />
                                                    </a>}} />}
                      {...rest_of_props}>
            <VirtualizedList
                rowRenderer={this.rowRenderer.bind(this)}
                rowCount={(materialIcons.length + 1)}
                style={{margin: '-8px 0', padding: '8px 0'}}
                rowHeight={36}
                width={320}
                height={320}
                scrollToAlignment='center'
                scrollToIndex={this.state.scrollTo}
            />
            <MenuItem value={selectedIcon} className={classes.fakeOption} />
        </Field>;
    }
}

IconField = withStyles(styles)(IconField);
IconField = connect((state, props) => ({
    selectedIcon: formValueSelector(props.formName)(state, props.name),
}), (dispatch) => bindActionCreators({
    change
}, dispatch))(IconField);

export default injectIntl(IconField);
