'use strict';
import validator from 'validator';
import Moment from 'moment';

/**
 * The wrapper of all validator functions (overwriting them)
 *
 * @param validatorFunction - validation function e.g. isEmpty()
 * @param functionName - name of given function
 * @param defaultMessages - localized errors
 */
let wrappedFunction = (validatorFunction, functionName, defaultMessages) => {
    return (errorMessage = null, errorDict, key, value, ...args) => {
        // Coerce to string, always...
        if (typeof (value) === 'undefined' || value === null) {
            value = '';
        } else {
            value = value + '';
        }

        // Allow custom validators to set their own errors. This is actually not really pretty, to be hones
        let validationMetrics = {_error: null};

        if (!validatorFunction(value, ...args, validationMetrics)) {
            // Default error values
            if (errorMessage === null) {
                if (validationMetrics._error !== null) {
                    errorMessage = validationMetrics._error;
                } else {
                    if (functionName in defaultMessages) {
                        errorMessage = defaultMessages[functionName];
                    } else {
                        errorMessage = defaultMessages.__generic__;
                    }
                }
            }

            // Keys may be nested, so, figure that out
            let path = key.split('.');
            for (var i = 0, current = errorDict; i < path.length; i++) {
                if (!current.hasOwnProperty(path[i])) {
                    current[path[i]] = '';
                    break;
                }

                // Is it nested key?
                if (typeof current[path[i]] === 'object') {
                    current = current[path[i]];
                } else {
                    break;
                }
            }

            current[path[i]] = errorMessage;
            return false;
        }
        return true;
    };
};

// The validator object to use the wrapped API
let reduxFormValidator = {};

/**
 * Initalize validator for Form validations. Also adding custom functions.
 *
 * @param initializingValidator - redux validator
 */
export function initValidator(initializingValidator) {
    let messages = initializingValidator.defaultMessages;

    // Wrap all validator functions to work more nicely with redux-form
    for (let validatorFunction in validator) {
        if (!validator.hasOwnProperty(validatorFunction)) {
            continue;
        }

        // Copy the interface
        if (!validatorFunction.startsWith('is') && validatorFunction !== 'equals' && validatorFunction !== 'contains') {
            initializingValidator[validator] = validator[validatorFunction];
            continue;
        }

        // Use the wrapper for the validator
        initializingValidator[validatorFunction] = wrappedFunction(validator[validatorFunction], validatorFunction, messages);
    }

    /**
     * Custom validators
     */
    // Inversion of isEmpty
    initializingValidator.isNotNull = wrappedFunction((value) => {
        return !validator.isEmpty(value);
    }, 'isNotNull', messages);

    // Inversion of equals
    initializingValidator.equalsNot = wrappedFunction((value, comparision) => {
        return !validator.equals(value, comparision);
    }, 'equalsNot', messages);

    // Required boolean
    initializingValidator.isTrue = wrappedFunction((value) => {
        return validator.toBoolean(value) === true;
    }, null, messages);

    // isSlug
    initializingValidator.isSlug = wrappedFunction((value) => {
        return /^[-a-zA-Z0-9_]+$/.test(value);
    }, 'isSlug', messages);

    // isUnique
    initializingValidator.isUnique = wrappedFunction((value, items, identifier) => {
        return !items ? true : !items.find(el => identifier ? (el[identifier] || el.get(identifier)) === value : el === value);
    }, null, messages);

    // includes
    initializingValidator.includes = wrappedFunction((value, list) => {
        return list.includes(value);
    }, null, messages);

    // is valid date time from <DateField />
    initializingValidator.isDateTime = wrappedFunction((value) => {
        return Moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid();
    }, 'isDateTime', messages);

    // isURL applicable to lists, not only single value
    initializingValidator.isURLList = wrappedFunction((value, options, validationMetrics) => {
        // Split to list by newline...
        let urls = value.split('\n');

        // Figure out actual number of the domains, by stripping whitespaced rows
        let actualURLs = [];
        for (let d of urls) {
            // Strip whitespaces
            if (!d.replace(/ /g, '').length) {
                continue;
            }

            actualURLs.push(d);
        }

        // Set default errors
        validationMetrics._error = actualURLs.length === 1 ? messages.isURLList[0] : messages.isURLList[1];

        for (let url of actualURLs) {
            if (!validator.isURL(url, options)) {
                return false;
            }
        }

        return true;
    }, 'isURLList', messages);

    // isEmail applicable to lists, not only single value
    initializingValidator.isEmailList = wrappedFunction((value, options) => {
        // Split to list by newline...
        let emails = value.split(',');

        // Figure out actual number of the emails, by stripping whitespaced rows
        let actualEmails = [];
        for (let d of emails) {
            actualEmails.push(d.trim());
        }

        for (let url of actualEmails) {
            if (!validator.isEmail(url, options)) {
                return false;
            }
        }

        return true;
    }, 'isEmailList', messages);
}

export default reduxFormValidator;
