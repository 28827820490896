'use strict';

/**
 * Make breaks in string.
 *
 * so:
 *  oneline\nsecondline
 * will be:
 *  oneline
 *  secondline
 *
 * @param str - sentence
 * @returns {string} - HTML result
 */
export function breakLine(str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

/**
 * Parse links for Paginator from API response headers
 *
 * @param link_header - API response header 'link'
 */
export function parseLinkHeader(link_header) {
    // Split parts by comma
    let parts = link_header.split(',');
    let links = {};
    // Parse each part into a named link
    parts.map((part) => {
        let section = part.split(';');
        let url = section[0].replace(/<(.*)>/, '$1').trim();
        let name = section[1].replace(/rel="(.*)"/, '$1').trim();
        links[name] = url;
    });
    return links;
}
