'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        // box-like
        color: theme.palette.common.white,
        background: theme.palette.primary[500],
        borderRadius: theme.shape.borderRadius,
        // if we have icon and text, next to each other
        '& svg, & span': {
            display: 'inline-flex',
            verticalAlign: 'middle'
        },
        '& svg': {
            fontSize: theme.typography.fontSize,
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            // space between
            marginRight: `${theme.spacing(0.5)}px`
        },
        // theming
        '&.color-yellow': {
            background: theme.palette.secondary[500]
        },
        '&.color-green': {
            background: theme.palette.success[500]
        },
        '&.color-red': {
            background: theme.palette.danger[500]
        },
        '&.color-orange': {
            background: theme.palette.orange[500]
        },
        '&.color-transparent': {
            background: 'transparent',
            color: theme.palette.text['primary'],
            '&:first-child': {
                paddingLeft: '0'
            }
        }
    }
});

/**
 * Nice looking 'Tag' to post, e.g. "#hashtag", "user position in company" etc.
 */
class Tag extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, className, color, ...rest_of_props} = this.props;
        // Merge props from props and our custom
        let rootClasses = [classes.root, `color-${color}`, className].filter(Boolean).join(' ');

        return <div className={rootClasses} {...rest_of_props} />;
    }
}

Tag = withStyles(styles)(Tag);
export default Tag;
