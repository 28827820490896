'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {altTheme} from 'theme/index';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// Icons
import CancelIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Close';


/**
 * DeleteDialog to make sure User doesn't accidentally delete anything.
 */
class DeleteDialog extends React.Component {
    render() {
        return <Dialog open={this.props.open} onClose={this.props.handleClose}>
            <DialogTitle>
                <FormattedMessage id={this.props.multiple ? 'deletedialog.multiple.title' : 'deletedialog.title'}
                                  values={{
                                      item: this.props.item || this.props.intl.formatMessage({id: 'deletedialog.item'}),
                                      items: this.props.items || this.props.intl.formatMessage({id: 'deletedialog.multiple.items'}),
                                  }} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id={this.props.multiple ? 'deletedialog.multiple.description' : 'deletedialog.description'}
                                      values={{
                                          item: this.props.item || this.props.intl.formatMessage({id: 'deletedialog.item'}),
                                          items: this.props.items || this.props.intl.formatMessage({id: 'deletedialog.multiple.items'}),
                                      }} />
                </DialogContentText>
                <DialogContentText>
                    <FormattedMessage id='deletedialog.description2' />
                </DialogContentText>
            </DialogContent>
            <MuiThemeProvider theme={altTheme}>
                <DialogActions>
                    <Button disabled={this.props.state !== null}
                            onClick={() => this.props.state === null ? this.props.handleClose() : {}}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    <Button variant='contained' color='secondary'
                            disabled={this.props.state !== null}
                            onClick={() => this.props.state === null ? this.props.handleConfirm() : {}}>
                        <DeleteIcon />
                        <FormattedMessage id='actions.delete' />
                    </Button>
                </DialogActions>
            </MuiThemeProvider>
        </Dialog>;
    }
}

DeleteDialog = connect((state) => ({
    state: state.app.get('state')
}))(DeleteDialog);

export default injectIntl(DeleteDialog);
