'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
import {setPortletState} from 'actions/portlets/general';
// Components
import {getSocialIcon} from 'components/core/ui/fields/SocialField';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({
    // share buttons holder
    socialCardContent: {
        paddingBottom: `${theme.spacing(1)}px !important`,
        '& button': {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        }
    }
});

/**
 * Renders social icons with link to social network of company
 */
class Social extends React.Component {
    /**
     * During initialization fetch Social links
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready' || this.props.loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'related_data_set']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        }
    }

    render() {
        return <React.Fragment>
            {this.props.portletState === 'fetching'
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'error'
                ? <CardContent>
                <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
            </CardContent>
                : this.props.portletState === 'ready'
                ? <CardContent className={this.props.classes.socialCardContent}>
                {this.this.props.items.map((social, idx) =>
                    <Tooltip key={idx} title={<FormattedMessage id={`portlets.social.network.${social.get('icon')}`} />}>
                        <IconButton href={social.get('url')} target='_blank'>{getSocialIcon(social.get('icon'))}</IconButton>
                    </Tooltip>
                )}
            </CardContent>
                : <div />}
        </React.Fragment>;
    }
}

Social = withStyles(styles)(Social);
Social = connect((state, props) => ({
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]),
    items: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList()
}), (dispatch) => bindActionCreators({
    setPortletState,
    fetchItems
}, dispatch))(Social);

export default injectIntl(Social);
