'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Moment from 'moment';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
import {setPortletState} from 'actions/portlets/general';
import {fetchCalendars, setSelectedDate} from 'actions/portlets/calendar';
// Components
import {default as ReactCalendar} from 'react-calendar';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import TodayIcon from '@material-ui/icons/Timelapse';
import InfoIcon from '@material-ui/icons/Info';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/Event';
import LocationIcon from '@material-ui/icons/LocationOn';
import CreatorIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/EventNote';
import LinkIcon from '@material-ui/icons/Link';


const styles = theme => ({
    // holder of whole react-calendar with loader
    calendarHolder: {
        position: 'relative'
    },
    // loader when calendar is fetching new data
    calendarLoader: {
        position: 'absolute',
        // next to borders
        left: '1px',
        right: '1px',
        // bellow title
        top: '45px',
        // limit size
        width: '350px',
        maxWidth: '100%',
        // center
        margin: '0 auto'
    },
    // modify style of react-calendar
    calendar: {
        // center
        margin: '0 auto',
        // change border to fit material
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        // we need to style navigation here
        '& .react-calendar__navigation': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& .react-calendar__navigation__label:disabled': {
            background: theme.palette.primary[500],
            color: theme.palette.common.white,
            pointerEvents: 'none' // allow right click even though element is disabled
        },
        '& .react-calendar__navigation__arrow': {
            color: theme.palette.text['primary'],
            // get rid of 'focus' style
            '&, &:enabled:focus': {
                background: theme.palette.grey[100]
            },
            // hover effect in our grey
            '&:enabled:hover': {
                background: theme.palette.grey[300]
            }
        }
    },
    // information about calendars name
    calendarsName: {
        margin: `${theme.spacing(1)}px 0`,
        color: theme.palette.grey[500],
        // text
        '& span': {
            fontStyle: 'italic'
        },
        // calendar name
        '& div': {
            whiteSpace: 'nowrap',
            fontWeight: 'bold'
        }
    },
    // default style for calendarTile
    calendarTile: {
        // make black text same black, as we use. Also overrides 'red' weekends
        color: theme.palette.text['primary'],
        // get rid of 'focus' style
        '&:enabled:focus': {
            background: 'transparent'
        },
        // hover effect in our grey
        '&:enabled:hover': {
            background: theme.palette.grey[300]
        },
        // selected calendar title
        '&.react-calendar__tile--active': {
            // change active color to our palette
            '&, &:enabled:hover, &:enabled:focus': {
                background: theme.palette.success[500],
                color: theme.palette.common.white
            }
        },
        // out of month days
        '&.react-calendar__month-view__days__day--neighboringMonth': {
            color: theme.palette.grey[500],
        }
    },
    // highlight calendarTile with Events
    calendarTileWithEvent: {
        // highlight
        fontWeight: 'bold',
        color: theme.palette.success[500]
    },
    // highlight selectedDate
    upcomingDate: {
        fontWeight: 'bold',
        borderBottom: `1px solid ${theme.palette.text['primary']}`,
        // no wrap on mobile
        whiteSpace: 'nowrap'
    },
    // no events
    empty: {
        // hide by default
        display: 'none',
        '&:last-child': {
            display: 'block'
        }
    },
    // event itself
    event: {
        position: 'relative',
        // proportion
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`, // bottom margin handled in eventInfo and eventButtonHolder
        marginBottom: `${theme.spacing(2)}px`,
        // style
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
    },
    // holder of headline
    eventTitleHolder: {
        // proportion
        margin: `-${theme.spacing(2)}px -${theme.spacing(2)}px ${theme.spacing(2)}px`, // to side
        padding: `${theme.spacing(2)}px`,
        // style
        background: theme.palette.primary[500],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        // title and info next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    eventTitle: {
        color: theme.palette.common.white
    },
    // info icon
    eventTitleIcon: {
        margin: '0 12px', // match width of iconbutton
        // style
        color: theme.palette.grey[100]
    },
    // info tooltip inside eventTitle
    eventTitleTooltip: {
        display: 'inline-block',
        // icon and text next to each othe
        '& svg, & span': {
            display: 'inline-flex',
            verticalAlign: 'middle'
        },
        '& svg': {
            // space between
            marginRight: `${theme.spacing(1)}px`
        }
    },
    // holder of ALL event information
    eventsInfoHolder: {
        [theme.breakpoints.up('sm')]: {
            // limit-size
            width: `${600 + 48 + theme.spacing(1)}px`,
            paddingRight: `${48 + theme.spacing(1)}`,
            maxWidth: '100%'
        }
    },
    // holder of event information
    eventInfoHolder: {
        display: 'inline-block',
        verticalAlign: 'top',
        position: 'relative',
        // proportion
        marginBottom: `${theme.spacing(2)}px`,
        width: '300px',
        maxWidth: '100%',
        // double variant for description
        '&.double': {
            width: '600px'
        }
    },
    // event information, like time, location, etc.
    eventInfo: {
        display: 'inline-block', // no 100% width block for tooltip
        maxWidth: '100%',
        // proportion
        paddingLeft: `${24 + theme.spacing(1)}px`, // icon space + space between icon and text
        // icon
        '& svg': {
            position: 'absolute',
            left: '0',
            top: '0'
        },
        // text
        '& div': {
            marginTop: '4.5px', // manual v-align
        },
        // marks that text should stay on one line
        '&.ellipsis': {
            // text
            '& div': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }
    },
    eventButtonHolder: {
        marginBottom: `${theme.spacing(1)}px`,
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: `${theme.spacing(2)}px`,
            bottom: '0',
            marginBottom: `${theme.spacing(0.25)}px`,
        }
    }
});

/**
 * Renders Calendar (integration of google calendar) with Events
 */
class Calendar extends React.Component {
    /**
     * During initialization fetch related data
     */
    componentDidMount() {
        // do we have to fetch related data
        if (this.props.portletState !== 'ready' || this.props.calendars_loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'related_data_set']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
                this.fetchCalendars();
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        } else {
            this.fetchCalendars();
        }
    }

    /**
     * Fetch google calendar events
     */
    fetchCalendars() {
        this.props.fetchCalendars(this.props.portlet, this.props.calendars).then(() => {
            // set selectedDate after that (so we have loader until then)
            this.props.setSelectedDate(this.props.portlet, new Date());
        });
    }

    render() {
        return <React.Fragment>
            {!this.props.selectedDate
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'error'
                ? <CardContent>
                <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
            </CardContent>
                : <CardContent>
                <Row wrap>
                    <Col width='366px'>
                        <div className={this.props.classes.calendarHolder}>
                        {this.props.portletState === 'fetching' &&
                            <LinearProgress className={this.props.classes.calendarLoader} />
                        }
                            <ReactCalendar
                                value={this.props.selectedDate}
                                onChange={date => {
                                    let start_of_month = Moment(date).startOf('month').format();
                                    let selected_start_of_month = Moment(this.props.selectedDate).startOf('month').format();
                                    // check if month changes, so fetch new data
                                    if (start_of_month !== selected_start_of_month) {
                                        this.props.fetchCalendars(this.props.portlet, this.props.calendars, Moment(date));
                                    }
                                    this.props.setSelectedDate(this.props.portlet, date);
                                }}
                                onActiveDateChange={({activeStartDate, view}) => {
                                    // month changes, fetch new data
                                    this.props.fetchCalendars(this.props.portlet, this.props.calendars, Moment(activeStartDate));
                                }}
                                locale={Moment.locale()}
                                className={this.props.classes.calendar}
                                minDetail='month'
                                tileClassName={({date, view}) => {
                                    // default class
                                    let classes = this.props.classes.calendarTile;
                                    // get events
                                    let start_of_month = Moment(date).startOf('month').format();
                                    let events = this.props.events.get(start_of_month);
                                    // check if we have event in this day
                                    if (events && events.find(event => Moment(event.getIn(['start', 'dateTime']) || event.getIn(['start', 'date'])).date() === date.getDate())) {
                                        classes = `${classes} ${this.props.classes.calendarTileWithEvent}`;
                                    }
                                    return classes;
                                }}
                            />
                        </div>
                        <Row hCenter>
                            <Col>
                                <div className={this.props.classes.calendarsName}>
                                    <FormattedMessage id={this.props.processedCalendars.length >= 1 ? 'portlets.calendar.calendars' : 'portlets.calendar.calendar'} />
                                    {this.props.processedCalendars.map((calendar, idx) =>
                                        <div key={idx} style={calendar.color ? {color: calendar.color} : {}}>
                                            {calendar.name}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <div className='text-right'>
                                    <Button variant='outlined' onClick={() => this.props.setSelectedDate(this.props.portlet, new Date())}>
                                        <TodayIcon />
                                        <FormattedMessage id='portlets.calendar.today' />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <SpaceDivider double />
                    </Col>
                    <Col>
                        <Typography variant='h5'>
                            <FormattedMessage id='portlets.calendar.events.upcoming'
                                              values={{date: <span className={this.props.classes.upcomingDate}>{Moment(this.props.selectedDate).format('LL')}</span>}} />
                        </Typography>
                        <SpaceDivider />
                        <div className={this.props.classes.empty}>
                            <Typography variant='h6'><FormattedMessage id='portlets.calendar.events.empty' /></Typography>
                        </div>
                        {this.props.events.get(Moment(this.props.selectedDate).startOf('month').format()) && this.props.events.get(Moment(this.props.selectedDate).startOf('month').format()).filter(event => Moment(event.getIn(['start', 'dateTime']) || event.getIn(['start', 'date'])).date() >= Moment(this.props.selectedDate).date()).slice(0, 5).map((event, idx) =>
                            <div key={idx} className={this.props.classes.event}>
                                <div className={this.props.classes.eventTitleHolder}
                                     style={{backgroundColor: this.props.calendars.find(el => el.get('calendar_key') === event.getIn(['organizer', 'email'])).get('color')}}>
                                    <Typography variant='h6' className={this.props.classes.eventTitle}>{event.get('summary')}</Typography>
                                    <Tooltip title={<div className={this.props.classes.eventTitleTooltip}>
                                        <CalendarIcon /><span>{event.getIn(['organizer', 'displayName'])}</span><br />
                                        <CreatorIcon /><span>{event.getIn(['creator', 'email'])}</span>
                                    </div>}>
                                        <InfoIcon className={this.props.classes.eventTitleIcon} />
                                    </Tooltip>
                                </div>
                                <div className={this.props.classes.eventsInfoHolder}>
                                    <div className={this.props.classes.eventInfoHolder}>
                                        <Tooltip title={<FormattedMessage id='portlets.calendar.events.detail.time' />}>
                                            <div className={this.props.classes.eventInfo}>
                                                <TimeIcon /><div>{event.getIn(['start', 'dateTime'])
                                                ? Moment(event.getIn(['start', 'dateTime'])).format('LT, LL')
                                                : Moment(event.getIn(['start', 'date'])).format('LL')}
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className={this.props.classes.eventInfoHolder}>
                                        {event.get('location') &&
                                            <Tooltip title={<FormattedMessage id='portlets.calendar.events.detail.location' />}>
                                                <div className={this.props.classes.eventInfo}>
                                                    <LocationIcon /><div>{event.get('location')}</div>
                                                </div>
                                            </Tooltip>
                                        }
                                    </div>
                                    {event.get('description') &&
                                        <div className={`${this.props.classes.eventInfoHolder} double`}>
                                            <Tooltip title={<FormattedMessage id='portlets.calendar.events.detail.description' />}>
                                                <div className={this.props.classes.eventInfo}>
                                                    <DescriptionIcon /><div>{event.get('description')}</div>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                                <div className={`${this.props.classes.eventButtonHolder} text-right`}>
                                    <Tooltip title={<FormattedMessage id='portlets.calendar.events.detail.link' />}>
                                        <a href={event.get('htmlLink')} target='_blank'>
                                            <IconButton>
                                                <LinkIcon />
                                            </IconButton>
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </CardContent>}
        </React.Fragment>;
    }
}

Calendar = withStyles(styles)(Calendar);
Calendar = connect((state, props) => ({
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    calendars_loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList(),
    calendars: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList(),
    processedCalendars: state.portlets_calendar.getIn(['calendar_data', props.portlet.get('url'), 'calendars']) || [],
    events: state.portlets_calendar.getIn(['calendar_data', props.portlet.get('url'), 'events']),
    selectedDate: state.portlets_calendar.getIn(['calendar_data', props.portlet.get('url'), 'selectedDate'])
}), (dispatch) => bindActionCreators({
    setPortletState,
    fetchItems,
    fetchCalendars,
    setSelectedDate
}, dispatch))(Calendar);

export default injectIntl(Calendar);
