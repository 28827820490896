'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Field as ReduxField} from 'redux-form';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
// Components
import {TextField, Select, Checkbox, Switch} from 'redux-form-material-ui/es/index';
import ColorField from 'components/core/ui/fields/ColorField';
import CompanyTypeField from 'components/core/ui/fields/CompanyTypeField';
import CountryField from 'components/core/ui/fields/CountryField';
import IconField from 'components/core/ui/fields/IconField';
import MarkdownField from 'components/core/ui/fields/MarkdownField';
import PortletField from 'components/core/ui/fields/PortletField';
import SocialField from 'components/core/ui/fields/SocialField';
import IconButtonSelect from 'components/core/ui/fields/IconButtonSelect';
// material-ui
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MUITextField from '@material-ui/core/TextField';
import MUISwitch from '@material-ui/core/Switch';
import MUISelect from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';


const styles = theme => ({
    formControl: {
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        width: '320px',
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10',
        whiteSpace: 'normal',
        // field itself
        '& input, & textarea': {
            letterSpacing: 'normal',
            lineHeight: theme.typography.body2.lineHeight
        },
        // highlighted input
        '&.highlight': {
            '& label': {
                color: theme.palette.secondary[500]
            },
            '& > div:before, & > div:after': {
                borderColor: `${theme.palette.secondary[500]} !important`
            }
        },
        // icon after the text and before the text
        '&.icon-after': {
            '& input, & textarea': {
                paddingRight: `${theme.spacing(1) + 48}px`
            },
            '& $selectSelectMenu': {
                paddingRight: `${theme.spacing(1) + theme.spacing(3) + 48}px`
            },
            '& $selectSelectMenu ~ svg': {
                right: `${theme.spacing(1) + 48}px` // select arrow
            }
        },
        '&.icon-before': {
            '& label': {
                marginLeft: `${theme.spacing(1) + 48}px`
            },
            '& input, & textarea, & $selectSelectMenu': {
                paddingLeft: `${theme.spacing(1) + 48}px`
            }
        },
        // specific for field types
        '&.textarea': {
            width: `${(320 * 2) + (theme.spacing(2))}px` // double the size
        },
        // dynamic size for checkboxes and switches
        '&.dynamic': {
            width: 'auto',
            maxWidth: '320px',
        },
        // half size
        '&.half': {
            width: `${(320 / 2) - (theme.spacing(1))}px`
        },
        // full size
        '&.full': {
            width: '100%'
        },
        // highlight values in disabled fields
        '& input:disabled': {
            color: theme.palette.text['primary']
        }
    },
    inFormContent: {
        width: '100%',
        margin: `0 ${theme.spacing(1)}px`
    },
    disabledCheckbox: {
        // highlight disabled checkbox
        color: `${theme.palette.text['primary']} !important`
    },
    disabledSelect: {
        // highlight disabled select
        color: `${theme.palette.text['primary']} !important`
    },
    selectSelectMenu: {
        // fix size when empty
        minHeight: '1.1875em'
    },
    // support for ListItemText and ListItemIcon with 'icon' and 'text' classes
    selectListItemSupport: {
        '& .icon, & .text': {
            display: 'inline-flex',
            verticalAlign: 'middle',
        },
        // correct proportion of icon
        '& .icon': {
            minWidth: '0', // get rid of not necessary min-width
            marginLeft: `${theme.spacing(1)}px`, // space between icon and wall
            marginRight: `${theme.spacing(2)}px` // space between icon and text
        },
        '& .icon > svg, & .icon > span': {
            margin: '-2.5px 0' // from 24px -> 19px
        },
        // correct proportion of text
        '& .text': {
            margin: '0',
            '& > span': {
                lineHeight: '1.1875em',
            }
        }
    },
    selectMenuPaper: {
        // limit size to display nicely large choices
        maxWidth: '320px',
        // menu items
        '& li': {
            display: 'block',
            minHeight: 'auto',
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // correct proportion of icon
            '& .icon': {
                marginLeft: '0' // get rid of space between icon and wall
            }
        }
    },
    checkboxHelperText: {
        marginTop: '-8px' // move closer to checkbox
    },
    fieldIcon: {
        display: 'inline-flex',
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px -${theme.spacing(1) + 48}px`,
        position: 'relative',
        zIndex: '20',
        height: '48px', // fixed height
        // icon Before field and not After
        '&.before': {
            margin: `${theme.spacing(1)}px -${theme.spacing(1) + 48}px ${theme.spacing(1)}px ${theme.spacing(1)}px`
        },
        // normal color even for disabled variant
        '&.disabled': {
            color: theme.palette.text.secondary
        }
    },
    fieldWithIconHolder: {
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        maxWidth: '100%',
        '&.full': {
            width: '100%'
        }
    }
});

/**
 * DateTimePicker field, use in redux-form Field as component (component={_renderDateTimeField})
 *
 * @param field - reduxform field
 * @private
 */
const _renderDateTimeField = (field) =>
    <FormControl fullWidth={field.fullWidth ? true : undefined}
                 error={field.meta.touched && field.meta.error ? true : undefined}
                 className={field.className}>
        <div id={`datetimefieldref-${field.input.name}`} style={{height: '58px', position: 'absolute', left: '0', right: '0', top: '0'}} />
        {field.label && <InputLabel>{field.label}</InputLabel>}
        <input {...field.input} type='hidden' />
        <DatePicker
            onChange={(value) => {
                if (Moment.isMoment(value)) {
                    value = value.format('YYYY-MM-DDTHH:mm:ssZ');
                }
                return field.input.onChange(value);
            }}
            onChangeRaw={event => {
                let value = event.target.value;
                if (Moment(value, 'YYYY-MM-DD HH:mm', true).isValid()) {
                    value = Moment(value).format('YYYY-MM-DDTHH:mm:ssZ');
                }
                return field.input.onChange(value);
            }}
            selected={field.input.value && Moment(field.input.value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ? Moment(field.input.value) : null}
            showTimeSelect
            timeFormat='HH:mm'
            dateFormat='YYYY-MM-DD HH:mm'
            timeIntervals={10}
            fixedHeight
            disabledKeyboardNavigation
            calendarContainer={props => <Popper
                style={{zIndex: 1600}}
                open={true}
                anchorEl={document.getElementById(`datetimefieldref-${field.input.name}`)}
                placement='bottom-start'
                modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}, hide: {enabled: false}}}>
                <div className={props.className}>
                    {props.children}
                </div>
                <div className='react-datepicker-popper' data-placement='bottom-start' style={{marginTop: 0}}>
                    <div className='react-datepicker__triangle' />
                </div>
            </Popper>}
            customInput={
                <Input fullWidth={field.fullWidth ? true : undefined}
                       style={field.label ? {marginTop: '16px'} : {}} />
            }
        />
        {field.meta.touched && field.meta.error
            ? <FormHelperText>{field.meta.error}</FormHelperText>
            : field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
    </FormControl>;

/**
 * Adds missing HelperText and error handling to Checkbox
 *
 * @private
 */
const _renderCheckbox = ({input, label, classes, meta: {touched, error}, fullWidth, className, _classes, name, helperText, ...rest_of_props}) => {
    return <FormControl fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <FormControlLabel label={label} control={
            <Checkbox name={name}
                      classes={{disabled: classes.disabledCheckbox, ..._classes}}
                      {...rest_of_props}
                      input={input}
            />
        } />
        {touched && error
            ? <FormHelperText className={classes.checkboxHelperText}>{error}</FormHelperText>
            : helperText && <FormHelperText className={classes.checkboxHelperText}>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Adds missing HelperText and error handling to Switch
 *
 * @private
 */
const _renderSwitch = ({input, label, classes, meta: {touched, error}, fullWidth, className, name, helperText, ...rest_of_props}) => {
    return <FormControl fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <FormControlLabel label={label} control={
            <Switch name={name}
                    classes={{...classes}}
                    {...rest_of_props}
                    input={input}
            />
        } />
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Adds missing HelperText and error handling to Select
 *
 * @private
 */
const _renderSelect = ({input, label, classes, meta: {touched, error}, children, fullWidth, className, name, _classes, helperText, ...rest_of_props}) => {
    return <FormControl fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select name={name}
                classes={{selectMenu: `${classes.selectSelectMenu} ${classes.selectListItemSupport}`, disabled: classes.disabledSelect, ..._classes}}
                MenuProps={{classes: {paper: `${classes.selectMenuPaper} ${classes.selectListItemSupport}`}}}
                {...rest_of_props}
                input={input}>
            {children}
        </Select>
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Add Icon to field, use it like this:
 * (It's a alternative way instead of start and end Adornment)
 *
 * <FieldWithIconHolder>
 *  <Field withIcon ... />
 *  <FieldIcon><Icon /></FieldIcon>
 * </FieldWithIconHolder>
 *
 * or
 *
 * <FieldWithIconHolder>
 *  <FieldIcon before><Icon /></FieldIcon>
 *  <Field withIconBefore ... />
 * </FieldWithIconHolder>
 */
class FieldIcon extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, before, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            classes.fieldIcon,
            root,
            className,
            before ? 'before' : '',
            rest_of_props.disabled ? 'disabled' : ''
        ].filter(Boolean).join(' ');

        return <IconButton
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        />;
    }
}

/**
 * Simple holder of Field with Icon to prevent wrap
 *
 * <FieldWithIconHolder>
 *  <Field withIcon ... />
 *  <FieldIcon><Icon /></FieldIcon>
 * <FieldWithIconHolder>
 */
class FieldWithIconHolder extends React.Component {
    render() {
        // split some stuff from props
        let {classes, className, size, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let rootClasses = [
            classes.fieldWithIconHolder,
            className,
            size === 'full' ? 'full' : ''
        ].filter(Boolean).join(' ');

        return <div className={rootClasses} {...rest_of_props} />;
    }
}

/**
 * To negate margin of Form to normal wrap content in this Component
 *
 * <Form onSubmit={this.props.handleSubmit}>
 *  <InFormContent>
 *     <Typography variant='body1'><FormattedMessage id='INTL_ID' /></Typography>
 *  </InFormContent>
 * </Form>
 */
class InFormContent extends React.Component {
    render() {
        // split some stuff from props
        let {classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let rootClasses = [
            classes.inFormContent,
            className
        ].filter(Boolean).join(' ');

        return <div className={rootClasses} {...rest_of_props} />;
    }
}

/**
 * Handles all Fields, no matter if used as switcher or in redux-form
 *
 * TextField
 *  <Field name='NAME' fieldType='TextField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * TextField (password)
 *  import Visibility from '@material-ui/icons/Visibility';
 *  import VisibilityOff from '@material-ui/icons/VisibilityOff';
 *
 *  <FieldWithIconHolder>
 *      <Field withIcon name='NAME' type={this.state.showPassword ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='INTL_ID' />} />
 *      <FieldIcon onClick={() => this.setState({showPassword: !this.state.showPassword})}>
 *          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
 *      </FieldIcon>
 *  </FieldWithIconHolder>
 *
 * URLField
 *  <Field name='NAME' fieldType='URLField' label={<FormattedMessage id='INTL_ID' />}
 *         change={this.props.change} selectedValue={this.props.selectedNAME} />
 *
 * NoReduxTextField
 *  <Field fieldType='NoReduxTextField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * DateField
 *  <Field name='NAME' fieldType='DateField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * TextArea
 *  <Field name='NAME' fieldType='TextArea' label={<FormattedMessage id='INTL_ID' />} />
 *
 * Checkbox
 *  <Field name='NAME' fieldType='Checkbox' label={<FormattedMessage id='INTL_ID' />} />
 *
 * Switch
 *  <Field name='NAME' fieldType='Switch' label={<FormattedMessage id='INTL_ID' />} />
 *
 * NoReduxSwitch
 *  <Field fieldType='NoReduxSwitch' label={<FormattedMessage id='INTL_ID' />}
 *         checked={this.state.checked} onChange{() => this.setState(checked: !this.state.checked)} />
 *
 * Select
 *  import MenuItem from '@material-ui/core/MenuItem';
 *
 *  <Field name='NAME' fieldType='Select' label={<FormattedMessage id='INTL_ID' />} >
 *      <MenuItem value='ITEM_VALUE'>READABLE_VALUE</MenuItem>
 *  </Field>
 *
 * NoReduxSelect
 *  import MenuItem from '@material-ui/core/MenuItem';
 *
 *  <Field fieldType='NoReduxSelect' label={<FormattedMessage id='INTL_ID' />} >
 *      <MenuItem value='ITEM_VALUE'>READABLE_VALUE</MenuItem>
 *  </Field>
 */
class Field extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, fieldType, label, name, size, highlight, withIcon, withIconBefore, helperText, rows, rowsMax, change, selectedValue, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let formControlClasses = [
            classes.formControl,
            className,
            fieldType ? fieldType.toLowerCase() : '',
            size === 'full' ? 'full' : size === 'half' ? 'half' : '',
            withIcon ? 'icon-after' : withIconBefore ? 'icon-before' : '',
            highlight ? 'highlight' : ''
        ].filter(Boolean).join(' ');

        switch (fieldType) {
            case 'TextField':
                return <ReduxField fullWidth
                                   className={formControlClasses}
                                   classes={_classes}
                                   component={TextField}
                                   label={label}
                                   name={name}
                                   helperText={helperText}
                                   {...rest_of_props} />;
            case 'URLField':
                return <ReduxField fullWidth
                                   className={formControlClasses}
                                   classes={_classes}
                                   component={TextField}
                                   label={label}
                                   name={name}
                                   type='url'
                                   onFocus={() => !selectedValue ? change(name, 'https://') : {}}
                                   onBlur={(e) => { if (selectedValue === 'https://') { change(name, ''); e.preventDefault(); } }}
                                   helperText={helperText}
                                   {...rest_of_props} />;
            case 'NoReduxTextField':
                return <MUITextField fullWidth
                                     className={formControlClasses}
                                     classes={_classes}
                                     label={label}
                                     helperText={helperText}
                                     {...rest_of_props} />;
            case 'DateField':
                return <ReduxField fullWidth
                                   className={formControlClasses}
                                   classes={_classes}
                                   component={_renderDateTimeField}
                                   label={label}
                                   name={name}
                                   helperText={helperText}
                                   {...rest_of_props} />;
            case 'TextArea':
                return <ReduxField fullWidth
                                   className={formControlClasses}
                                   classes={_classes}
                                   component={TextField}
                                   label={label}
                                   name={name}
                                   multiline rows={rows || 2} rowsMax={rowsMax || 15}
                                   helperText={helperText}
                                   {...rest_of_props} />;
            case 'Checkbox':
                return <ReduxField fullWidth
                                   className={`${formControlClasses}${size ? '' : ' dynamic'}`}
                                   classes={classes}
                                   _classes={_classes}
                                   label={label}
                                   component={_renderCheckbox}
                                   name={name}
                                   helperText={helperText}
                                   normalize={(value) => !!value}
                                   {...rest_of_props} />;
            case 'Switch':
                return <ReduxField fullWidth
                                   className={`${formControlClasses}${size ? '' : ' dynamic'}`}
                                   classes={_classes}
                                   label={label}
                                   component={_renderSwitch}
                                   name={name}
                                   helperText={helperText}
                                   normalize={(value) => !!value}
                                   {...rest_of_props} />;
            case 'NoReduxSwitch':
                return <FormControl fullWidth
                                    className={`${formControlClasses}${size ? '' : ' dynamic'}`}>
                    <FormControlLabel label={label} control={
                        <MUISwitch classes={_classes}
                                   {...rest_of_props} />
                    } />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>;
            case 'Select':
                return <ReduxField fullWidth
                                   className={formControlClasses}
                                   classes={classes}
                                   _classes={_classes}
                                   label={label}
                                   component={_renderSelect}
                                   name={name}
                                   helperText={helperText}
                                   {...rest_of_props} />;
            case 'NoReduxSelect':
                return <FormControl fullWidth
                                    className={formControlClasses}>
                    <InputLabel>{label}</InputLabel>
                    <MUISelect classes={{selectMenu: `${classes.selectSelectMenu} ${classes.selectListItemSupport}`, disabled: classes.disabledSelect, ..._classes}}
                               MenuProps={{classes: {paper: `${classes.selectMenuPaper} ${classes.selectListItemSupport}`}}}
                               {...rest_of_props} />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>;
            default:
                console.error('Not defined Field type!');
                return <div />;
        }
    }
}

FieldIcon = withStyles(styles)(FieldIcon);
FieldWithIconHolder = withStyles(styles)(FieldWithIconHolder);
InFormContent = withStyles(styles)(InFormContent);
Field = withStyles(styles)(Field);

export {FieldIcon, FieldWithIconHolder, InFormContent, ColorField, CompanyTypeField,
    CountryField, IconField, MarkdownField, PortletField, SocialField,
    IconButtonSelect};
export default Field;
