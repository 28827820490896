'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Xing icon
 */
export default class Xing extends React.Component {
    static defaultProps = {
        viewBox: '0 0 512 512',
        color1: '#006567',
        color2: '#b0d400'
    };

    render() {
        let {color1, color2, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <path style={{fill: color1}} d='M499.906,5.312c-1.911-3.292-5.431-5.316-9.237-5.312H384.002c-3.835-0.006-7.378,2.046-9.28,5.376
                L204.056,304.043c-1.832,3.251-1.832,7.224,0,10.475l106.667,192c1.875,3.371,5.423,5.467,9.28,5.483h106.667
                c5.891,0,10.667-4.775,10.667-10.666c0-1.814-0.463-3.599-1.344-5.184L332.248,309.333L499.949,15.957
                C501.833,12.655,501.817,8.599,499.906,5.312z' />
            <path style={{fill: color2}} d='M222.488,197.184l-64-106.667c-1.927-3.217-5.402-5.185-9.152-5.184H42.669
                C36.778,85.332,32.001,90.107,32,95.998c0,1.932,0.524,3.828,1.517,5.485l60.8,101.312L12.056,346.709
                c-2.922,5.115-1.144,11.631,3.971,14.553c1.617,0.923,3.447,1.408,5.309,1.405h106.667c3.835,0.006,7.378-2.046,9.28-5.376
                l85.333-149.333C224.523,204.607,224.474,200.488,222.488,197.184z' />
        </SvgIcon>;
    }
}
