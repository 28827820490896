'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Diamond icon
 */
export default class Diamond extends React.Component {
    static defaultProps = {
        viewBox: '0 0 512 512',
        color1: '#BDFAFF',
        color2: '#A0F0F2',
        color3: '#66D3D0',
        color4: '#5FC1BC'
    };

    render() {
        let {color1, color2, color3, color4, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <polygon style={{fill: color1}} points='360.129,172.138 256,472.276 512,172.138' />
            <polygon style={{fill: color1}} points='406.069,39.724 256,39.724 360.129,172.138' />
            <polygon style={{fill: color2}} points='105.931,39.724 0,172.138 151.871,172.138' />
            <polygon style={{fill: color2}} points='360.129,172.138 512,172.138 406.069,39.724' />
            <polygon style={{fill: color2}} points='360.129,172.138 256,39.724 151.871,172.138' />
            <polygon style={{fill: color3}} points='256,39.724 105.931,39.724 151.871,172.138' />
            <polygon style={{fill: color3}} points='151.871,172.138 256,472.276 360.129,172.138' />
            <polygon style={{fill: color4}} points='0,172.138 256,472.276 151.871,172.138' />
        </SvgIcon>;
    }
}
