'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
    paper: {
        '& ul': {
            padding: '0' // get rid of padding above and bellow MenuItems
        }
    },
    menuItem: {
        color: theme.palette.common.white // readable text
    }
});

/**
 * Redux Field for Material Colors visible with white text
 */
class ColorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: [
                Map({hash: '#F44336', text: 'red'}),
                Map({hash: '#E91E63', text: 'pink'}),
                Map({hash: '#9C27B0', text: 'purple'}),
                Map({hash: '#673AB7', text: 'deep_purple'}),
                Map({hash: '#3F51B5', text: 'indigo'}),
                Map({hash: '#2196F3', text: 'blue'}),
                Map({hash: '#03A9F4', text: 'light_blue'}),
                Map({hash: '#00BCD4', text: 'cyan'}),
                Map({hash: '#009688', text: 'teal'}),
                Map({hash: '#4CAF50', text: 'green'}),
                Map({hash: '#8BC34A', text: 'light_green'}),
                Map({hash: '#CDDC39', text: 'lime'}),
                Map({hash: '#FF9800', text: 'orange'}),
                Map({hash: '#FF5722', text: 'deep_orange'}),
                Map({hash: '#795548', text: 'brown'}),
                Map({hash: '#9E9E9E', text: 'grey'}),
                Map({hash: '#607D8B', text: 'blue_grey'})
            ]
        };
    };

    render() {
        // Split some stuff from props
        let {classes, intl, ...rest_of_props} = this.props;

        return <Field fieldType='Select'
                      renderValue={hash => !hash ? '' : <div style={{color: hash}}>
                          <FormattedMessage id={`colorfield.color.${this.state.colors.find(el => el.get('hash') === hash) ? this.state.colors.find(el => el.get('hash') === hash).get('text') : 'unknown'}`} />
                      </div>}
                      MenuProps={{PopoverClasses: {paper: classes.paper}}}
                      {...rest_of_props}>
            {this.state.colors.map((color, idx) =>
                <MenuItem key={idx} value={color.get('hash')} style={{backgroundColor: color.get('hash')}} className={classes.menuItem}>
                    <FormattedMessage id={`colorfield.color.${color.get('text')}`} />
                </MenuItem>
            )}
        </Field>;
    }
}

ColorField = withStyles(styles)(ColorField);
export default ColorField;
