'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// Actions
import {confirmEmailChange} from 'actions/users';
// Components
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';


/**
 * Component to indicate confirmation of email. Then redirects User to login or profile page (based on logged status)
 */
class ConfirmEmail extends React.Component {
    /**
     * On initialization run email confirmation
     */
    componentDidMount() {
        this.props.confirmEmailChange({token: this.props.match.params.token}).then(() => {
            // redirect based on User status
            this.props.history.push(this.props.authState === 'logged' ? '/' : '/settings/profile');
        });
    }

    render() {
        return <div>
            {this.props.state === 'updating_profile_email' &&
            <div>
                <SpaceDivider double />
                <LinearProgress />
                <SpaceDivider double />
            </div>}
        </div>;
    }
}

ConfirmEmail = connect((state) => ({
    state: state.app.get('state'),
    authState: state.auth.get('state')
}), (dispatch) => bindActionCreators({
    confirmEmailChange
}, dispatch))(ConfirmEmail);

export default withRouter(ConfirmEmail);
