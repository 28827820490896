'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import marked from 'marked';
// Actions
import {setState} from 'actions/app';
import {setState as setApiState} from 'actions/api';
import {updateProfile} from 'actions/users';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// icons
import ToSUpdateIcon from '@material-ui/icons/Description';
import AcceptIcon from '@material-ui/icons/Check';


/**
 * Shows newly updated Terms of Services - User must approve them to continue using application
 */
class ToSUpdate extends React.Component {
    render() {
        return <Card>
            <CardHeader
                title={this.props.tos.get('title')}
                subheader={<FormattedMessage id='tos.update.description' />}
                action={
                    <ActionButton iconButton disabled>
                        <ToSUpdateIcon />
                    </ActionButton>
                }
            />
            <CardContent>
                <div dangerouslySetInnerHTML={{__html: marked(this.props.tos.get('text'), {breaks: true})}} />
            </CardContent>
            {['updating_profile', 'updated_profile', 'failed_update_profile'].includes(this.props.state)
                ? <CardActionsLoader success={this.props.state === 'updated_profile'}
                                     failure={this.props.state === 'failed_update_profile'}
                                     postAnimation={success => {
                                         this.props.setState(null);
                                         if (success) {
                                             this.props.setApiState('ready');
                                         }
                                     }} />
                : <MuiThemeProvider theme={altTheme}>
                <CardActions>
                    <Button disabled={this.props.state !== null}
                            variant='contained' color='primary' type='submit'
                            onClick={() => this.props.state === null ? this.props.updateProfile({last_tos_version: this.props.tos.get('version')}) : {}}>
                        <AcceptIcon />
                        <FormattedMessage id='actions.accept' />
                    </Button>
                </CardActions>
            </MuiThemeProvider>}
        </Card>;
    }
}

ToSUpdate = connect((state) => ({
    state: state.app.get('state'),
    tos: state.tables.getIn(['tables', 'static-texts']) ? state.tables.getIn(['tables', 'static-texts']).find((el) => (el.get('name') === 'tos')) : null
}), (dispatch) => bindActionCreators({
    setState,
    updateProfile,
    setApiState
}, dispatch))(ToSUpdate);

export default injectIntl(ToSUpdate);
