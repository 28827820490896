'use strict';
import {constants} from 'reducers/portlets/general';


/**
 * Simple action creator to modify specific Portlet state
 *
 * @param portlet - Portlet instance
 * @param {string|null} state - e.g. 'null', 'fetching', 'error'
 */
export function setPortletState(portlet, state) {
    return {
        type: constants.PORTLET_STATE,
        portlet,
        state
    };
}

/**
 * Set selected item in Portlet
 *
 * @param portlet - Portlet instance
 * @param item - item to be set or null
 */
export function setSelected(portlet, item) {
    return {
        type: constants.PORTLET_SELECTED,
        portlet,
        item
    };
}

/**
 * Simple action creator to set Portlet related data to store
 *
 * @param portlet
 * @param data
 * @returns {{type: *, portlet: *, data: *}}
 */
export function setRelatedData(portlet, data) {
    return {
        type: constants.PORTLET_RELATED_DATA,
        portlet,
        data
    };
}
