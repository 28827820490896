'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';
import validator from 'lib/valitator';
import {altTheme} from 'theme/index';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {login, setState as setAuthState} from 'actions/auth';
// Components
import {Link, withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// icons
import LoginIcon from '@material-ui/icons/Lock';
import RegisterIcon from '@material-ui/icons/AccessibilityNew';
import RegisterButtonIcon from '@material-ui/icons/Add';
import SubmitIcon from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


/**
 * Simple Login page
 */
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false // flag to display/hide password
        };
    };

    render() {
        return <div>
            <Card>
                <CardHeader
                    title={<FormattedMessage id='login.title' />}
                    subheader={<FormattedMessage id='login.subheader' />}
                    action={
                        <ActionButton iconButton disabled>
                            <LoginIcon />
                        </ActionButton>
                    }
                />
                <CardContent>
                    <Form onSubmit={this.props.handleSubmit} alignCenter>
                        <Field name='email' type='email' fieldType='TextField' label={<FormattedMessage id='login.form.fields.email' />} />
                        <FieldWithIconHolder>
                            <Field withIcon name='password' type={this.state.showPassword ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='login.form.fields.password' />} />
                            <FieldIcon onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </FieldIcon>
                        </FieldWithIconHolder>
                    </Form>
                    {(this.props.submitFailed && this.props.error && this.props.error === 'invalid_credentials') && <ErrorMessage>
                        <FormattedMessage id='login.error.invalid_credentials' />
                    </ErrorMessage>}
                    <p className='text-right notmargin'>
                        <Link className='hover-border' to='/forgotten-password'><FormattedMessage id='login.forgottenpassword' /></Link>
                    </p>
                </CardContent>
                {['logging', 'login_failed'].includes(this.props.authState)
                    ? <CardActionsLoader failure={this.props.authState === 'login_failed'}
                                         postAnimation={success => this.props.setAuthState(null)} />
                    : <CardActions>
                    <Button variant='contained' color='secondary' type='submit' onClick={this.props.handleSubmit}>
                        <SubmitIcon />
                        <FormattedMessage id='login.form.submit' />
                    </Button>
                </CardActions>}
            </Card>
            <SpaceDivider grid />
            <Card>
                <CardHeader
                    title={<FormattedMessage id='login.register.title' />}
                    subheader={<FormattedMessage id='login.register.subheader' />}
                    action={
                        <ActionButton iconButton disabled>
                            <RegisterIcon />
                        </ActionButton>
                    }
                />
                <MuiThemeProvider theme={altTheme}>
                    <CardActions center>
                        <Button variant='contained' color='primary'
                                onClick={() => this.props.history.push('/registration')}>
                            <RegisterButtonIcon />
                            <FormattedMessage id='login.register' />
                        </Button>
                    </CardActions>
                </MuiThemeProvider>
                <SpaceDivider />
            </Card>
        </div>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'password', data.password);

    return errors;
};

Login = reduxForm({
    form: 'loginForm',
    initialValues: {
        email: process.env.NODE_ENV === 'development' ? 'admin@admin.cz' : '',
        password: process.env.NODE_ENV === 'development' ? 'admin' : ''
    },
    validate,
    onSubmit: (values, dispatch, props) => (
        dispatch(login(values))
    )
})(Login);

Login = connect((state) => ({
    authState: state.auth.get('state')
}), (dispatch) => bindActionCreators({
    login,
    setAuthState
}, dispatch))(Login);

export default injectIntl(withRouter(Login));
