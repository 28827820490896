'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import marked from 'marked';
import {connect} from 'react-redux';
// material-ui
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
// icons
import Silver from 'components/core/icons/partnerlevel/Silver';
import Gold from 'components/core/icons/partnerlevel/Gold';
import Platinum from 'components/core/icons/partnerlevel/Platinum';
import Diamond from 'components/core/icons/partnerlevel/Diamond';


const styles = theme => ({
    // Holder of Icon and Text next to it
    contentHolder: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    // headline of specific Partner Level
    partnerLevelHeadline: {
        textTransform: 'capitalize'
    },
    // Partner Level Icon
    iconHolder: {
        minWidth: '150px',
        marginRight: `${theme.spacing(4)}px`, // space between
        // icon itself
        '& svg': {
            fontSize: '150px' // increase size
        }
    }
});

/**
 * Get correct Partner Level Icon
 *
 * @param identifier - Identifier of level, e.g. 'silver'
 */
export function getPartnerLevelIcon(identifier) {
    switch (identifier) {
        case 'silver':
            return <Silver />;
        case 'gold':
            return <Gold />;
        case 'platinum':
            return <Platinum />;
        case 'diamond':
            return <Diamond />;
        default:
            console.error(`Unknown Partner Level icon ${identifier}`);
            return <div />;
    }
}

/**
 * Displays partner level of Company
 */
class PartnerLevel extends React.Component {
    render() {
        if (!this.props.partner_level.get('code')) {
            return <div />;
        }

        return <CardContent>
            <div className={this.props.classes.contentHolder}>
                <div className={this.props.classes.iconHolder}>{getPartnerLevelIcon(this.props.partner_level.get('code'))}</div>
                <div>
                    <Typography variant='h5'><FormattedMessage id='portlets.partner_level.title' /></Typography>
                    <Typography variant='h4' className={this.props.classes.partnerLevelHeadline} style={{color: this.props.partner_level.get('primary_color')}}>
                        {this.props.partner_level.get('name')}
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: marked(this.props.partner_level.get('description'), {breaks: true})}} />
                </div>
            </div>
        </CardContent>;
    }
}

PartnerLevel = withStyles(styles)(PartnerLevel);
PartnerLevel = connect((state) => ({
    company: state.company.get('company'),
    partner_level: state.company.get('partner_level')
}))(PartnerLevel);

export default injectIntl(PartnerLevel);
