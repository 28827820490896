'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUICardActions from '@material-ui/core/CardActions';


const styles = theme => ({
    cardActionsRoot: {
        justifyContent: 'flex-end',
        padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
        '&.center': {
            justifyContent: 'center'
        }
    }
});

/**
 * Extends CardActions component to change root style (right align by default)
 */
class CardActions extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, center, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [classes.cardActionsRoot, root, className, center ? 'center' : ''].filter(Boolean).join(' ');

        return <MUICardActions
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        />;
    }
}

CardActions = withStyles(styles)(CardActions);
export default CardActions;
