'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {toggleMobileMenu} from 'actions/app';
import {logout} from 'actions/auth';
import {setState as setApiState} from 'actions/api';
// Components
import LanguageSwitcherMenu from 'components/core/ui/LanguageSwitcherMenu';
import {withRouter} from 'react-router-dom';
// material-ui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
// icons
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import EmptyAvatar from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';


const styles = theme => ({
    appbar: {
        background: theme.palette.primary[800],
        height: '64px',
        marginBottom: '2px', // border size
        whiteSpace: 'nowrap',
        position: 'relative',
        // partner levels style
        '&.partner-level': {
            // gradient border
            '&:after': {
                // pseudo element
                content: '""',
                display: 'block',
                // size and position
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '-2px', // size of border
                height: '2px',
                // style
                background: (props) => `linear-gradient(to bottom right, ${props.partner_level.get('highlight_color')}, ${props.partner_level.get('primary_color')})`
            }
        },
        // customers specific style
        '&.asisi': {
            background: theme.palette.common.black
        }
    },
    toolbar: {
        minHeight: '64px' // disable lowering size of toolbar in responsive
    },
    // mobile button
    menuButton: {
        color: theme.palette.common.white,
        marginLeft: '-12px',
        marginRight: '20px',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    // holder of Title and Logo
    logoHolder: {
        display: 'block',
        flexGrow: '1', // full size (menu on the left, rest on the right)
        // hide logo on mobile when we have menu button (logged user only)
        '&.hide-on-mobile h1': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    // Logged User name in menu
    menuItemUser: {
        // move to top
        marginTop: `-${theme.spacing(1)}px`,
        paddingTop: `${theme.spacing(1)}px`,
        // get rid of disabled style
        opacity: '1 !important',
        // increase size
        minHeight: '48px',
        // partner levels style
        '&.partner-level': {
            color: (props) => props.partner_level.get('text_color')
                ? props.partner_level.get('text_color')
                : theme.palette.text['primary'],
            background: (props) => `linear-gradient(to right, ${props.partner_level.get('highlight_color')}, ${props.partner_level.get('primary_color')})`
        }
    },
    // holder of actions buttons
    actionButtonsHolder: {
        // fix position (same as with menuButton)
        marginRight: '-12px'
    },
    // holder of single button to allow tooltip when button is disabled
    actionButtonHolder: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        marginLeft: `${theme.spacing(0.5)}px` // space between
    },
    // actions buttons like logout
    actionButton: {
        color: `${theme.palette.common.white} !important`
    },
    // holder of user picture
    avatarHolder: {
        // style
        cursor: 'pointer', // link like
        border: `1px solid ${theme.palette.grey[600]}`,
        borderRadius: '50%',
        // position
        display: 'inline-flex',
        verticalAlign: 'middle',
        // display something during image loading, also background of empty avatar
        background: theme.palette.grey[400],
        // fix size to be same as iconButton, increase right side (due to full size)
        margin: `3px ${3 + (theme.spacing(0.5))}px 3px 3px`,
        // partner levels style
        '&.partner-level': {
            borderColor: 'transparent',
            borderWidth: '2px',
            // for gradient border
            position: 'relative',
            backgroundClip: 'padding-box',
            // gradient border
            '&:before': {
                borderRadius: 'inherit', // radius as parent
                // pseudo element
                content: '""',
                display: 'block',
                // full-size
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                // move to border
                margin: '-2px', // size of border
                zIndex: '-1', // not before background
                // style
                background: (props) => `linear-gradient(to bottom right, ${props.partner_level.get('primary_color')}, ${props.partner_level.get('highlight_color')})`
            }
        }
    },
    // style of MenuItems
    menuItem: {
        // get rid of disabled style
        opacity: '1'
    }
});

/**
 * Application Header
 */
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageMenuOpen: false, // flag to open/close language menu
            languageMenuAnchor: null, // anchor to correct position of menu
            moreMenuOpen: false, // flag to open/close more menu
            moreMenuAnchor: null // anchor to correct position of menu
        };
    }

    /**
     * Renders MenuItem for Menu
     *
     * @param path - Slug, e.g. '/manage_profile'
     * @param intl_id - ID to intl for FormattedMessage
     */
    renderMenuItem(path, intl_id) {
        return <MenuItem disabled={this.props.state !== null}
                         classes={{root: this.props.classes.menuItem}}
                         selected={this.props.location.pathname.startsWith(path)}
                         onClick={this.props.state === null ? () => { this.props.history.push(path); this.setState({moreMenuOpen: false}); } : () => {}}>
            <FormattedMessage id={intl_id} />
        </MenuItem>;
    }

    render() {
        return <AppBar position='static' className={`${this.props.classes.appbar}${this.props.config && this.props.config.get('customer') === 'asisi' ? ' asisi' : this.props.partner_level.get('code') ? ' partner-level' : ''}`}>
            <Toolbar className={this.props.classes.toolbar}>
                {this.props.authState === 'logged' &&
                    <IconButton className={this.props.classes.menuButton}
                                onClick={() => this.props.toggleMobileMenu(!this.props.openMobileMenu)}>
                        <MenuIcon />
                    </IconButton>
                }
                <div className={`${this.props.classes.logoHolder}${this.props.authState === 'logged' ? ' hide-on-mobile' : ''}`} />
                <div className={this.props.classes.actionButtonsHolder}>
                    {this.props.authState === 'logged' && this.props.apiState === 'ready' && <React.Fragment>
                        <div className={`${this.props.classes.avatarHolder}${this.props.config && this.props.config.get('customer') === 'asisi' ? ' asisi' : this.props.partner_level.get('code') ? ' partner-level' : ''}`}>
                            <Avatar alt={`${this.props.user.get('first_name')} ${this.props.user.get('last_name')}`}
                                    src={this.props.user.get('avatar_url') || undefined}
                                    onClick={(event) => this.setState({moreMenuOpen: !this.state.moreMenuOpen, moreMenuAnchor: event.currentTarget})}>
                                {!this.props.user.get('avatar_url') && <EmptyAvatar />}
                            </Avatar>
                        </div>
                    </React.Fragment>}
                    {this.props.authState === 'logged' && this.props.apiState === 'ready' &&
                        <Menu open={this.state.moreMenuOpen}
                              anchorEl={this.state.moreMenuAnchor}
                              onClose={() => this.setState({moreMenuOpen: false})}>
                            <MenuItem disabled className={`${this.props.classes.menuItemUser}${this.props.partner_level.get('code') ? ' partner-level' : ''}`}>
                                {`${this.props.user.get('first_name')} ${this.props.user.get('last_name')}`}
                            </MenuItem>
                            <Divider />
                            {this.renderMenuItem('/settings/profile', 'header.more.manage_profile')}
                            {this.props.user.isAdmin() && this.renderMenuItem('/settings/users', 'header.more.manage_users')}
                            {this.props.user.isAdmin() && this.renderMenuItem('/settings/company', 'header.more.manage_company')}
                        </Menu>
                    }
                    <Tooltip title={<FormattedMessage id='header.language' />}>
                        <div className={this.props.classes.actionButtonHolder}>
                            <IconButton className={this.props.classes.actionButton}
                                        onClick={(event) => this.setState({languageMenuOpen: !this.state.languageMenuOpen, languageMenuAnchor: event.currentTarget})}>
                                <LanguageIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <LanguageSwitcherMenu open={this.state.languageMenuOpen}
                                          anchorEl={this.state.languageMenuAnchor}
                                          onClose={() => this.setState({languageMenuOpen: false})} />
                    {this.props.authState === 'logged' && ['ready', 'tos_update'].includes(this.props.apiState) &&
                        <Tooltip title={<FormattedMessage id='actions.logout' />}>
                            <div className={this.props.classes.actionButtonHolder}>
                                <IconButton disabled={this.props.state !== null}
                                            className={this.props.classes.actionButton}
                                            onClick={this.props.state === null ? () => this.props.logout().then(
                                                () => {
                                                    if (this.props.apiState === 'tos_update') {
                                                        this.props.setApiState('ready');
                                                    }
                                                    this.props.history.push('/');
                                                }) : () => {}}>
                                    <LogoutIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    }
                </div>
            </Toolbar>
        </AppBar>;
    }
}

Header = withStyles(styles)(Header);
Header = connect((state) => ({
    apiState: state.api.get('state'),
    authState: state.auth.get('state'),
    state: state.app.get('state'),
    config: state.api.get('config'),
    openMobileMenu: state.app.get('openMobileMenu'),
    user: state.auth.get('user'),
    company: state.company.get('company'),
    partner_level: state.company.get('partner_level')
}), (dispatch) => bindActionCreators({
    toggleMobileMenu,
    logout,
    setApiState
}, dispatch))(Header);

export default injectIntl(withRouter(Header));
