'use strict';
import {Record, Map, Iterable, fromJS} from 'immutable';

/**
 * Convert raw data of model to model.
 * @see https://facebook.github.io/immutable-js/docs/#/fromJS
 *
 * @param Klazz - model
 * @param data - raw data of model
 */
export function asModel(Klazz, data) {
    return fromJS(data, (key, sequence) => (
        Iterable.isIndexed(sequence)
            ? sequence.toList()
            : key === 'company' ? new Company(sequence)
            : key !== '' ? new Map(sequence)
            : new Klazz(sequence)
    ));
}

/**
 * Convert raw list data of model to models.
 *
 * @param Klazz - model
 * @param data - raw data of multiple models
 */
export function listAsModel(Klazz, data) {
    return fromJS(data, (key, sequence) => (
        Iterable.isIndexed(sequence)
            ? sequence.toList()
            : key === 'company' ? new Company(sequence)
            : !Number.isInteger(key) ? new Map(sequence)
            : new Klazz(sequence)
    ));
}

/**
 * Convert raw list data of Porlets to models.
 *
 * @param Klazz - PagePortlet class
 * @param data - raw data of multiple models
 */
export function portletListAsModel(Klazz, data) {
    return fromJS(data, (key, sequence) => (
        Iterable.isIndexed(sequence)
            ? sequence.toList()
            : key === 'config' ? new Portlet(sequence)
            : !Number.isInteger(key) ? new Map(sequence)
            : sequence.get('config') && sequence.get('width') ? new PagePortlet(sequence)
            : new Map(sequence)
    ));
}

/**
 * Dynamic Configuration of Portal instance
 */
export class Config extends Record({
    customer: null
}) {}

/**
 * Informative Message to inform User what is happening
 */
export class Message extends Record({
    intl_id: null, // text in intl
    intl_values: null, // additional values for intl (e.g. <Link />)
    text: null, // text directly (instead of intl)
    path: null, // path where message sits until removed or 'on-change'
    remove_paths: null, // remove message when this path is reached
    type: 'success' // success / error / info
}) {}

/**
 * Model to store links for Paginated requests
 */
export class Paginator extends Record({
    page: 1, // starting Page
    maxLoadedPage: null, // maximum loaded Page
    next: null // next page
}) {}

/**
 * Model for Tables countries
 */
export class TableCountry extends Record({
    id: null,
    url: null,
    name: null
}) {}

/**
 * Model for Tables company-types
 */
export class TableCompanyType extends Record({
    id: null,
    url: null,
    name: null
}) {}

/**
 * Model for Tables static-text
 */
export class TableStaticText extends Record({
    url: null,
    name: null,
    version: null,
    title: null,
    text: null
}) {}

/**
 * General User account
 */
export class User extends Record({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone_nr: null,
    created_at: null,
    last_access: null,
    role: null,
    avatar: null,
    avatar_url: null,
    company_name: null,
    company_id: null,
    last_tos_version: null,

    url: null, // link to self
    // later dynamic fields ?
    skype: null,
    twitter: null,
    linkedin: null,
    xing: null
}) {
    isAdmin() {
        return [
            'superuser',
            'company_consultant',
            'customer_admin',
            'root_company_admin',
            'root_company_user'
        ].includes(this.role);
    }
    isSuperAdmin() {
        return [
            'superuser',
            'company_consultant',
            'root_company_admin',
            'root_company_user'
        ].includes(this.role);
    }
    popUpFields(data) {
        let {id, url, created_at, last_access, role, avatar, avatar_url, password2, company_name, company_id, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * User belong to some Company
 */
export class Company extends Record({
    id: null,
    name: null,

    email: null,
    phone_nr: null,
    website_url: null,
    vat_number: null,
    logo: null,
    logo_url: null,
    created_at: null,
    user_count: null,

    // address
    country: null,
    city: null,
    street: null,
    code: null,

    url: null, // link to self, we get this link from login request (token)
    partner_level: null, // relationship with PartnerLevel
    user_set: null, // link to users
}) {
    popUpFields(data) {
        let {id, url, logo, logo_url, user_set, created_at, user_count, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * PartnerLevel of the Company (e.g. silver, gold, etc.)
 */
export class PartnerLevel extends Record({
    id: null,
    name: null,
    description: null,
    code: null,

    text_color: null,
    primary_color: null,
    highlight_color: null,

    url: null,
}) {}

/**
 * Invitation model is basically User model with Company inside
 */
export class Invitation extends Record({
    first_name: null,
    last_name: null,
    email: null,
    company: new Company()
}) {}

/**
 * Dynamic Pages containing Portlets for logged user
 */
export class Page extends Record({
    id: null,
    slug: null,
    title: null,
    icon: null,
    active: null,
    portlet_count: null,

    url: null, // link to self
    page_portlet_set: null, // link to page portlets
    page_portlet_order: null // link to reorder page portlets
}) {
    popUpFields(data) {
        let {id, url, portlet_count, page_portlet_set, page_portlet_order, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Base Portlet model
 */
export class Portlet extends Record({
    id: null,
    name: null,
    title: null,
    subheader: null,
    title_icon: null,
    type: null,
    groups: null,

    url: null, // link to self
    related_data_set: null, // link to related-data (like news articles)
    related_data_order: null, // link to ordering of related-data
    // specific portlet data
    portlet_data: Map()
}) {
    // get list of all types
    getTypes() {
        return [
            'html',
            // 'social',
            'news',
            'contact_us',
            'contacts',
            'google_calendar',
            'gallery',
            'files',
            'partner_level',
            'leads'
        ];
    }
    // get list of types with related data
    getTypesWithRelatedData() {
        return [
            'social',
            'news',
            'contacts',
            'google_calendar'
        ];
    }
    // get list of all types which related models are sortable
    getRelatedSortableTypes() {
        return [
            'social',
            'contacts',
            'google_calendar'
        ];
    }
    // get Icon to Type of Portlet (use with Material-ui <Icon />)
    getTypeIcon() {
        switch (this.type) {
            case 'html':
                return 'code';
            case 'social':
                return 'share';
            case 'news':
                return 'description';
            case 'contact_us':
                return 'textsms';
            case 'contacts':
                return 'contacts';
            case 'google_calendar':
                return 'event';
            case 'gallery':
                return 'view_carousel';
            case 'files':
                return 'file_copy';
            case 'partner_level':
                return 'star_rate';
            case 'leads':
                return 'people';
            default:
                console.warn(`unknown portlet type ${this.type}`);
                return 'extension';
        }
    }
    popUpFields(data) {
        let {id, url, related_data_set, related_data_order, ...rest_of_data} = data;
        return rest_of_data;
    }
    relatedPopUpFields(data, selectedType) {
        // pop-up some values which we don't want to send
        let {id, url, ...rest_of_data} = data;

        // pop specific fields from related object
        switch (selectedType) {
            case 'news':
                delete rest_of_data['created_at'];
                delete rest_of_data['tags'];
                break;
        }

        return rest_of_data;
    }
}

/**
 * Page Portlet
 */
export class PagePortlet extends Record({
    id: this ? this.url : null,
    url: null,
    width: null,
    height: null,
    // base portlet relationship
    config: new Portlet()
}) {
    getWidth() {
        switch (this.width) {
            case 1:
                return '100%';
            case 2:
                return '75%';
            case 3:
                return '66.6667%';
            case 4:
                return '50%';
            case 5:
                return '33.3333%';
            case 6:
                return '25%';
            default:
                return '100%';
        }
    }
    // Portals
    getTypes() { return this.config.getTypes(); }
    getTypesWithRelatedData() { return this.config.getTypesWithRelatedData(); }
    getRelatedSortableTypes() { return this.config.getRelatedSortableTypes(); }
    getTypeIcon() { return this.config.getTypeIcon(); }
}

/**
 * Product Group
 */
export class ProductGroup extends Record({
    id: null,
    url: null,
    name: null,

    // tree fields
    index: null,
    parent: null
}) {
    popUpFields(data) {
        let {id, url, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Product
 */
export class Product extends Record({
    id: null,
    url: null,
    name: null,
    description: null,
    sku_code: null,
    msrp: null,
    weight: null,
    not_released: null,
    groups: null, // ProductGroup relationship
    add_groups: null // link where to send Product Groups
}) {
    popUpFields(data) {
        let {id, url, groups, add_groups, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Project, which contains order (for multiple Products)
 */
export class Project extends Record({
    id: null,
    url: null,

    name: null,
    completion_date: null,
    company_name: null,
    company_id: null,
    user_name: null,
    user_id: null,
    notes: null,
    one_shot_delivery: null,

    customer: null,
    customer_city: null,
    customer_street: null,
    customer_zip: null,
    contact_person: null,

    code: null,
    created_at: null,
    longitude: null,
    latitude: null
}) {
    popUpFields(data) {
        let {id, url, code, created_at, longitude, latitude, company_name, user_name, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Folder where Files lay
 */
export class Folder extends Record({
    id: null,
    name: null,
    parent_folder: null,
    has_subfolders: null, // flag if folder have children (Folders)

    url: null,
    file_set: null, // link to Folder Files
    folder_set: null // link to child Folders
}) {
    popUpFields(data) {
        let {id, folder_children, url, file_set, folder_set, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * All Images and other Files, which Portal uses
 */
export class FileModel extends Record({
    id: null,
    url: null,
    name: null,

    folder: null,
    file: null,
    mine_type: null,
    file_size: null
}) {
    popUpFields(data) {
        let {id, url, file, mine_type, file_size, ...rest_of_data} = data;
        return rest_of_data;
    }
    // return provided file Name without extension
    getNameWithoutExtension(name_with_extension) {
        return name_with_extension.split(/\.(?=[^\.]+$)/)[0];
    }
    // get human-readable size of File
    getSize() {
        let size = this.file_size || 0;
        if (size === 0) return '0 B';
        let f = Math.floor(Math.log(size) / Math.log(1024));
        return `${parseFloat((size / Math.pow(1024, f)).toFixed(2))} ${['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][f]}`;
    }
    // return file Type based on File
    getFileExtension() {
        return this.file ? this.file.split(/\#|\?/)[0].split('.').pop().trim().toLowerCase() : '';
    }
    // get type based on File Extension
    getType() {
        switch (this.getFileExtension()) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'png':
            case 'ico':
            case 'bmp':
            case 'svg':
                return 'image';
            case 'mp3':
            case 'mid':
            case 'midi':
            case 'ogg':
            case 'wma':
                return 'music';
            case 'mp4':
            case 'wav':
            case 'avi':
            case 'wmv':
            case 'mov':
            case 'flv':
                return 'video';
            case 'html':
                return 'web';
            case 'txt':
            case 'pdf':
            case 'odt':
            case 'doc':
            case 'rtf':
            case 'xml':
                return 'document';
            case 'zip':
            case 'rar':
            case 'tar':
            case '7z':
                return 'archive';
            default:
                return 'file';
        }
    }
    // returns 'Image' / 'File' based on File type
    getGeneralType() {
        switch (this.getType()) {
            case 'image':
                return 'Image';
            default:
                return 'File';
        }
    }
    // returns material-icon string based on File type
    getIcon() {
        switch (this.getType()) {
            case 'image':
                return 'image';
            case 'music':
                return 'music_note';
            case 'video':
                return 'movie';
            case 'web':
                return 'web';
            case 'document':
                return 'description';
            case 'archive':
                return 'file_copy';
            case 'file':
                return 'insert_drive_file';
        }
    }
}

/**
 * Lead Form model - Basically data source
 */
export class LeadForm extends Record({
    id: null,
    url: null,
    name: null,
    code: null,
    domains: null,
    require_recaptcha: null
}) {}
