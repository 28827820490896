'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        display: 'block',
        width: '100%',
        height: `${theme.spacing(2)}px`,
        '&.double': {
            height: `${theme.spacing(4)}px`,
        },
        '&.none': {
            height: '0'
        },
        '&.grid': {
            height: `${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                height: `${theme.spacing(1)}px`,
            }
        },
        '&.loading': {
            // bellow and above divider
            height: `${30 + (theme.spacing(2))}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                height: `${38 + (theme.spacing(1))}px`
            }
        }
    }
});

/**
 * Space divider to divide content
 *
 * Props:
 *  double - double the size of divider
 *  none   - zero height, it's just divider (useful in Flex <Form /> instead of <br />)
 *  grid   - size as grid spacing
 */
class SpaceDivider extends React.Component {
    render() {
        let classes = [
            this.props.className,
            this.props.classes.root,
            this.props.double ? 'double'
                : this.props.none ? 'none'
                : this.props.grid ? 'grid'
                : this.props.loading ? 'loading'
                : ''
        ].filter(Boolean).join(' ');

        return <div className={classes} style={this.props.height ? {height: this.props.height} : {}} />;
    }
}

SpaceDivider = withStyles(styles)(SpaceDivider);
export default SpaceDivider;
