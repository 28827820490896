'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// social icons
import FacebookIcon from 'components/core/icons/social/Facebook';
import InstagramIcon from 'components/core/icons/social/Instagram';
import LinkedinIcon from 'components/core/icons/social/Linkedin';
import MediumIcon from 'components/core/icons/social/Medium';
import SalesforceIcon from 'components/core/icons/social/Salesforce';
import TwitterIcon from 'components/core/icons/social/Twitter';
import YoutubeIcon from 'components/core/icons/social/Youtube';


/**
 * Get correct social Icon
 *
 * @param identifier - Identifier of social network, e.g. 'facebook'
 */
export function getSocialIcon(identifier) {
    switch (identifier) {
        case 'facebook':
            return <FacebookIcon />;
        case 'instagram':
            return <InstagramIcon />;
        case 'linkedin':
            return <LinkedinIcon />;
        case 'medium':
            return <MediumIcon />;
        case 'salesforce':
            return <SalesforceIcon noText />;
        case 'twitter':
            return <TwitterIcon />;
        case 'youtube':
            return <YoutubeIcon />;
        default:
            console.error(`Unknown social icon ${identifier}`);
            return <span />;
    }
}

/**
 * Simple Select for Social links
 */
class SocialField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            social_links: ['facebook', 'instagram', 'linkedin', 'medium', 'salesforce', 'twitter', 'youtube']
        };
    };

    render() {
        // Split some stuff from props
        let {intl, ...rest_of_props} = this.props;

        return <Field fieldType='Select' {...rest_of_props}>
            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
            {this.state.social_links.map((identifier, idx) =>
                <MenuItem key={idx} value={identifier}>
                    <ListItemIcon className='icon'>{this.getSocialIcon(identifier)}</ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id={`socialfield.link.${identifier}`} /></ListItemText>
                </MenuItem>
            )}
        </Field>;
    }
}

export default injectIntl(SocialField);
