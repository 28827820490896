'use strict';
import React from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';
import validator from 'lib/valitator';
import {altTheme} from 'theme/index';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {addMessage} from 'actions/app';
import {setState as setAuthState, loginProcedure} from 'actions/auth';
import {simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import ToSDialog from 'components/core/ui/ToSDialog';
import Field, {FieldIcon, FieldWithIconHolder, CompanyTypeField} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import RegisterIcon from '@material-ui/icons/AccountCircle';
import SuccessIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Undo';
import SubmitIcon from '@material-ui/icons/Fingerprint';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const styles = theme => ({
    // Tos Field
    tos: {
        margin: '0 -7px 0 auto', // left align on side with rest of form
        '& label': {
            // flip label and checkbox
            margin: '0',
            flexDirection: 'row-reverse'
        }
    }
});

/**
 * New User Registration
 */
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasswords: false, // flag to display/hide passwords
            tosDialogOpen: false // open/close status of ToS dialog
        };
    };

    /**
     * In case of Token provided, confirm registration
     */
    componentDidMount() {
        if (this.props.token) {
            this.props.simplePost('confirm_registration', 'tokens', {token: this.props.token}, {setState: this.props.setAuthState, submissionError: false, ignore_403: true}).then((result) => {
                // handle invalid token
                if (this.props.authState === 'failed_confirm_registration') {
                    this.props.history.push('/registration');
                    this.props.addMessage({intl_id: 'register.error.invalid_token', type: 'error', path: '/registration'});
                } else {
                    // login User
                    this.props.loginProcedure(result).then(() => {
                        this.props.history.push('/');
                        this.props.addMessage({intl_id: 'register.success.login', path: '/'});
                    });
                }
            });
        }
    }

    /**
     * Make sure to reset Auth state
     */
    componentWillUnmount() {
        // we don't want to let something in state
        if (![null, 'logged', 'posted_registration_confirm'].includes(this.props.authState)) {
            this.props.setAuthState(null);
        }
    }

    render() {
        return <div>
            {['posting_confirm_registration', 'posted_confirm_registration'].includes(this.props.authState)
                ? <div>
                <SpaceDivider double />
                <LinearProgress />
                <SpaceDivider double />
            </div>
                : ['posted_registration', 'posted_registration_afterAnim'].includes(this.props.authState)
                ? <Card>
                <CardHeader
                    title={<FormattedMessage id='register.success.title' />}
                    subheader={<FormattedMessage id='register.success.subheader' />}
                    action={
                        <ActionButton iconButton disabled>
                            <SuccessIcon />
                        </ActionButton>
                    }
                />
                {this.props.authState === 'posted_registration'
                    ? <CardActionsLoader success={true}
                                         postAnimation={success => this.props.setAuthState('posted_registration_afterAnim')} />
                    : <CardActions>
                    <Button onClick={() => this.props.history.push('/')}>
                        <CancelIcon />
                        <FormattedMessage id='actions.back' />
                    </Button>
                </CardActions>}
            </Card>
                : <Card>
                <CardHeader
                    title={<FormattedMessage id='register.title' />}
                    subheader={<FormattedMessage id='register.subheader' />}
                    action={
                        <ActionButton iconButton disabled>
                            <RegisterIcon />
                        </ActionButton>
                    }
                />
                <CardContent>
                    <h6 className='nomargin'><FormattedMessage id='register.section.account' /></h6>
                    <Form onSubmit={this.props.handleSubmit}>
                        <Field name='email' type='email' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.email'})}*`} />
                        <SpaceDivider none />
                        <Field name='first_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.first_name'})}*`} />
                        <Field name='last_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.last_name'})}*`} />
                    </Form>

                    <h6 className='nobmargin'><FormattedMessage id='register.section.company' /></h6>
                    <Form onSubmit={this.props.handleSubmit}>
                        <Field name='company.name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.company.name'})}*`} />
                        <CompanyTypeField name='company.type' label={`${this.props.intl.formatMessage({id: 'register.form.fields.company.type'})}*`} />
                    </Form>

                    <h6 className='nobmargin'><FormattedMessage id='register.section.secure' /></h6>
                    <Form onSubmit={this.props.handleSubmit}>
                        <Field name='password' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.password'})}*`} />
                        <FieldWithIconHolder>
                            <Field withIcon name='password2' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'register.form.fields.password2'})}*`} />
                            <FieldIcon onClick={() => this.setState({showPasswords: !this.state.showPasswords})}>
                                {this.state.showPasswords ? <VisibilityOff /> : <Visibility />}
                            </FieldIcon>
                        </FieldWithIconHolder>
                    </Form>

                    <SpaceDivider double />

                    <Form onSubmit={this.props.handleSubmit}>
                        <MuiThemeProvider theme={altTheme}>
                            <Field name='tos' fieldType='Checkbox' color='primary'
                                   label={<FormattedMessage id='tosfield.description'
                                                            values={{link: <a href='#' className='hover-border'
                                                                              onClick={(e) => { e.preventDefault(); this.setState({tosDialogOpen: true}); }}>
                                                                <FormattedMessage id='tosfield.description.link' />
                                                            </a>}} />}
                                   className={this.props.classes.tos} />
                        </MuiThemeProvider>
                    </Form>
                    <ToSDialog open={this.state.tosDialogOpen} handleClose={() => this.setState({tosDialogOpen: false})} />
                </CardContent>
                {['posting_registration', 'failed_registration'].includes(this.props.authState)
                    ? <CardActionsLoader failure={this.props.authState === 'failed_registration'}
                                         postAnimation={success => this.props.setAuthState(null)} />
                    : <CardActions>
                    <Button onClick={() => this.props.history.push('/')}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    <Button variant='contained' color='secondary' type='submit' onClick={this.props.handleSubmit}>
                        <SubmitIcon />
                        <FormattedMessage id='register.form.submit' />
                    </Button>
                </CardActions>}
            </Card>}
        </div>;
    }
}

let validate = (data, props) => {
    const errors = {company: {}};
    let nested_company = data.company || {}; // nested fix

    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);

    validator.isNotNull(null, errors, 'company.name', nested_company.name);
    validator.isNotNull(null, errors, 'company.type', nested_company.type);

    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'register.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);

    validator.isTrue(
        props.intl.formatMessage({id: 'tosfield.error.required'}),
        errors, 'tos', data.tos
    );

    return errors;
};

Register = withStyles(styles)(Register);
Register = reduxForm({
    form: 'registerForm',
    validate,
    onSubmit: (values, dispatch, props) => (
        dispatch(simplePost('registration', 'accounts', values, {setState: props.setAuthState, conflictEmail: true}))
    )
})(Register);

Register = connect((state, props) => ({
    authState: state.auth.get('state'),
    token: props.match.params.token
}), (dispatch) => bindActionCreators({
    setAuthState,
    addMessage,
    simplePost,
    loginProcedure
}, dispatch))(Register);

export default injectIntl(withRouter(Register));
