'use strict';
import {constants} from 'reducers/users';
import {SubmissionError} from 'redux-form';
import {handleErrors, getErrorCode} from 'actions/errors';
import {setState, addMessage} from 'actions/app';
import {setUser} from 'actions/auth';
import {fetchCompany} from 'actions/company';

// todo: get rid of this, implement methods from shared.js

/**
 * Simple action creator to set Users to store
 *
 * @param data - raw list of Users models data or null
 */
export function setUsers(data) {
    return {
        type: constants.USER_USERS,
        data
    };
}

/**
 * Actually fetch Users to management
 */
export function reallyFetchUsers() {
    return (dispatch, getState) => {
        let state = getState();
        let client = getState().api.get('client');
        let url = state.company.getIn(['company', 'user_set']);

        return client.get(url, {ordering: 'created_at'}).then(result => {
            return dispatch(setUsers(result.data));
        }).catch(error => {
            return handleErrors('reallyFetchUsers', dispatch, getState, error, null);
        });
    };
}

/**
 * Check if we have company fetched (for link to users) and then run reallyFetchUsers()
 */
export function fetchUsers() {
    return (dispatch, getState) => {
        let state = getState();
        let company = state.company.get('company');

        dispatch(setState('fetching_users'));
        // load company if we didn't already
        if (company.get('id') === null) {
            return dispatch(fetchCompany()).then(() => {
                return dispatch(reallyFetchUsers());
            }).then(() => {
                return dispatch(setState(null));
            }).catch(error => {
                dispatch(setState(null));
                return handleErrors('fetchUsers', dispatch, getState, error, null);
            });
        } else {
            return dispatch(reallyFetchUsers()).then(() => {
                return dispatch(setState(null));
            }).catch(error => {
                dispatch(setState(null));
                return handleErrors('fetchUsers', dispatch, getState, error, null);
            });
        }
    };
}

/**
 * Removes User from Portal
 *
 * @param which - User model to delete
 */
export function deleteUser(which) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');
        let url = which.get('url');

        dispatch(setState('deleting_user', which.get('id')));
        client.delete(url).then(() => {
            // re-fetch users to update collection
            return dispatch(reallyFetchUsers());
        }).then(() => {
            return dispatch(setState(null));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('deleteUser', dispatch, getState, error, null);
        });
    };
}

/**
 * Update User profile
 *
 * @param data - Data containing all User fields
 */
export function updateProfile(data) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let url = state.auth.getIn(['user', 'url']);

        dispatch(setState('updating_profile'));
        return client.patch(url, data).then(result => {
            return dispatch(setUser(result.data));
        }).then(() => {
            // done
            return dispatch(setState('updated_profile')); // trigger success animation, loader will reset state
        }).catch(error => {
            dispatch(setState('failed_update_profile'));
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    // display field errors
                    throw new SubmissionError(error.response.data);
            }
            return handleErrors('updateProfile', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Confirm that User email is really Users
 *
 * @param data - Data containing confirmation token
 */
export function confirmEmailChange(data) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let account_link = state.auth.getIn(['user', 'url']);

        dispatch(setState('updating_profile_email'));
        return client.post('email-confirm', data).then(() => {
            // check if we are logged (we have account URL)
            if (account_link) {
                // re-fetch User account if we are logged (we have account link)
                return client.get(account_link).then(result => {
                    return dispatch(setUser(result.data));
                }).then(() => {
                    dispatch(setState(null));
                    // inform user about what we have done
                    return dispatch(addMessage({intl_id: 'users.detail.basic.emailconfirm.success', path: '/settings/profile'}));
                });
            } else {
                dispatch(setState(null));
                // inform user about what we have done
                return dispatch(addMessage({intl_id: 'users.detail.basic.emailconfirm.success', path: '/'}));
            }
        }).catch(error => {
            dispatch(setState(null));
            let error_code = getErrorCode(error);
            switch (error_code) {
                // invalid token
                case 400:
                    // inform user about it
                    return dispatch(addMessage({intl_id: 'users.detail.basic.emailconfirm.failure', type: 'error', path: account_link ? '/settings/profile' : '/'}));
            }
            return handleErrors('confirmEmailChange', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Update User profile picture (Avatar)
 *
 * @param avatar - New Image from DropFile component
 */
export function updateProfileAvatar(avatar) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let url = state.auth.getIn(['user', 'url']);

        dispatch(setState('updating_profile_avatar'));
        // upload new image
        return client.upload('files', avatar, {name: 'avatar', type: 'Image'}).then((result) => {
            // add image to User
            return client.patch(url, {avatar: result.data.url});
        }).then(result => {
            // update User and we are done
            return dispatch(setUser(result.data));
        }).then(() => {
            dispatch(setState(null));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('updateProfileAvatar', dispatch, getState, error, null);
        });
    };
}
