'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// Actions
import {executeRemoteAction} from 'actions/app';
// Components
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';


/**
 * Component to trigger remote action. Then redirects User to homepage
 */
class ConfirmEmail extends React.Component {
    /**
     * On initialization run remote action
     */
    componentDidMount() {
        this.props.executeRemoteAction({token: this.props.match.params.token}).then(() => {
            this.props.history.push('/');
        });
    }

    render() {
        return <div>
            {this.props.state === 'executing_remote_action' && <div>
                <SpaceDivider double />
                <LinearProgress />
                <SpaceDivider double />
            </div>}
        </div>;
    }
}

ConfirmEmail = connect((state) => ({
    state: state.app.get('state'),
}), (dispatch) => bindActionCreators({
    executeRemoteAction
}, dispatch))(ConfirmEmail);

export default withRouter(ConfirmEmail);
