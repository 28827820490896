'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormSubmitErrors} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {addMessage} from 'actions/app';
import {loginProcedure, setState as setAuthState} from 'actions/auth';
import {simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// icons
import ForgottenPasswordIcon from '@material-ui/icons/SettingsBackupRestore';
import SuccessIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Undo';
import Submit1Icon from '@material-ui/icons/Send';
import Submit2Icon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


/**
 * ForgottenPassword process
 *
 * First 'null' state -> Email form
 * Then 'reset_password_request' state -> Success message, visit inbox
 *
 * First 'null' state with token -> New password form
 * Then -> logged
 */
class ForgottenPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasswords: false, // flag to display/hide passwords
        };
    };

    /**
     * Make sure to reset Auth state
     */
    componentWillUnmount() {
        // we don't want to let something in state
        if (![null, 'logged', 'posted_reset_password'].includes(this.props.authState)) {
            this.props.setAuthState(null);
        }
    }

    /**
     * Ensure that we don't have invalid token in URL
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // in case of invalid token, remove token from URL
        if (this.props.token && this.props.submitErrors.token) {
            this.props.history.push('/forgotten-password');
        }
    }

    render() {
        return <div>
            {this.props.authState === 'posted_reset_password_request' || this.props.authState === 'posted_reset_password_request_afterAnim'
                ? <Card>
                <CardHeader
                    title={<FormattedMessage id='forgottenpassword.success.title' />}
                    subheader={<FormattedMessage id='forgottenpassword.success.subheader' />}
                    action={
                        <ActionButton iconButton disabled>
                            <SuccessIcon />
                        </ActionButton>
                    }
                />
                {this.props.authState === 'posted_reset_password_request'
                    ? <CardActionsLoader success={true}
                                         postAnimation={success => this.props.setAuthState('posted_reset_password_request_afterAnim')} />
                    : <CardActions>
                    <Button onClick={() => this.props.history.push('/')}>
                        <CancelIcon />
                        <FormattedMessage id='actions.back' />
                    </Button>
                </CardActions>}
            </Card>
                : <Card>
                <CardHeader
                    title={<FormattedMessage id='forgottenpassword.title' />}
                    subheader={!this.props.token
                        ? <FormattedMessage id='forgottenpassword.subheader.1' />
                        : <FormattedMessage id='forgottenpassword.subheader.2' />
                    }
                    action={
                        <ActionButton iconButton disabled>
                            <ForgottenPasswordIcon />
                        </ActionButton>
                    }
                />
                {!this.props.token
                    ? <CardContent>
                    {(this.props.submitFailed && this.props.submitErrors.token) && <React.Fragment>
                        <ErrorMessage><FormattedMessage id='forgottenpassword.error.invalid_token' /></ErrorMessage>
                        <SpaceDivider />
                    </React.Fragment>}
                    <Form onSubmit={this.props.handleSubmit} alignCenter>
                        <Field name='email' type='email' fieldType='TextField' label={<FormattedMessage id='forgottenpassword.form.fields.email' />} />
                    </Form>
                </CardContent>
                    : <CardContent>
                    <Form onSubmit={this.props.handleSubmit} alignCenter>
                        <Field name='password' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'forgottenpassword.form.fields.password'})}*`} />
                        <FieldWithIconHolder>
                            <Field withIcon name='password2' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'forgottenpassword.form.fields.password2'})}*`} />
                            <FieldIcon onClick={() => this.setState({showPasswords: !this.state.showPasswords})}>
                                {this.state.showPasswords ? <VisibilityOff /> : <Visibility />}
                            </FieldIcon>
                        </FieldWithIconHolder>
                    </Form>
                </CardContent>}
                {['posting_reset_password_request', 'posting_reset_password', 'posted_reset_password',
                    'failed_reset_password_request', 'failed_reset_password'].includes(this.props.authState)
                    ? <CardActionsLoader failure={['failed_reset_password_request', 'failed_reset_password'].includes(this.props.authState)}
                                         postAnimation={success => this.props.setAuthState(null)} />
                    : <CardActions>
                    <Button onClick={() => this.props.history.push('/')}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    {!this.props.token
                        ? <Button variant='contained' color='secondary' type='submit' onClick={this.props.handleSubmit}>
                        <Submit1Icon />
                        <FormattedMessage id='forgottenpassword.form.submit.1' />
                    </Button>
                        : <Button variant='contained' color='secondary' type='submit' onClick={this.props.handleSubmit}>
                        <Submit2Icon />
                        <FormattedMessage id='forgottenpassword.form.submit.2' />
                    </Button>}
                </CardActions>}
            </Card>}
        </div>;
    }
}

let validate = (data, props) => {
    const errors = {};

    if (!props.token) {
        validator.isNotNull(null, errors, 'email', data.email) &&
        validator.isEmail(null, errors, 'email', data.email);
    } else {
        validator.isNotNull(null, errors, 'password', data.password) &&
        validator.isNotNull(null, errors, 'password2', data.password2) &&
        validator.equals(
            props.intl.formatMessage({id: 'forgottenpassword.error.no_match'}),
            errors, 'password2',
            data.password, data.password2);
    }

    return errors;
};

ForgottenPassword = reduxForm({
    form: 'forgottenPasswordForm',
    validate,
    onSubmit: (values, dispatch, props) => (
        !props.token
            ? dispatch(simplePost('reset_password_request', 'reset-password', {email: values.email}, {post_method: 'delete', setState: props.setAuthState}))
            : dispatch(simplePost('reset_password', 'reset-password', {token: props.token, id: props.id, password: values.password}, {setState: props.setAuthState})).then((result) =>
            dispatch(loginProcedure(result)).then(() => {
                props.history.push('/dashboard');
                return dispatch(addMessage({intl_id: 'forgottenpassword.success.login', path: '/dashboard'}));
            })
        )
    )
})(ForgottenPassword);

ForgottenPassword = connect((state, props) => ({
    authState: state.auth.get('state'),
    submitErrors: getFormSubmitErrors('forgottenPasswordForm')(state),
    token: props.match.params.token,
    id: props.match.params.id
}), (dispatch) => bindActionCreators({
    setAuthState,
    addMessage,
    simplePost,
    loginProcedure
}, dispatch))(ForgottenPassword);

export default injectIntl(withRouter(ForgottenPassword));
