'use strict';
import React from 'react';

/**
 * Add this component at the start of Form where you don't want to use AutoComplete.
 * It looks strange, but it works and it because of chrome, read more:
 * https://stackoverflow.com/questions/12374442/chrome-browser-ignoring-autocomplete-off/38961567#38961567
 */
class AutoCompleteOff extends React.Component {
    render() {
        return <div style={{display: 'none'}}>
            <input type='email' name='email' />
            <input type='password' name='password' autoComplete='new-password' />
        </div>;
    }
}

export default AutoCompleteOff;
