'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Platinum plate icon
 */
export default class Platinum extends React.Component {
    static defaultProps = {
        viewBox: '0 0 512 512',
        color1: '#d5d5d5',
        color2: '#7e7e7e',
        color3: '#a2a2a2',
        color4: '#c5c5c5',
        color5: '#8f8f8f'
    };

    render() {
        let {color1, color2, color3, color4, color5, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <path style={{fill: color1}} d='M376.182,32.203H135.818c-5.586,0-10.748,2.98-13.541,7.818L2.095,248.182 c-2.793,4.838-2.793,10.798,0,15.636L122.277,471.98c2.793,4.838,7.955,7.818,13.541,7.818h240.364 c5.586,0,10.748-2.98,13.541-7.818l120.182-208.162c2.793-4.838,2.793-10.798,0-15.636L389.723,40.021 C386.93,35.183,381.768,32.203,376.182,32.203z' />
            <path style={{fill: color1}} d='M179.24,123.047L128,34.297c-2.338,1.35-4.327,3.304-5.723,5.723L2.095,248.183 C0.698,250.601,0,253.301,0,256h102.479L179.24,123.047z' />
            <path style={{fill: color2}} d='M512,256H409.521L332.76,388.953L384,477.702c2.338-1.35,4.327-3.304,5.723-5.723l120.182-208.162 C511.302,261.399,512,258.699,512,256z' />
            <path style={{fill: color3}} d='M332.76,388.953H179.24L128,477.702c2.338,1.35,5.025,2.095,7.818,2.095h240.364 c2.793,0,5.48-0.745,7.818-2.095L332.76,388.953z' />
            <path style={{fill: color3}} d='M332.76,123.047L409.521,256H512c0-2.699-0.698-5.399-2.095-7.817L389.723,40.02 c-1.396-2.419-3.385-4.373-5.723-5.723L332.76,123.047z' />
            <path style={{fill: color4}} d='M179.24,388.953L102.479,256H0c0,2.699,0.698,5.399,2.095,7.818L122.277,471.98 c1.397,2.419,3.385,4.373,5.723,5.723L179.24,388.953z' />
            <path style={{fill: color4}} d='M179.24,123.047h153.52L384,34.297c-2.338-1.35-5.025-2.095-7.818-2.095H135.818 c-2.793,0-5.48,0.745-7.818,2.095L179.24,123.047z' />
            <polygon style={{fill: color5}} points='332.761,123.047 179.239,123.047 102.479,256 179.239,388.953 332.761,388.953 409.521,256' />
        </SvgIcon>;
    }
}
