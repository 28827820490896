'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Icon representing general File
 */
export default class Archive extends React.Component {
    static defaultProps = {
        viewBox: '0 0 56 56'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: theme.palette.grey[300]}} points='51.5,14 37.5,0 6.5,0 6.5,58 51.5,58' />
            <polygon style={{fill: theme.palette.grey[500]}} points='37.5,0 37.5,14 51.5,14' />
        </svg>;
    }
}
