'use strict';
import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Silver coin icon
 */
export default class Silver extends React.Component {
    static defaultProps = {
        viewBox: '-3 -3 62 62', // original '0 0 56 56'
        color1: '#CCCCCC',
        color2: '#C0C0C0',
        color3: '#ACACAC',
        color4: '#9A9A9A'
    };

    render() {
        let {color1, color2, color3, color4, ...rest_of_props} = this.props;

        return <SvgIcon {...rest_of_props}>
            <path style={{fill: color1}} d='M31,56C17.469,56,6.5,43.464,6.5,28S17.469,0,31,0c13.531,0,24.5,12.536,24.5,28 S44.531,56,31,56' />
            <path style={{fill: color1}} d='M31,52c-11.304,0-20.5-10.766-20.5-24S19.696,4,31,4c11.304,0,20.5,10.766,20.5,24 S42.304,52,31,52' />
            <path style={{fill: color2}} d='M6.5,28C6.5,13.698,15.885,1.908,28,0.216C27.016,0.079,26.016,0,25,0 C11.469,0,0.5,12.536,0.5,28S11.469,56,25,56c1.016,0,2.016-0.079,3-0.216C15.885,54.092,6.5,42.302,6.5,28' />
            <path style={{fill: color3}} d='M31,53C19.145,53,9.5,41.785,9.5,28S19.145,3,31,3s21.5,11.215,21.5,25 S42.855,53,31,53 M31,5C20.248,5,11.5,15.318,11.5,28c0,12.682,8.748,23,19.5,23s19.5-10.318,19.5-23C50.5,15.318,41.752,5,31,5' />
            <path style={{fill: color4}} d='M6.498,29H0.501c0.02,0.673,0.058,1.34,0.119,2h6.003 C6.56,30.34,6.519,29.673,6.498,29' />
            <path style={{fill: color4}} d='M6.727,24H0.731c-0.083,0.659-0.143,1.327-0.184,2h5.999 C6.588,25.327,6.644,24.659,6.727,24' />
            <path style={{fill: color4}} d='M7.048,34H1.047c0.13,0.676,0.28,1.343,0.451,2h5.999 C7.327,35.343,7.178,34.676,7.048,34' />
            <path style={{fill: color4}} d='M8.447,39H2.443c0.255,0.682,0.537,1.347,0.837,2h6.001 C8.981,40.348,8.702,39.681,8.447,39' />
            <path style={{fill: color4}} d='M7.772,19H1.779c-0.195,0.657-0.377,1.322-0.529,2h6.007 C7.409,20.324,7.578,19.655,7.772,19' />
            <path style={{fill: color4}} d='M9.764,14h-6c-0.33,0.651-0.641,1.317-0.925,2h6.004 C9.127,15.318,9.434,14.651,9.764,14' />
            <path style={{fill: color4}} d='M13.845,8H7.838c-0.569,0.636-1.11,1.303-1.622,2h6.005 C12.734,9.303,13.276,8.636,13.845,8' />
            <path style={{fill: color4}} d='M19.96,3h-6.013c-1.022,0.59-2.002,1.258-2.936,2h6.009 C17.955,4.258,18.937,3.59,19.96,3' />
            <path style={{fill: color4}} d='M11.519,45H5.516c0.464,0.693,0.954,1.36,1.472,2h6.007 C12.477,46.36,11.984,45.693,11.519,45' />
            <path style={{fill: color4}} d='M9.832,50c0.803,0.723,1.644,1.394,2.524,2h6.014c-0.884-0.607-1.734-1.274-2.54-2 H9.832z' />
        </SvgIcon>;
    }
}
