'use strict';
import {constants} from 'reducers/shared';
import {SubmissionError} from 'redux-form';
import {parseLinkHeader} from 'lib/util';
import {fromJS} from 'immutable';
import {handleErrors, getErrorCode} from 'actions/errors';
import {updateClientLinks} from 'actions/api';
import {setState, addMessage} from 'actions/app';
import {setUser} from 'actions/auth';
import {User} from 'lib/models';


/**
 * Simple action creator to store fetched Items in Collection
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param items - items to set
 * @param {boolean} update - should we replace collection from scratch or just update it
 * @param paginator - optional RAW data for Paginator
 */
export function setCollection(model, placement, items, update = false, paginator = null) {
    return {
        type: constants.SHARED_SET_COLLECTION,
        model,
        placement,
        items,
        update,
        paginator
    };
}

/**
 * In case we are moving backward or forward (in already fetched Pages), we need to update Paginator
 *
 * @param paginator - RAW data for Paginator
 * @param placement - placement of Paginator in store
 */
export function updatePaginator(paginator, placement) {
    return {
        type: constants.SHARED_UPDATE_PAGINATOR,
        paginator,
        placement
    };
}

/**
 * Simple action creator to remove single Item from Collection
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param item - items to remove
 */
export function removeFromCollection(model, placement, item) {
    return {
        type: constants.SHARED_REMOVE_FROM_COLLECTION,
        model,
        placement,
        item
    };
}

/**
 * Simple action creator to mark that Items in Collection were Filtered/Ordered/Whatever and needs reload
 * it simply turns 'loaded' into false
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 */
export function markFiltered(model, placement) {
    return {
        type: constants.SHARED_FILTERED,
        model,
        placement
    };
}

/**
 * Simple action creator to mark that Items in Collection are no longer up-to-date and therefore should reload
 * Used by Websocket
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 */
export function markOutdated(model, placement) {
    return {
        type: constants.SHARED_OUTDATED,
        model,
        placement
    };
}

/**
 * Fetches Links for specific URL and updates Client.js link map, e.g. 'tables'
 *
 * @param placement - placement in link map, e.g. 'statistics'
 * @param url       - URL to Link map
 * @param options   - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_links_tables'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 */
export function fetchLinks(placement, url, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // process options and it's defaults
        let state_name = options.state_name !== undefined ? options.state_name : placement;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let success_state = options.success_state !== undefined ? options.success_state : null;

        if (affect_state) {
            dispatch(setState(`fetching_links_${state_name}`));
        }
        return client.get(url).then(result => {
            return dispatch(updateClientLinks(placement, result.data));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(null));
            }
            return handleErrors(`fetchLinks-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch Items from backend
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param ordering - optional ordering of request, e.g. 'name'
 * @param filter - optional filter, e.g. '{type: 'events.gcal'}'
 * @param options - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_items_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  paginate                - default: false; is request paginated?
 *  paginator_page          - default: 1; which Page we are fetching?
 */
export function fetchItems(model, placement, url, ordering = null, filter = null, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // process options and it's defaults
        let state_name = options.state_name !== undefined ? options.state_name : placement;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let success_state = options.success_state !== undefined ? options.success_state : null;
        let paginate = options.paginate !== undefined ? options.paginate : false;
        let paginator_page = options.paginator_page !== undefined ? options.paginator_page : 1;

        let qparams = {};
        if (ordering) {
            qparams['ordering'] = ordering;
        }
        if (filter) {
            qparams = {...qparams, ...filter};
        }

        // TODO: remove this when backend ready
        if (url === 'projects') {
            dispatch(setCollection(model, placement, [
                {id: '1', code: 'DR6541-6468', name: 'Big order', created_at: '2018-04-12 16:18:02.600797+02', completion_date: '2019-05-03 12:00:00.000000+02', user_name: 'Jakub Randák', user_id: '09e99338-aaf4-4739-a090-3b84bd8a36e2', company_name: 'Johny', company_id: '6f0b2e3d-2978-464b-823c-bb75ba313fcd'},
                {id: '2', code: 'DR6542-7868', name: 'Small order', created_at: '2018-06-12 13:12:56.780806+02', completion_date: '2019-02-01 12:00:00.000000+02', user_name: 'John Doe', user_id: '93232506-28d8-443e-8de0-e0283b59a990', company_name: 'Johny', company_id: '6f0b2e3d-2978-464b-823c-bb75ba313fcd'},
            ]));
            return new Promise((resolve, reject) => resolve(true));
        }

        if (affect_state) {
            dispatch(setState(`fetching_items_${state_name}`));
        }
        return client.get(url, qparams).then(result => {
            let paginator_data = null;
            if (paginate) {
                // get links from header
                paginator_data = result.headers.link ? parseLinkHeader(result.headers.link) : {};
                // set new page
                paginator_data['page'] = paginator_page;
                // set maximum loaded page (we expect, that we are fetching new records only)
                paginator_data['maxLoadedPage'] = paginator_page;
            }
            return dispatch(setCollection(model, placement, result.data || [], false, paginator_data));
        }).then(() => {
            if (filter !== null) {
                dispatch(markFiltered(model, placement));
            }
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(null));
            }
            return handleErrors(`fetchItems-${state_name}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch specific Items from backend
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. ['pages', id] or specific URL
 * @param options - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_item_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  failure_state           - default: null; what should be set after failure fetch as state
 *  expand_item             - default: null; item which should be expanded with additional data
 *  expand_item_data        - default: false; fetched data are data for Item
 *  company                 - default: null; item should belong to this Company
 */
export function fetchItem(model, placement, url, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');
        let item_object = null; // it's here to get rid of eslint no-undef warning

        // process options and it's defaults
        let state_name = options.state_name !== undefined ? options.state_name : placement;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let success_state = options.success_state !== undefined ? options.success_state : null;
        let failure_state = options.failure_state !== undefined ? options.failure_state : null;
        let expand_item = options.expand_item !== undefined ? options.expand_item : null;
        let expand_item_data = options.expand_item_data !== undefined ? options.expand_item_data : false;
        let company = options.company !== undefined ? options.company : null;

        if (affect_state) {
            dispatch(setState(`fetching_item_${state_name}`));
        }
        return client.get(url).then(result => {
            item_object = expand_item_data ? {data: result.data} : result.data;
            // check if we are expanding existing item
            if (expand_item) {
                item_object = {...expand_item.toObject(), ...item_object};
            }
            // check if item shares the same Company
            if (company && company.getIn(['links', 'self']) !== result.data.links.company) {
                if (affect_state) {
                    return dispatch(setState(failure_state));
                }
            }
            return dispatch(setCollection(model, placement, [item_object], true));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).then(() => {
            return fromJS(item_object); // return data (so we can work with them)
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(failure_state));
            }
            let error_code = getErrorCode(error);
            switch (error_code) {
                // detail not found, we don't need to do anything
                case 404:
                    return false;
            }
            return handleErrors(`fetchItem-${state_name}`, dispatch, getState, error, error_code);
        });
    };
}

/**
 * Add or Update provided item.
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param data - new data
 * @param item - do we already have item? (so editing not adding)
 * @param options - {} contains optional options
 *  new_at_bottom       - default: false; is it correct to place new item to bottom of the Collection? Otherwise marks collection filtered to trigger loader
 *  state_name          - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state        - default: true; should method affect state with e.g. 'saving_item_pages'
 *  update_method       - default: 'patch'; which method should be used during update (patch / put)
 *  save_method         - default: 'post'; which method should be used during create (post / put)
 *  error_field_prefix  - default: null; should we prefix submission errors? e.g. 'firmware' will change 'version' -> 'firmware.version'
 *  ignore_404          - default: false; should we ignore #404 error? (promise will return false)
 *  ignore_403          - default: false; should we ignore #403 error? (promise will return false)
 *  qparams             - default: {}; optional query params appended to the url
 */
export function saveItem(model, placement, url, data, item = null, options = {}) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let result_data = null; // it's here to get rid of eslint no-undef warning

        // process options and it's defaults
        let new_at_bottom = options.new_at_bottom !== undefined ? options.new_at_bottom : false;
        let state_name = options.state_name !== undefined ? options.state_name : placement;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let update_method = options.update_method !== undefined ? options.update_method : 'patch';
        let save_method = options.save_method !== undefined ? options.save_method : 'post';
        let error_field_prefix = options.error_field_prefix !== undefined ? options.error_field_prefix : null;
        let ignore_404 = options.ignore_404 !== undefined ? options.ignore_404 : false;
        let ignore_403 = options.ignore_403 !== undefined ? options.ignore_403 : false;
        let qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setState(`saving_item_${state_name}`));
        }
        let client_promise = item
            ? update_method === 'put' ? client.put(url, data, qparams) : client.patch(url, data, qparams)
            : save_method === 'put' ? client.put(url, data, qparams) : client.post(url, data, qparams);
        return client_promise.then(result => {
            result_data = result.data;
            // if client is editing himself (logged user), we also have to store it to auth app
            if (item && model === User && state.auth.getIn(['user', 'username']) === result_data.username) {
                dispatch(setUser({...result_data, admin_access: state.auth.getIn(['user', 'admin_access'])}));
            }
            return dispatch(setCollection(model, placement, [result_data], true));
        }).then(() => {
            // create and new is not at the bottom (because it doesn't use local sorting)
            if (!item && new_at_bottom === false) {
                dispatch(markFiltered(model, placement));
            }
            if (affect_state) {
                dispatch(setState(`saved_item_${state_name}`)); // trigger success animation, loader will reset state
            }
            return fromJS(result_data); // return data (so we can work with them)
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(`failed_save_item_${state_name}`)); // trigger failure animation, loader will reset state
            }
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    if (ignore_404) {
                        return false;
                    } else {
                        let submission_errors = error.response.data.details;
                        if (error_field_prefix) {
                            submission_errors = {[error_field_prefix]: submission_errors};
                        }
                        // display field errors
                        throw new SubmissionError(submission_errors);
                    }
                case 403:
                    if (ignore_403) {
                        return false;
                    }
                    break;
            }
            return handleErrors(`saveItem-${state_name}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Add Image to model (e.g. User avatar, Company logo)
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param item - item we are patching
 * @param image - uploaded image
 * @param image_name - name of image
 * @param fieldName - field name, e.g. 'avatar'
 */
export function saveItemImage(model, placement, url, item, image, image_name, fieldName) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        dispatch(setState(`saving_item_image_${placement}`));
        // upload new image
        return client.upload('files', image, {name: image_name, type: 'Image'}).then((result) => {
            // add image to Model
            return client.patch(url, {[fieldName]: result.data.url});
        }).then(result => {
            // update Model and we are done
            return dispatch(setCollection(model, placement, [result.data], true));
        }).then(() => {
            dispatch(setState(null));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors(`saveItemImage-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Delete specific item
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages-order'
 * @param item - Item to be deleted
 * @param options - {} contains optional options
 *  error_message_intl      - default: null; If provided, error message will be displayed when #400 occurs
 *  affect_state            - default: true; should method affect state with e.g. 'deleting_item_pages'
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  failure_state           - default: null; what should be set after failure fetch as state
 *  qparams         - default: {}; optional query params appended to the url
 */
export function deleteItem(model, placement, url, item, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // process options and it's defaults
        let error_message_intl = options.error_message_intl !== undefined ? options.error_message_intl : null;
        let state_name = options.state_name !== undefined ? options.state_name : placement;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let success_state = options.success_state !== undefined ? options.success_state : null;
        let failure_state = options.failure_state !== undefined ? options.failure_state : null;
        let qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setState(`deleting_item_${state_name}`, item.get('id')));
        }
        return client.delete(url, qparams).then(() => {
            return dispatch(removeFromCollection(model, placement, item));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(failure_state));
            }
            let error_code = getErrorCode(error);
            switch (error_code) {
                // something failed (deletion blocked)
                case 400:
                    if (error_message_intl) {
                        dispatch(addMessage({intl_id: error_message_intl, type: 'error', path: 'on-change'}));
                        return false;
                    }
            }
            return handleErrors(`deleteItem-${placement}`, dispatch, getState, error, error_code);
        });
    };
}

/**
 * Delete multiple items in same placement
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param items - Item to be deleted
 * @param options - {} contains optional options
 *  error_message_intl   - default: null; If provided, error message will be displayed when #400 occurs
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_links_tables'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 */
export function deleteItems(model, placement, items, options = {}) {
    return (dispatch, getState) => {
        // process options and it's defaults
        let error_message_intl = options.error_message_intl !== undefined ? options.error_message_intl : null;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let success_state = options.success_state !== undefined ? options.success_state : null;

        if (affect_state) {
            dispatch(setState(`deleting_items_${placement}`));
        }
        // prepare promises
        let promises = [];
        for (let item of items) {
            promises.push(dispatch(deleteItem(model, placement, item.get('url'), item, {affect_state: false, error_message_intl: error_message_intl})));
        }

        return Promise.all(promises).then(results => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(null));
            }
            return handleErrors(`deleteItems-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Change order of Items
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages-order'
 * @param orderedItems - ordered list of Items
 * @param options - {} contains optional options
 *  sortly      - default: false; this is nested sortly reorder
 */
export function reorderItems(model, placement, url, orderedItems, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // process options and it's defaults
        let sortly = options.sortly !== undefined ? options.sortly : false;

        // prepare data
        let data = orderedItems;
        if (!sortly) {
            data = [];
            // get list of ids for post request
            orderedItems.map(el => {
                data.push(sortly ? el.url : el.get('url'));
            });
        }

        dispatch(setState(`ordering_items_${placement}`));
        let client_promise = sortly ? client.patch(url, data) : client.post(url, data);
        return client_promise.then(() => {
            return dispatch(setCollection(model, placement, orderedItems));
        }).then(() => {
            return dispatch(setState(`ordered_items_${placement}`)); // trigger success animation, loader will reset state
        }).catch(error => {
            dispatch(setState(`failed_order_items_${placement}`)); // trigger failure animation, loader will reset state
            return handleErrors(`reorderItems-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Simple POST of data to API
 *
 * @param placement - name of state, e.g. 'invite' => 'posting_invite', 'posted_invite', 'failed_invite'
 * @param url - URL where to post
 * @param data - Data from Form
 * @param options - {} contains optional options
 *  post_method             - default: 'post'; which method should be used to POST data
 *  setState                - default: app/setState; which setState action should be used, could be from AUTH
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_item_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  submissionError         - default: true; returns submissionsError if response error_code is 400, otherwise returns false
 *  conflictEmail           - default: false; returns submissionError to email if response error_code is 409
 *  ignore_403              - default: false; should we ignore #403 error?
 *  qparams                 - default: {}; optional query params appended to the url
 */
export function simplePost(placement, url, data, options = {}) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // process options and it's defaults
        let post_method = options.post_method !== undefined ? options.post_method : 'post';
        let setStateMethod = options.setState !== undefined ? options.setState : setState;
        let affect_state = options.affect_state !== undefined ? options.affect_state : true;
        let success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        let submissionError = options.submissionError !== undefined ? options.submissionError : true;
        let conflictEmail = options.conflictEmail !== undefined ? options.conflictEmail : false;
        let ignore_403 = options.ignore_403 !== undefined ? options.ignore_403 : false;
        let qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setStateMethod(`posting_${placement}`));
        }
        let client_promise = post_method === 'delete'
            ? client.delete(url, data, qparams)
            : post_method === 'put'
                ? client.put(url, data, qparams)
                : post_method === 'patch'
                    ? client.patch(url, data, qparams)
                    : client.post(url, data, qparams);
        return client_promise.then((result) => {
            if (affect_state && success_affect_state) {
                dispatch(setStateMethod(`posted_${placement}`)); // trigger success animation, loader will reset state
            }
            return result; // returns result
        }).catch(error => {
            if (affect_state) {
                dispatch(setStateMethod(`failed_${placement}`)); // trigger failure animation, loader will reset state
            }
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    if (submissionError) {
                        // display field errors
                        throw new SubmissionError(error.response.data.details);
                    } else {
                        return false;
                    }
                case 403:
                    if (ignore_403) {
                        return false;
                    }
                    break;
                case 409:
                    if (conflictEmail) {
                        // User with the same email address already exists
                        throw new SubmissionError({email: error.response.data.error}); // assign error to email field
                    } else {
                        break;
                    }
            }
            return handleErrors(`simplePost-${placement}`, dispatch, getState, error, error_code);
        });
    };
}
