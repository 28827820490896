'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        // red background, white text
        background: theme.palette.danger['main'],
        color: theme.palette.common.white,
        // card like
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        // proportion
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px auto`,
        textAlign: 'center',
        '&.center': {
            width: '320px' // limit to Field size
        }
    }
});

/**
 * Error message used to display non_field_errors in redux-form
 */
class ErrorMessage extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, className, center, ...rest_of_props} = this.props;
        let rootClasses = [classes.root, className, center ? 'center' : ''].filter(Boolean).join(' ');

        return <div className={rootClasses} {...rest_of_props} />;
    }
}

ErrorMessage = withStyles(styles)(ErrorMessage);
export default ErrorMessage;
