'use strict';
import {Map, List} from 'immutable';
import keymirror from 'keymirror';
import {listAsModel, asModel, portletListAsModel,
    Paginator, PagePortlet, Folder} from 'lib/models';


export const constants = keymirror({
    SHARED_SET_COLLECTION: null,
    SHARED_UPDATE_PAGINATOR: null,
    SHARED_REMOVE_FROM_COLLECTION: null,
    SHARED_FILTERED: null,
    SHARED_OUTDATED: null
});

let defaults = Map({
    loaded: Map({
        'pages': false
    }),
    outdated: Map({}), // might contain same records as 'loaded'
    paginator: Map({
        'paginated': new Paginator() // not used yet
    }),
    items: Map({
        'pages': List(),
        'files': List(),
        'folders': List()
        // folders-${folder_id}
        // files-${folder_id}
        // portlets-${page_slug}
        // portlet-${portlet_id}-related
    })
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets items/removes to store
        case constants.SHARED_SET_COLLECTION:
            // get proper listAsModel function
            let listAsModelFunction = listAsModel;
            if (action.model === PagePortlet) {
                // PagePortlet need different listAsModel function
                listAsModelFunction = portletListAsModel;
            } else if (action.model === Folder) {
                // Folders are locally sorted by name for now (and not how they are sorted in tree structure)
                action.items.sort((a, b) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
            }

            // implementation of paginator
            if (action.paginator) {
                // set new paginator
                // update parent collection (e.g. 'users' without page)
                // marks loaded as 'true'
                // add new Page (e.g. 'users-1')
                return state.setIn(['paginator', action.placement], asModel(Paginator, action.paginator))
                    .setIn(['items', action.placement], List(state.getIn(['items', action.placement], List()).reduce((result, el) => result.set(el.get('id'), el), Map()).merge(listAsModelFunction(action.model, action.items).reduce((result, el) => result.set(el.get('id'), el), Map())).values()))
                    .setIn(['loaded', action.placement], true).setIn(['outdated', action.placement], false)
                    .setIn(['items', `${action.placement}-${action.paginator.page}`], listAsModelFunction(action.model, action.items));
            }

            // now update / set / reset collection
            return action.model && action.placement && action.items
                ? action.update
                    // update list (for detail, pagination, etc.) - updates current IDs and add new missing items
                    // magic happening here: https://stackoverflow.com/a/43658124
                    ? state.setIn(['items', action.placement], List(state.getIn(['items', action.placement], List()).reduce((result, el) => result.set(el.get('id'), el), Map()).merge(listAsModelFunction(action.model, action.items).reduce((result, el) => result.set(el.get('id'), el), Map())).values()))
                    // create list, mark loaded as 'true'
                    : state.setIn(['items', action.placement], listAsModelFunction(action.model, action.items)).setIn(['loaded', action.placement], true).setIn(['outdated', action.placement], false)
                : state.set('items', defaults.get('items')).set('loaded', defaults.get('loaded')).set('outdated', defaults.get('outdated'));

        // update paginator
        case constants.SHARED_UPDATE_PAGINATOR:
            return action.paginator && action.placement
                ? state.setIn(['paginator', action.placement], asModel(Paginator, action.paginator))
                : state.set('paginator', defaults.get('paginator'));

        // removes single item from collection
        case constants.SHARED_REMOVE_FROM_COLLECTION:
            return state.setIn(['items', action.placement], List(state.getIn(['items', action.placement]), List()).filter(el => el.get('id') !== action.item.get('id')));

        // turns 'loaded' into false. It is indicator for 'needs reload'
        case constants.SHARED_FILTERED:
            return state.setIn(['loaded', action.placement], false);

        // turns 'outdated' into true. It is indicator for 'needs reload'
        case constants.SHARED_OUTDATED:
            return state.setIn(['outdated', action.placement], true);

        default:
            return state;
    }
};
