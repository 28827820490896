'use strict';
import React from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues, FieldArray} from 'redux-form';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
import Moment from 'moment';
// Actions
import {setFile} from 'actions/files';
// Components
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import DropFile from 'components/core/ui/DropFile';
import StickyTable from 'components/core/ui/StickyTable';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import Button from 'components/core/ui/mui/Button';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import CardActions from 'components/core/ui/mui/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
// icons
import SubmissionIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import StatusApprovedIcon from '@material-ui/icons/Check';
import StatusPendingIcon from '@material-ui/icons/MoreHoriz';
import SaveIcon from '@material-ui/icons/Save';
import NextIcon from '@material-ui/icons/ChevronRight';
import BackIcon from '@material-ui/icons/ChevronLeft';
import CancelIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Close';


const styles = theme => ({
    // Icon of Submission in List
    statusIcon: {
        '&.approved': {
            color: theme.palette.success[500]
        },
        '&.pending': {
            color: theme.palette.primary[500]
        },
        '&.declined': {
            color: theme.palette.danger[500]
        }
    },
    productAmountField: {
        // lower the size (max. 1000 number)
        width: '75px'
    },
    overviewHolder: {
        background: theme.palette.grey[100], // highlight
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        // position (full size wrap)
        position: 'absolute',
        right: `-${theme.spacing(2)}px`, // to side
        left: `-${theme.spacing(1)}px`,
        top: '0',
        bottom: '0',
        // correct padding
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2) + 57}px`, // 57px is overviewTotal space
        // responsive
        [theme.breakpoints.down('sm')]: {
            borderLeft: 'none',
            // position
            left: `-${theme.spacing(2)}px`, // to side
        }
    },
    // Total € at the bottom of overview
    overviewTotal: {
        position: 'absolute',
        bottom: `${theme.spacing(2)}px`,
        left: `${theme.spacing(2)}px`,
        right: `${theme.spacing(2)}px`
    },
    // Invoice upload
    dropFileRoot: {
        height: '100px',
        maxWidth: '320px', // same as field
        marginLeft: '0' // left side align
    },
    dropFileContentHolder: {
        paddingBottom: '0',
        height: '100%'
    },
    // confirmation overview
    confirmOverviewHolder: {
        '& $overviewHolder': {
            position: 'static',
            padding: '0',
            border: 'none',
            background: 'none',
            // hide overview text
            '& p:first-child': {
                display: 'none'
            }
        },
        '& $overviewTotal': {
            position: 'static',
            '& hr': {
                background: 'transparent'
            }
        }
    }
});

/**
 * Submissions portlet, renders table of Submissions and allow creating new one
 */
class Submissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null, // null, 'add', this.props.item
            activeStep: 0,
            sortedItems: [], // stored this.props.items in sorted order
            sortName: 'created_at', // default value of sorting
            sortDirection: 'asc', // default direction of sorting ('desc' / 'asc')
            tableHead: [
                {sortName: 'name', label: <FormattedMessage id='portlets.submissions.list.table.name' />},
                {sortName: 'created_at', label: <FormattedMessage id='portlets.submissions.list.table.created_at' />},
                {sortName: 'status', label: <FormattedMessage id='portlets.submissions.list.table.status' />},
                {sortName: 'rebate', label: <FormattedMessage id='portlets.submissions.list.table.rebate' />, numeric: true}
            ]
        };
    }

    /**
     * During initialization fetch and initially sort items
     */
    componentDidMount() {
        this.sortItems(this.state.sortName, true);
    }

    /**
     * Locally sort Items by sortName
     *
     * @param sortName - field to sort with
     * @param first_time - First time sorting? Let's get items from props and not state
     */
    sortItems(sortName, first_time = false) {
        let sortDirection = this.state.sortDirection;
        // no sortName change? switch direction
        if (!first_time && this.state.sortName === sortName) {
            sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }
        // sort items
        let sortedItems = first_time ? this.props.items.toArray() : this.state.sortedItems;
        sortedItems = sortedItems.slice().sort((a, b) => {
            let a_field = typeof a.get(sortName) === 'string' ? a.get(sortName).toLowerCase() : a.get(sortName);
            let b_field = typeof b.get(sortName) === 'string' ? b.get(sortName).toLowerCase() : b.get(sortName);
            if (a_field < b_field) {
                return sortDirection === 'desc' ? -1 : 1;
            } else if (a_field > b_field) {
                return sortDirection === 'desc' ? 1 : -1;
            } else {
                return 0;
            }
        });
        // set to state
        this.setState({sortedItems: sortedItems, sortName: sortName, sortDirection: sortDirection});
    }

    /**
     * Overview of Selected Products and it's prices
     */
    renderOverview() {
        return <div className={this.props.classes.overviewHolder}>
            <Typography variant='body1'><FormattedMessage id='portlets.submissions.detail.phones.overview.title' /></Typography>
            <SpaceDivider />
            <Table size={1}>
                <TableHead>
                    <TableRow>
                        <TableCell><FormattedMessage id='portlets.submissions.detail.phones.overview.table.product' /></TableCell>
                        <TableCell numeric><FormattedMessage id='portlets.submissions.detail.phones.overview.table.amount' /></TableCell>
                        <TableCell numeric><FormattedMessage id='portlets.submissions.detail.phones.overview.table.price' /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.formValues && this.props.formValues.products && this.props.formValues.products.length
                        ? this.props.formValues.products.map((product, idx) => (product && product.product) && <TableRow key={idx}>
                        <TableCell>{product.product && product.product}</TableCell>
                        <TableCell numeric>{product.amount && product.amount}</TableCell>
                        <TableCell numeric>{product.amount ? (250 * product.amount) : 0} €</TableCell>
                    </TableRow>)
                        : <TableRow>
                        <TableCell colSpan={3}><FormattedMessage id='portlets.submissions.detail.phones.overview.table.empty' /></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            <div className={this.props.classes.overviewTotal}>
                <hr />
                <Typography variant='body1' className='text-right'>
                    <FormattedMessage id='portlets.submissions.detail.phones.overview.total' values={{value: this.getTotalPrice()}} />
                </Typography>
            </div>
        </div>;
    }

    /**
     * Render FieldArray for Products
     *
     * @param fields - field_name in FieldArray, used to render N*Products
     */
    renderProducts = ({fields}) => {
        return <React.Fragment>
            {fields.map((field, idx) => <React.Fragment key={idx}>
                <Tooltip title={<FormattedMessage id='actions.remove' />}>
                    <IconButton onClick={() => fields.remove(idx)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Field name={`${field}.amount`} type='number' min='1' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'portlets.submissions.detail.phones.form.fields.products.amount'})}*`}
                       className={this.props.classes.productAmountField} />
                <Field name={`${field}.product`} fieldType='Select' label={`${this.props.intl.formatMessage({id: 'portlets.submissions.detail.phones.form.fields.products.product'})}*`}>
                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                    <MenuItem value='D765'>D765</MenuItem>
                    <MenuItem value='D725'>D725</MenuItem>
                    <MenuItem value='D745'>D745</MenuItem>
                    <MenuItem value='D3'>D3</MenuItem>
                    <MenuItem value='D305'>D305</MenuItem>
                    <MenuItem value='D315'>D315</MenuItem>
                    <MenuItem value='D345'>D345</MenuItem>
                    <MenuItem value='D375'>D375</MenuItem>
                    <MenuItem value='D715'>D715</MenuItem>
                    <MenuItem value='D712'>D712</MenuItem>
                    <MenuItem value='D710'>D710</MenuItem>
                    <MenuItem value='D7'>D7</MenuItem>
                    <MenuItem value='D720'>D120</MenuItem>
                    <MenuItem value='M215 SC'>M215 SC</MenuItem>
                    <MenuItem value='M15 SC'>M15 SC</MenuItem>
                </Field>
                <SpaceDivider />
            </React.Fragment>)}
            <Button variant='contained' color='primary' onClick={() => fields.push()}>
                <AddIcon />
                <FormattedMessage id='portlets.submissions.detail.phones.form.fields.products.add' />
            </Button>
        </React.Fragment>;
    };

    /**
     * Get total price for Overview
     *
     * @returns {number} - total price of selected Products
     */
    getTotalPrice() {
        if (this.props.formValues && this.props.formValues.products && this.props.formValues.products.length) {
            let total = 0;
            this.props.formValues.products.map((product) => {
                if (product && product.product && product.amount) {
                    total += 250 * product.amount;
                }
            });
            return total;
        } else {
            return 0;
        }
    }

    render() {
        if (this.state.detail === null) {
            return <Card>
                <CardHeader
                    title={<FormattedMessage id='portlets.submissions.list.title' />}
                    subheader={<FormattedMessage id='portlets.submissions.list.subheader' />}
                    action={<div>
                        <ActionButton variant='contained' color='secondary'
                                      onClick={() => this.setState({detail: 'add'})}>
                            <AddIcon />
                            <FormattedMessage id='actions.create' />
                        </ActionButton>
                    </div>}
                />
                <StickyTable
                    header={<Table sticky size={this.state.tableHead.length}>
                        <TableHead>
                            <TableRow>
                                {this.state.tableHead.map((item, idx) =>
                                    <TableCell key={idx} numeric={item.numeric}>
                                        <TableSortLabel active={this.state.sortName === item.sortName}
                                                        direction={this.state.sortDirection}
                                                        onClick={() => this.sortItems(item.sortName)}>
                                            {item.label}
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                    </Table>}
                    content={<Table size={this.state.tableHead.length}>
                        <TableBody>
                            {this.state.sortedItems.map((submission, idx) =>
                                <TableRow key={idx} link hover
                                          onClick={() => this.setState({detail: submission})}>
                                    <TableCell>{submission.get('name')}</TableCell>
                                    <TableCell>{Moment(submission.get('created_at')).format('l')}</TableCell>
                                    <TableCell>
                                        {submission.get('status') === 'approved'
                                            ? <Tooltip title={<FormattedMessage id='portlets.submissions.list.table.status.approved' />}>
                                            <StatusApprovedIcon className={`${this.props.classes.statusIcon} approved`} />
                                        </Tooltip>
                                            : submission.get('status') === 'pending'
                                            ? <Tooltip title={<FormattedMessage id='portlets.submissions.list.table.status.pending' />}>
                                            <StatusPendingIcon className={`${this.props.classes.statusIcon} pending`} />
                                        </Tooltip>
                                            : submission.get('status') === 'declined'
                                            ? <Tooltip title={<FormattedMessage id='portlets.submissions.list.table.status.declined' />}>
                                            <DeleteIcon className={`${this.props.classes.statusIcon} declined`} />
                                        </Tooltip>
                                            : <React.Fragment />}
                                    </TableCell>
                                    <TableCell numeric>{`${submission.get('rebate')} €`}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>} />
            </Card>;
        } else {
            return <Card>
                <CardHeader title={<FormattedMessage id='portlets.submissions.detail.title' />}
                            subheader={<FormattedMessage id='portlets.submissions.detail.subheader' />}
                            action={
                                <ActionButton iconButton disabled>
                                    <SubmissionIcon />
                                </ActionButton>
                            } />
                <Stepper activeStep={this.state.activeStep}>
                    <Step completed={this.state.activeStep > 0}>
                        <StepLabel><FormattedMessage id='portlets.submissions.detail.step.phones' /></StepLabel>
                    </Step>
                    <Step completed={this.state.activeStep > 1}>
                        <StepLabel><FormattedMessage id='portlets.submissions.detail.step.invoice' /></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel><FormattedMessage id='portlets.submissions.detail.step.confirm' /></StepLabel>
                    </Step>
                </Stepper>
                {this.state.activeStep === 0
                    ? <CardContent>
                    <Row wrapSwitch stretch>
                        <Col>
                            <Typography variant='body1'><FormattedMessage id='portlets.submissions.detail.phones.distributor' /></Typography>
                            <SpaceDivider />
                            <Form onSubmit={this.props.handleSubmit}>
                                <Field name='distributor' fieldType='Select' label={`${this.props.intl.formatMessage({id: 'portlets.submissions.detail.phones.form.fields.distributor'})}*`}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    <MenuItem value='ALSO'>ALSO</MenuItem>
                                    <MenuItem value='Snom'>Snom</MenuItem>
                                </Field>
                            </Form>
                            <SpaceDivider double />
                            <SpaceDivider double />
                            <Typography variant='body1'><FormattedMessage id='portlets.submissions.detail.phones.products' /></Typography>
                            <SpaceDivider />
                            <Form onSubmit={this.props.handleSubmit}>
                                <FieldArray name='products' component={this.renderProducts} />
                            </Form>
                        </Col>
                        <Col width='500px' minHeight='300px' relative>
                            {this.renderOverview()}
                        </Col>
                    </Row>
                </CardContent>
                    : this.state.activeStep === 1
                    ? <CardContent>
                    <Form onSubmit={this.props.handleSubmit}>
                        <Field name='invoice.number' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'portlets.submissions.detail.invoice.form.fields.invoice.number'})}*`} />
                    </Form>
                    <SpaceDivider double />
                    <DropFile
                        _classes={{
                            root: this.props.classes.dropFileRoot,
                            contentHolder: this.props.classes.dropFileContentHolder
                        }}
                        file_name={this.props.storedFile ? this.props.storedFile.name : undefined}
                        noCropAvatar
                        onDropped={file => this.props.setFile(file)}
                    />
                    <SpaceDivider />
                    <Typography variant='body2'><FormattedMessage id='portlets.submissions.detail.invoice.form.fields.invoice.upload' /></Typography>
                    <SpaceDivider double />
                    <SpaceDivider double />
                    <Typography variant='caption'><FormattedMessage id='portlets.submissions.detail.invoice.warning' /></Typography>
                </CardContent>
                    : this.state.activeStep === 2
                    ? <CardContent>
                    <Typography variant='h5'>
                        <FormattedMessage id='portlets.submissions.detail.confirm.title'
                                          values={{
                                              invoice: <strong>
                                                  {`#${this.props.formValues && this.props.formValues.invoice && this.props.formValues.invoice.number
                                                      ? this.props.formValues.invoice.number
                                                      : '000000'}`}
                                              </strong>,
                                              distributor: <strong>
                                                  {`${this.props.formValues && this.props.formValues.distributor
                                                      ? this.props.formValues.distributor
                                                      : 'Snom'}`}
                                              </strong>
                                          }} />
                    </Typography>
                    <div className={this.props.classes.confirmOverviewHolder}>
                        {this.renderOverview()}
                    </div>
                    <SpaceDivider double />
                    <Typography variant='body2' className='text-right'><FormattedMessage id='portlets.submissions.detail.confirm.description' /></Typography>
                </CardContent>
                    : <ReactFragment />}
                <CardActions>
                    <Button onClick={() => this.setState({activeStep: 0, detail: null})}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    {this.state.activeStep !== 0 &&
                        <Button variant='outlined'
                                onClick={() => this.setState({activeStep: this.state.activeStep - 1})}>
                            <BackIcon />
                            <FormattedMessage id='actions.back' />
                        </Button>
                    }
                    {this.state.activeStep !== 2
                        ? <Button variant='contained' color='primary'
                            onClick={() => this.setState({activeStep: this.state.activeStep + 1})}>
                        <FormattedMessage id='actions.next' />
                        <NextIcon />
                    </Button>
                        : <MuiThemeProvider theme={altTheme}>
                            <Button variant='contained' color='primary'
                                    onClick={() => this.setState({activeStep: 0, detail: null})}>
                            <SaveIcon />
                            <FormattedMessage id='actions.save' />
                        </Button>
                    </MuiThemeProvider>}
                </CardActions>
            </Card>;
        }
    }
}

Submissions = withStyles(styles)(Submissions);
Submissions = reduxForm({
    form: 'submissionsForm'
})(Submissions);

Submissions = connect((state, props) => ({
    items: new ImmutableList([
        new ImmutableMap({
            name: '1876865',
            created_at: '2019-07-15T14:43:02.653192+0000',
            status: 'approved',
            rebate: '500'
        }),
        new ImmutableMap({
            name: '1465854',
            created_at: '2019-06-10T11:50:08.3523292+0000',
            status: 'pending',
            rebate: '1230'
        }),
        new ImmutableMap({
            name: '1254864',
            created_at: '2019-05-10T08:21:53.163892+0000',
            status: 'declined',
            rebate: '320'
        }),
    ]),
    formValues: getFormValues('submissionsForm')(state),
    storedFile: state.files.get('file')
}), (dispatch) => bindActionCreators({
    setFile
}, dispatch))(Submissions);

export default injectIntl(Submissions);
