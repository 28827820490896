'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUIMenu from '@material-ui/core/Menu';


const styles = theme => ({
    paper: {
        // icon and text next to each other support
        '& .icon, & .text': {
            display: 'inline-flex',
            verticalAlign: 'middle',
        },
        // correct proportion of icon
        '& .icon': {
            minWidth: '0', // get rid of not necessary min-width
            marginRight: `${theme.spacing(2)}px` // space between icon and text
        },
        // correct proportion of text
        '& .text': {
            margin: '0',
            '& > span': {
                lineHeight: '1.1875em',
            }
        }
    }
});

/**
 * Extends Menu component to change style
 */
class Menu extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {paper, ...rest_of_classes} = _classes || {};
        let paperClasses = [
            classes.paper, paper, className,
        ].filter(Boolean).join(' ');

        return <MUIMenu
            classes={{
                paper: paperClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        />;
    }
}

Menu = withStyles(styles)(Menu);
export default Menu;
