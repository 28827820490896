'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import marked from 'marked';
// Actions
import {fetchTable} from 'actions/tables';
// material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({
    loader: {
        width: '552px',
        maxWidth: '100%'
    }
});

/**
 * Dialog with Terms of Services
 */
class ToSDialog extends React.Component {
    /**
     * Watch for dialog open, ensure that we have data feched
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // check if we opened dialog and if we have data loaded
        if (prevProps.open === false && this.props.open === true && this.props.static_texts === null) {
            this.props.fetchTable('static-texts');
        }
    }

    render() {
        return <Dialog open={this.props.open} onClose={this.props.handleClose}>
            {this.props.tos && <DialogTitle>{this.props.tos.get('title')}</DialogTitle>}
            <DialogContent>
                {this.props.static_texts === null
                    ? <LinearProgress className={this.props.classes.loader} />
                    : this.props.tos
                    ? <div dangerouslySetInnerHTML={{__html: marked(this.props.tos.get('text'), {breaks: true})}} />
                    : <DialogContentText><FormattedMessage id='tos.notfound' /></DialogContentText>
                }
            </DialogContent>
        </Dialog>;
    }
}


ToSDialog = withStyles(styles)(ToSDialog);
ToSDialog = connect((state, props) => ({
    static_texts: state.tables.getIn(['tables', 'static-texts']),
    tos: state.tables.getIn(['tables', 'static-texts']) ? state.tables.getIn(['tables', 'static-texts']).find(el => el.get('name') === 'tos') : null
}), (dispatch) => bindActionCreators({
    fetchTable
}, dispatch))(ToSDialog);

export default injectIntl(ToSDialog);
