'use strict';
import {constants} from 'reducers/tables';
import {handleErrors} from 'actions/errors';


/**
 * Simple action creator to set Tables links
 *
 * @param links - Links to tables resources
 */
function setLinks(links) {
    return {
        type: constants.TABLES_LINKS,
        links
    };
}

/**
 * Simple action creator to set Tables data
 *
 * @param data - actual data to set
 * @param which - Which data to set
 */
export function setData(data, which) {
    return {
        type: constants.TABLES_DATA,
        data,
        which
    };
}

/**
 * Fetch specific Table from tableLinks e.g. 'countries'
 *
 * @param which - Which table to fetch, e.g. 'countries'
 */
function _fetchTable(which) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let tablesLinks = state.tables.get('links');

        return client.get(tablesLinks.get(which)).then(result => {
            return dispatch(setData(result.data, which));
        }).catch(error => {
            return handleErrors(`fetchTable-${which}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch tableLinks (if needed) and then run _fetchTable()
 *
 * @param which - Which table to fetch, e.g. 'countries'
 */
export function fetchTable(which) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let tablesLinks = state.tables.get('links');

        if (tablesLinks === null) {
            return client.get('tables').then(result => {
                return dispatch(setLinks(result.data));
            }).then(() => {
                return dispatch(_fetchTable(which));
            }).catch(error => {
                return handleErrors(`fetchTable-${which}`, dispatch, getState, error, null);
            });
        } else {
            return dispatch(_fetchTable(which));
        }
    };
}
