'use strict';
import Moment from 'moment';
import axios from 'axios';
import {Seq} from 'immutable';
import {handleErrors} from 'actions/errors';
import {setPortletState} from 'actions/portlets/general';
import {constants} from 'reducers/portlets/calendar';

/**
 * Set Events (Calendar data) into given date (month) or clear all data
 *
 * @param portlet - Portlet to indicate where to save data
 * @param calendars - Portlet related data
 * @param date - MomentJS date which is fetched
 * @param data - Events (calendar data)
 */
export function setCalendarData(portlet, calendars, date, data) {
    return {
        type: constants.PORTLET_CALENDAR_DATA,
        portlet,
        calendars,
        date,
        data
    };
}

/**
 * Simple action creator to select date in Calendar
 *
 * @param portlet - Portlet to indicate where to save data
 * @param date - Plain JS date which is selected
 */
export function setSelectedDate(portlet, date) {
    return {
        type: constants.PORTLET_CALENDAR_DATE,
        portlet,
        date
    };
}

/**
 * Fetches multiple 1 month of calendars data (based on provided date, or current date)
 *
 * @param portlet - Instance of Calendar Portlet
 * @param calendars - Calendar Portlet related data
 * @param date - Date which month to fetch
 * @param forceFetch - by default function skips already loaded data
 */
export function fetchCalendars(portlet, calendars, date = Moment(), forceFetch = false) {
    return (dispatch, getState) => {
        let start_of_month = date.startOf('month').format();

        // check if we already have data fetched, so we don't have to do anything
        if (forceFetch === false && getState().portlets_calendar.getIn(['calendar_data', portlet.get('url'), 'events', start_of_month]) !== undefined) {
            return new Promise((resolve, reject) => resolve(true));
        }

        dispatch(setPortletState(portlet, 'fetching'));
        // prepare promises (to fetch all calendars)
        let promises = [];
        // iterate all calendars
        calendars.map(calendar => {
            promises.push(axios.get(
                `https://www.googleapis.com/calendar/v3/calendars/${calendar.get('calendar_key')}/events`,
                {params: {
                    key: portlet.getIn(['config', 'portlet_data', 'api_key']),
                    orderBy: 'startTime',
                    singleEvents: true,
                    timeMin: start_of_month,
                    timeMax: date.endOf('month').format(),
                }}
            ));
        });

        // make calls
        return Promise.all(promises).then(results => {
            Seq(results).forEach(result => {
                dispatch(setCalendarData(portlet, calendars, date, result.data));
            });
            dispatch(setPortletState(portlet, 'ready'));
        }).catch(error => {
            dispatch(setPortletState(portlet, 'error'));
            return handleErrors('fetchCalendars', dispatch, getState, error, null);
        });
    };
}
