'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// Components
import Sticky from 'react-sticky-el';
import {ScrollSync, ScrollSyncPane} from 'react-scroll-sync';


const styles = theme => ({
    stickyContainer: {
        position: 'relative'
    },
    sticky: {
        zIndex: '500'
    }
});

/**
 * Integration of react-sticky, sticky header for table.
 *
 * Usage:
 *  <StickyTable
 *      header={<Table sticky><TableHead>...</TableHead></Table>}
 *      content={<Table>....</Table>}
 *      footer={<Table sticky><Pagination /></Table>} />
 */
class StickyTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateID: 0
        };
    };

    /**
     * Watch for content change to recalculate Sticky instances position
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        if (this.props.content !== prevProps.content) {
            // workaround to prevent interval check with positionRecheckInterval
            this.setState({updateID: this.state.updateID + 1});
        }
    }

    render() {
        return <ScrollSync>
            <React.Fragment>
                <div className={this.props.classes.stickyContainer} key={this.state.updateID}>
                    {this.props.header && <Sticky
                        boundaryElement={`.${this.props.classes.stickyContainer}`}
                        stickyClassName={this.props.classes.sticky}
                        hideOnBoundaryHit={false}
                        mode='top'
                        bottomOffset={this.props.footer ? 52 : 0}>
                        <ScrollSyncPane>
                            {this.props.header}
                        </ScrollSyncPane>
                    </Sticky>}
                    <ScrollSyncPane>
                        {this.props.content}
                    </ScrollSyncPane>
                    {this.props.footer && <Sticky
                        boundaryElement={`.${this.props.classes.stickyContainer}`}
                        stickyClassName={this.props.classes.sticky}
                        hideOnBoundaryHit={false}
                        mode='bottom'
                        bottomOffset={this.props.header ? 52 : 0}>
                        <ScrollSyncPane>
                            {this.props.footer}
                        </ScrollSyncPane>
                    </Sticky>}
                </div>
            </React.Fragment>
        </ScrollSync>;
    }
}

StickyTable = withStyles(styles)(StickyTable);
export default StickyTable;
