'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {setState, addMessage} from 'actions/app';
import {reallyFetchUsers} from 'actions/users';
import {simplePost} from 'actions/shared';
// Components
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Button from 'components/core/ui/mui/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// Icons
import InviteIcon from '@material-ui/icons/PersonAdd';
import SubmitIcon from '@material-ui/icons/Send';


/**
 * Card with form to invite User to join Portal
 */
class Invite extends React.Component {
    render() {
        return <Card>
            <CardHeader
                title={<FormattedMessage id='users.list.invite.title' />}
                subheader={<FormattedMessage id='users.list.invite.subheader' />}
                action={
                    <ActionButton iconButton disabled>
                        <InviteIcon />
                    </ActionButton>
                }
            />
            <CardContent>
                <Form onSubmit={this.props.handleSubmit} alignCenter>
                    <AutoCompleteOff />
                    <Field name='first_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.list.invite.form.fields.first_name'})}*`} />
                    <Field name='last_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.list.invite.form.fields.last_name'})}*`} />
                    <Field name='email' type='email' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'users.list.invite.form.fields.email'})}*`} />
                </Form>
                <SpaceDivider />
            </CardContent>
            {['posting_invite_user', 'posted_invite_user', 'failed_invite_user'].includes(this.props.state)
                ? <CardActionsLoader success={this.props.state === 'posted_invite_user'}
                                     failure={this.props.state === 'failed_invite_user'}
                                     postAnimation={success => this.props.setState(null)} />
                : <MuiThemeProvider theme={altTheme}>
                <CardActions>
                    <Button disabled={this.props.state !== null || !this.props.company.get('id')}
                            variant='contained' color='primary' type='submit'
                            onClick={() => this.props.state === null && this.props.company.get('id') ? this.props.handleSubmit() : {}}>
                        <SubmitIcon />
                        <FormattedMessage id='users.list.invite.form.submit' />
                    </Button>
                </CardActions>
            </MuiThemeProvider>}
        </Card>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);

    return errors;
};

Invite = reduxForm({
    form: 'userInviteForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => (
        dispatch(simplePost('invite_user', props.company.get('user_set'), values)).then(() => {
            // re-fetch users
            return dispatch(reallyFetchUsers()).then(() => {
                // inform user about what we have done
                dispatch(addMessage({intl_id: 'users.list.success', path: 'on-change'}));
                // clear form
                return dispatch(reduxFormReset('userInviteForm'));
            });
        })
    )
})(Invite);

Invite = connect((state) => ({
    state: state.app.get('state'),
    company: state.company.get('company')
}), (dispatch) => bindActionCreators({
    setState,
    simplePost,
    reallyFetchUsers,
    reduxFormReset
}, dispatch))(Invite);

export default injectIntl(Invite);
