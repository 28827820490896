'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import Button from 'components/core/ui/mui/Button';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
    buttonRoot: {
        // space between
        marginLeft: `${theme.spacing(1)}px`,
        '&:first-child': {
            marginLeft: '0'
        },
        // button and not iconButton
        '&.button': {
            // change size
            padding: `7px ${theme.spacing(1)}px`,
            '&.outlined': {
                // lower padding due to border
                padding: `${7 - 1}px ${(theme.spacing(1)) - 1}px`
            },
            // fix position of loader
            '& .loader': {
                top: '3px'
            },
            // ensure that button is same size as icon button
            marginTop: '5px',
            marginBottom: '5px',
            '&:last-child': {
                // larger space between button and wall
                marginRight: `${theme.spacing(1)}px`,
            }
        }
    }
});

/**
 * Extends Button and IconButton from material-ui to better fit into CardHeader
 */
class ActionButton extends React.Component {
    render() {
        // Split some stuff from props
        let {iconButton, classes, _classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            classes.buttonRoot, root, className,
            rest_of_props.variant === 'outlined' ? ' outlined' : '',
            iconButton ? 'iconButton' : 'button'
        ].filter(Boolean).join(' ');

        if (iconButton) {
            return <IconButton
                classes={{
                    root: rootClasses,
                    ...rest_of_classes
                }}
                {...rest_of_props} />;
        } else {
            return <Button
                _classes={{
                    root: rootClasses,
                    ...rest_of_classes
                }}
                {...rest_of_props} />;
        }
    }
}

ActionButton = withStyles(styles)(ActionButton);
export default ActionButton;
