'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
// Actions
import {setState, addMessage} from 'actions/app';
import {simplePost} from 'actions/shared';
// Components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Button from 'components/core/ui/mui/Button';
import CardContent from '@material-ui/core/CardContent';
// Icons
import Icon from '@material-ui/core/Icon';


/**
 * Contact Us contains Contact Form
 */
class ContactUs extends React.Component {
    render() {
        return <React.Fragment>
            <CardContent>
                <Form onSubmit={this.props.handleSubmit} alignCenter>
                    <Field name='text' fieldType='TextArea' label={this.props.portlet.getIn(['config', 'portlet_data', 'form_field_name'])} />
                </Form>
                <SpaceDivider />
            </CardContent>
            {['posting_contact_us', 'posted_contact_us', 'failed_contact_us'].includes(this.props.state)
                ? <CardActionsLoader success={this.props.state === 'posted_contact_us'}
                                     failure={this.props.state === 'failed_contact_us'}
                                     postAnimation={success => this.props.setState(null)} />
                : <MuiThemeProvider theme={altTheme}>
                <CardActions>
                    <Button disabled={this.props.state !== null}
                            variant='contained' color='primary' type='submit'
                            onClick={() => this.props.state === null ? this.props.handleSubmit() : {}}>
                        <Icon className='icon'>{this.props.portlet.getIn(['config', 'portlet_data', 'form_submit_icon'])}</Icon>
                        <span>{this.props.portlet.getIn(['config', 'portlet_data', 'form_submit_text'])}</span>
                    </Button>
                </CardActions>
            </MuiThemeProvider>}
        </React.Fragment>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'text', data.text);

    return errors;
};

ContactUs = reduxForm({
    form: 'contactUsForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch) => {
        // split some stuff from values
        let {url, success_message, ...rest_of_data} = values;

        return dispatch(simplePost('contact_us', url, rest_of_data)).then(() => {
            dispatch(addMessage({text: success_message, path: 'on-change'}));
            return dispatch(reduxFormReset('contactUsForm')); // clear form
        });
    }
})(ContactUs);

ContactUs = connect((state, props) => ({
    state: state.app.get('state'),
    initialValues: {
        url: props.portlet.getIn(['config', 'portlet_data', 'post_url']),
        success_message: props.portlet.getIn(['config', 'portlet_data', 'success_message'])
    }
}), (dispatch) => bindActionCreators({
    reduxFormReset,
    setState,
    addMessage,
    simplePost
}, dispatch))(ContactUs);

export default ContactUs;
