'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// Components
import {SortableHandle} from 'react-sortable-hoc';
// material-ui
import MUITableCell from '@material-ui/core/TableCell';
// icons
import DragHandleIcon from '@material-ui/icons/DragHandle';


const styles = theme => ({
    // all cells
    cell: {
        // reduce font size
        fontSize: theme.typography.pxToRem(13),
        // get rid of padding
        paddingTop: '0',
        paddingBottom: '0',
        // use height
        height: `${theme.spacing(6.5)}px`
    },
    // extended numeric cell from material-ui
    numericCell: {
        textAlign: 'right',
        width: '150px !important', // fixed with
        minWidth: '0 !important'
    },
    // acts like link
    linkCell: {
        cursor: 'pointer' // correct cursor for clickable cell
    },
    // name and icon support
    nameCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .icon, & .text': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& .icon': {
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(24),
            marginRight: `${theme.spacing(1)}px`
        },
        '& .text': {
            width: `calc(100% - ${(theme.spacing(1)) + 24}px)`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    nameAvatar: {
        '& .avatar-holder, & .text': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& .avatar-holder': {
            marginRight: `${theme.spacing(1)}px`
        },
        '& .text': {
            width: `calc(100% - ${(theme.spacing(1)) + 42}px)`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    // cell with long text like URL
    urlViewCell: {
        whiteSpace: 'nowrap',
        overflow: 'auto',
        userSelect: 'all'
    },
    // cell with checkbox and nothing else
    checkboxCell: {
        width: '72px !important', // fixed width. We don't need extra space
        minWidth: '0 !important',
        textAlign: 'center'
    },
    // table cell with drag handle
    dragCell: {
        // basically same size, as checkbox
        width: '72px !important', // fixed width. We don't need extra space
        minWidth: '0 !important',
        textAlign: 'center',
        // icon itself
        '& svg': {
            cursor: 'row-resize' // correct mouse
        }
    },
});


/**
 * DragIcon for sorting
 */
const DragHandle = SortableHandle(() =>
    <DragHandleIcon />
);

/**
 * Basically MUI TableRow with optional styles
 *  'link'      - correct cursor for clickable row
 *  'name'      - ellipsis and icon support
 *  'checkbox'  - fixed checkbox size without extra space
 *  'drag'      - drag handle for react-sortable-hoc
 */
class TableCell extends React.Component {
    render() {
        // Split some stuff from props
        let {children, padding, classes, _classes, className,
            numeric, link, name, nameAvatar, urlView, checkbox, drag,
            ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            root, className,
            classes.cell,
            numeric ? classes.numericCell : '',
            link ? classes.linkCell : '',
            name ? classes.nameCell : '',
            nameAvatar ? classes.nameAvatar : '',
            urlView ? classes.urlViewCell : '',
            checkbox ? classes.checkboxCell : '',
            drag ? classes.dragCell : '',
        ].filter(Boolean).join(' ');

        return <MUITableCell
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            padding={drag || checkbox ? 'checkbox' : padding}
            {...rest_of_props}>
            {drag && !children ? <DragHandle /> : children}
        </MUITableCell>;
    }
}

TableCell = withStyles(styles)(TableCell);
export default TableCell;
