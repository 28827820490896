'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUITab from '@material-ui/core/Tab';


const styles = theme => ({
    root: {
        // reduce font size
        fontSize: theme.typography.pxToRem(13)
    }
});

/**
 * Extends Tab component with additional props
 */
class MenuItem extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            classes.root, root, className
        ].filter(Boolean).join(' ');

        return <MUITab
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        />;
    }
}

MenuItem = withStyles(styles)(MenuItem);
export default MenuItem;
