'use strict';
import {createMuiTheme} from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';

// process colors
let green = Colors.lightGreen;
green['main'] = Colors.lightGreen[500];
green['dark'] = Colors.lightGreen[800];
green['light'] = Colors.lightGreen[300];
green['contrastText'] = '#fff';
let red = Colors.red;
red['main'] = red['A400'];
red['dark'] = red['A700'];
red['light'] = red['A200'];
red['contrastText'] = '#fff';
let orange = Colors.orange;
orange['main'] = orange['A400'];
orange['dark'] = orange['A700'];
orange['light'] = orange['A200'];

// Primary application theme
export const theme = createMuiTheme({
    palette: {
        primary: {...Colors.blueGrey, contrastText: '#FFF'}, // also in style/main.styl
        secondary: Colors.amber,
        success: green,
        danger: red,
        orange: orange
    },
    fileDrop: {
        background: 'rgba(0, 0, 0, 0.6)'
    }
});

// Alt-theme for Success & Danger
export const altTheme = createMuiTheme({
    palette: {
        primary: green, // success
        secondary: red // danger
    }
});
