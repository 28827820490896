'use strict';
import {constants} from 'reducers/app';
import {handleErrors, getErrorCode} from 'actions/errors';


/**
 * Simple action creator to modify application state.
 * State is not globally implemented, use this state for various action, e.g. saving user changes
 *
 * @param {string|null} state - e.g. null, 'saving_user'
 * @param {string|null} identifier - optional identifier
 */
export function setState(state, identifier = null) {
    return {
        type: constants.APP_STATE,
        state,
        identifier
    };
}

/**
 * Simple action creator to Open or Close mobile menu
 *
 * @param {boolean} open - Should we open or close menu?
 */
export function toggleMobileMenu(open) {
    return {
        type: constants.APP_TOGGLE_MENU,
        open
    };
}

/**
 * Simple action creator to add message to store
 *
 * @param data - Raw data of message model
 */
export function addMessage(data) {
    return {
        type: constants.APP_ADD_MESSAGE,
        data
    };
}

/**
 * Simple action creator to remove message from store
 *
 * @param index - index of message to remove
 */
export function removeMessage(index) {
    return {
        type: constants.APP_REMOVE_MESSAGE,
        index
    };
}

/**
 * Simple action creator to set backup of Forms into app store
 *
 * @param forms - state.form
 */
export function backupForms(forms) {
    return {
        type: constants.APP_BACKUP_FORMS,
        forms
    };
}

/**
 * Run remote action based on provided token
 *
 * @param data - Data containing action token
 */
export function executeRemoteAction(data) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        dispatch(setState('executing_remote_action'));
        return client.post('remote-action', data).then(() => {
            dispatch(setState(null));
            // inform user about what we have done
            return dispatch(addMessage({intl_id: 'remoteaction.success', path: '/'}));
        }).catch(error => {
            dispatch(setState(null));
            let error_code = getErrorCode(error);
            switch (error_code) {
                // invalid token
                case 400:
                case 403:
                    return dispatch(addMessage({intl_id: 'remoteaction.failure', type: 'error', path: '/'}));
                // used token
                case 409:
                    return dispatch(addMessage({intl_id: 'remoteaction.failure_used', type: 'error', path: '/'}));
                // expired token
                case 410:
                    return dispatch(addMessage({intl_id: 'remoteaction.failure_expired', type: 'error', path: '/'}));
            }
            return handleErrors('executeRemoteAction', dispatch, getState, error, error_code);
        });
    };
}
