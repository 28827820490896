'use strict';
import {Map, List} from 'immutable';
import keymirror from 'keymirror';
import {asModel, listAsModel, portletListAsModel, Message, Page, PagePortlet} from 'lib/models';


export const constants = keymirror({
    APP_STATE: null,
    APP_TOGGLE_MENU: null,
    APP_ADD_MESSAGE: null,
    APP_REMOVE_MESSAGE: null,
    APP_BACKUP_FORMS: null
});

let defaults = Map({
    state: null,
    identifier: null,
    openMobileMenu: false,
    messages: List(),
    backupForms: null
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets application state
        case constants.APP_STATE:
            return state.set('state', action.state).set('identifier', action.identifier);

        // open or close mobile menu
        case constants.APP_TOGGLE_MENU:
            return state.set('openMobileMenu', action.open);

        // add Message to messages list
        case constants.APP_ADD_MESSAGE:
            return state.set('messages', List([asModel(Message, action.data), ...state.get('messages')]));

        // remove Message from messages list or all of them
        case constants.APP_REMOVE_MESSAGE:
            return action.index === null
                ? state.set('messages', defaults.get('messages'))
                : state.set('messages', state.get('messages').delete(action.index));

        // make backup of Forms for future restore
        case constants.APP_BACKUP_FORMS:
            return state.set('backupForms', action.forms || null);

        default:
            return state;
    }
};
