'use strict';
import {setErrorState as setErrorApiState, setState as setApiState} from 'actions/api';
import {addMessage} from 'actions/app';
import {clearStore, setState as setAuthState} from 'actions/auth';


/**
 * Get code from error response
 *
 * @param error         - error from catch
 * @returns {string}    - response.status eg. 500, 403, 'unknown'
 */
export function getErrorCode(error) {
    let error_code = 'unknown';
    if (error && error.response && error.response.status) {
        error_code = error.response.status;
    }
    return error_code;
}

/**
 * Handle Errors from API calls.
 * This will open pop-up about failed request with button to refresh page
 *
 * @param name          - Identifier of function, e.g. 'initializeApi'
 * @param dispatch      - dispatch method
 * @param getState     - getState method
 * @param error         - error from catch
 * @param error_code    - optional, response.status eg. 500, 403, 'unknown'
 */
export function handleErrors(name, dispatch, getState, error, error_code = null) {
    if (!error_code) {
        error_code = getErrorCode(error);
    }
    switch (error_code) {
        case 504:
        case 503:
        case 502:
            dispatch(setApiState('unavailable'));
            throw new Error('API unavailable');
        case 401:
            let client = getState().api.get('client');
            let user = getState().auth.get('user');
            if (user) {
                // try to get User account
                return client.get(user.get('url')).then(() => {
                    dispatch(setErrorApiState(name, error_code, error.message));
                    throw new Error('Login Session Healthy');
                }).catch(account_error => {
                    let account_error_code = getErrorCode(account_error);
                    // Logout user without deleting Token from API (which is invalid anyway)
                    if (account_error_code === 401) {
                        // ok, start logging out
                        dispatch(setAuthState('logging_out'));
                        // clear store
                        dispatch(clearStore());
                        // inform user about what we have done
                        dispatch(addMessage({intl_id: 'logout.expired', type: 'info', path: '/'}));
                        // done
                        dispatch(setAuthState(null));
                        // throw error to stop additional .then actions
                        throw new Error('Expired Login Session');
                    } else {
                        dispatch(setErrorApiState(name, error_code, error.message));
                        throw error;
                    }
                });
            }
    }
    dispatch(setErrorApiState(name, error_code, error.message));
    throw error;
}
