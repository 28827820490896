'use strict';
import {Map} from 'immutable';
import keymirror from 'keymirror';
import {asModel, User, Invitation} from 'lib/models';


export const constants = keymirror({
    AUTH_STATE: null,
    AUTH_STORAGE: null,
    AUTH_USER: null,
    AUTH_INVITATION: null
});

let defaults = Map({
    state: null,
    user: new User(),
    invitation: null // null states indicates 'not loaded yet'
});

export default (state = defaults, action) => {
    switch (action.type) {
        // sets AUTH state
        case constants.AUTH_STATE:
            return state.set('state', action.state);

        // sets / remove User
        case constants.AUTH_USER:
            return state.set('user', action.user ? asModel(User, action.user) : defaults.get('user'));

        // sets / remove Invitation
        case constants.AUTH_INVITATION:
            return state.set('invitation', action.invitation ? asModel(Invitation, action.invitation) : defaults.get('invitation'));

        default:
            return state;
    }
};
