'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {fetchTable} from 'actions/tables';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';


/**
 * Redux Field for Company Types, used during registration
 */
class CompanyTypeField extends React.Component {
    /**
     * Ensure we have Company Types fetched
     */
    componentDidMount() {
        // check if we have data loaded
        if (this.props.company_types === null) {
            this.props.fetchTable('company-types');
        }
    }

    render() {
        // Split some stuff from props
        let {fetchTable, company_types, intl, disabled, ...rest_of_props} = this.props;

        return <Field fieldType='Select'
                      disabled={company_types === null || disabled}
                      renderValue={company_type_url => company_types === null
                          ? ''
                          : company_types.find(el => el.get('url') === company_type_url).get('name')
                      }
                      {...rest_of_props}>
            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
            {company_types !== null && company_types.map((company_type, idx) =>
                <MenuItem key={idx} value={company_type.get('url')}>
                    {company_type.get('name')}
                </MenuItem>
            )}
        </Field>;
    }
}

CompanyTypeField = connect((state, props) => ({
    company_types: state.tables.getIn(['tables', 'company-types']),
}), (dispatch) => bindActionCreators({
    fetchTable
}, dispatch))(CompanyTypeField);

export default injectIntl(CompanyTypeField);
