'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    input: {
        // hide it
        position: 'absolute',
        top: '0',
        left: '-9999px',
        zIndex: '-1',
        width: '1px',
        height: '1px',
        visibility: 'hidden',
        // get rid of style
        padding: '0',
        margin: '0',
        background: 'transparent',
        border: '0'
    }
});

/**
 * Add this component at the end of the Form to allow submiting form with Enter key
 */
class InvisibleEnterSubmit extends React.Component {
    render() {
        return <React.Fragment>
            <input type='submit' tabIndex='-1' className={this.props.classes.input} />
        </React.Fragment>;
    }
}

InvisibleEnterSubmit = withStyles(styles)(InvisibleEnterSubmit);
export default InvisibleEnterSubmit;
