'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
import {setPortletState} from 'actions/portlets/general';
// Components
import ReactSwipe from 'react-swipe';
// material-ui
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({
    // 16:9 image
    imageRatio: {
        // style
        background: theme.palette.grey[200],
        boxShadow: theme.shadows[1], // highlight effect
        // nice fit
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&.ratio-2': {
            // 16:9
            paddingBottom: '56.25%',
        },
        '&.ratio-3': {
            // 1:1
            paddingBottom: '100%'
        }
    },
    // regular image
    image: {
        display: 'block',
        margin: '0 auto',
        // style
        background: theme.palette.grey[200],
        boxShadow: theme.shadows[1], // highlight effect
    },
    // holder of images
    galleryHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        // to sides
        margin: `0 -${theme.spacing(1)}px -${theme.spacing(1)}px`,
        // holder of image
        '& > .image-holder': {
            display: 'flex',
            alignItems: 'center',
            minWidth: '183px', // 183:244 = 3:4
            // space between
            padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
            // image itself
            '& > img': {
                maxHeight: '244px'
            },
            '& > div': {
                height: '100%',
                width: '100%'
            }
        },
        '&.col-2 > .image-holder': {
            width: '50%'
        },
        '&.col-3 > .image-holder': {
            width: '33.3333%'
        },
        '&.col-4 > .image-holder': {
            width: '25%'
        },
        '&.col-5 > .image-holder': {
            width: '20%'
        },
        '&.col-6 > .image-holder': {
            width: '16.6666666667%'
        },
    }
});

/**
 * Renders Gallery Portlet - as Carousel or Gallery
 */
class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: 0 // gallery columns
        };
        this.swipeRef = React.createRef(); // ref for react-swipe instance
    }

    /**
     * During initialization fetch Images
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready' || this.props.loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'related_data_set']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
                // calculate size for gallery
                this.calculateGallerySize();
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        } else {
            // calculate size for gallery
            this.calculateGallerySize();
        }
    }

    /**
     * Calculate how many columns should be used
     */
    calculateGallerySize() {
        if (this.props.portlet.getIn(['config', 'portlet_data', 'type']) === 2) {
            let cols = 0;
            switch (this.props.portlet.getWidth()) {
                case '100%':
                    cols = 6;
                    break;
                case '75%':
                    cols = 5;
                    break;
                case '66.6667%':
                    cols = 4;
                    break;
                case '50%':
                    cols = 3;
                    break;
                case '33.3333%':
                case '25%':
                    cols = 2;
                    break;
                default:
                    cols = 6;
                    break;
            }
            let image_num = this.props.items.size;
            if (image_num < cols) {
                cols = image_num;
            }
            // set to state
            this.setState({cols: cols});
        }
    }

    /**
     * Renders Image itself from provided Image File
     * @param imageFile - File object
     */
    renderImage(imageFile) {
        // short to make code slightly more readable
        let ratio = this.props.portlet.getIn(['config', 'portlet_data', 'ratio']);

        return ratio === 1
            ? <img className={this.props.classes.image} src={imageFile.get('file')} alt={imageFile.get('name')} />
            : <div className={`${this.props.classes.imageRatio} ratio-${ratio}`} style={{backgroundImage: `url("${imageFile.get('file')}")`}} />;
    }

    render() {
        // shortcut to make code slightly more readable
        let type = this.props.portlet.getIn(['config', 'portlet_data', 'type']);

        // todo: with material-ui 5.0.0 use carousel instead of ReactSwipe

        return <React.Fragment>
            {this.props.portletState === 'fetching'
                ? <CardContent>
                <LinearProgress />
            </CardContent>
                : this.props.portletState === 'error'
                ? <CardContent>
                <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
            </CardContent>
                : this.props.portletState === 'ready'
                ? <CardContent>
                {type === 1 // carousel
                    ? <ReactSwipe ref={this.swipeRef}
                                  swipeOptions={{
                                      startSlide: 0,
                                      continuous: true,
                                      auto: 5000,
                                      speed: 500,
                                  }}>
                    {this.props.items.map((imageFile, idx) => <div key={idx}>
                        {this.renderImage(imageFile, undefined)}
                    </div>)}
                </ReactSwipe>
                    : type === 2 // gallery
                    ? <div className={`${this.props.classes.galleryHolder} col-${this.state.cols}`}>
                    {this.props.items.map((imageFile, idx) => <div className='image-holder' key={idx}>
                        {this.renderImage(imageFile, undefined)}
                    </div>)}
                </div>
                    : <div />}
            </CardContent>
                : <div />}
        </React.Fragment>;
    }
}

Gallery = withStyles(styles)(Gallery);
Gallery = connect((state, props) => ({
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]),
    items: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList()
}), (dispatch) => bindActionCreators({
    setPortletState,
    fetchItems
}, dispatch))(Gallery);

export default injectIntl(Gallery);
