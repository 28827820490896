'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Folder Icon
 */
export default class Folder extends React.Component {
    static defaultProps = {
        viewBox: '0 0 58 58'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: theme.palette.primary[500]}} points='25,10.5 20,3.5 0,3.5 0,10.5 0,54.5 58,54.5 58,17.5 30,17.5 ' />
            <polygon style={{fill: theme.palette.primary[800]}} points='30,17.5 58,17.5 58,10.5 25,10.5 ' />
        </svg>;
    }
}
