'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Icon representing Video
 */
export default class Video extends React.Component {
    static defaultProps = {
        viewBox: '0 0 53 53'
    };

    render() {
        return <svg {...this.props}>
            <rect style={{fill: theme.palette.primary[500]}} x='0.5' width='52' height='53' />
            <polygon style={{fill: theme.palette.common.white}} points='23.5,25 23.5,17.954 23.5,11 34.5,18' />
            <rect style={{fill: theme.palette.primary[800]}} x='0.5' y='34.941' width='52' height='18.059' />
            <path style={{fill: theme.palette.primary[900]}} d='M46.5,45h-30c-0.553,0-1-0.447-1-1s0.447-1,1-1h30c0.553,0,1,0.447,1,1S47.053,45,46.5,45z' />
            <path style={{fill: theme.palette.common.white}} d='M6.5,48c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1s1,0.447,1,1v6C7.5,47.553,7.053,48,6.5,48z' />
            <path style={{fill: theme.palette.common.white}} d='M10.5,48c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1s1,0.447,1,1v6C11.5,47.553,11.053,48,10.5,48z' />
            <path style={{fill: theme.palette.secondary[500]}} d='M21.5,43h-5c-0.553,0-1,0.447-1,1s0.447,1,1,1h5V43z' />
            <path style={{fill: theme.palette.common.white}} d='M21.5,48c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1s1,0.447,1,1v6C22.5,47.553,22.053,48,21.5,48z' />
        </svg>;
    }
}
