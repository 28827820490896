'use strict';
import {constants} from 'reducers/company';
import {SubmissionError} from 'redux-form';
import {handleErrors, getErrorCode} from 'actions/errors';
import {setState} from 'actions/app';

// todo: get rid of this, implement methods from shared.js

/**
 * Simple action creator to add/update Company in store
 *
 * @param company - raw Company data or null (set's empty company)
 */
export function setCompany(company) {
    return {
        type: constants.COMPANY_COMPANY,
        company
    };
}

/**
 * Simple action creator to add/update Company Partner Level in store
 *
 * @param partner_level - raw PartnerLevel data or null (set's empty PartnerLevel)
 */
export function setPartnerLevel(partner_level) {
    return {
        type: constants.COMPANY_PARTNER_LEVEL,
        partner_level
    };
}

/**
 * Fetch User Company and it's Partner Level
 */
export function fetchCompany(url) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');

        dispatch(setState('fetching_company'));
        return client.get(url).then(result => {
            dispatch(setCompany(result.data));
            if (result.data.partner_level) {
                return client.get(result.data.partner_level).then(result => {
                    dispatch(setPartnerLevel(result.data));
                    return dispatch(setState(null));
                });
            } else {
                return dispatch(setState(null));
            }
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('fetchCompany', dispatch, getState, error, null);
        });
    };
}

/**
 * Update User company
 *
 * @param data - Data containing all Company fields
 */
export function updateCompany(data) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let url = state.company.getIn(['company', 'url']);

        dispatch(setState('updating_company'));
        return client.patch(url, data).then(result => {
            return dispatch(setCompany(result.data));
        }).then(() => {
            return dispatch(setState('updated_company')); // trigger success animation, loader will reset state
        }).catch(error => {
            dispatch(setState('failed_update_company'));
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    // display field errors
                    throw new SubmissionError(error.response.data);
            }
            return handleErrors('updateCompany', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Update Company logo
 *
 * @param logo - New Image from DropFile component
 */
export function updateCompanyLogo(logo) {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');
        let url = state.company.getIn(['company', 'url']);

        dispatch(setState('updating_company_logo'));
        // upload new image
        return client.upload('files', logo, {name: 'logo', type: 'Image'}).then((result) => {
            // add image to Company
            return client.patch(url, {logo: result.data.url});
        }).then(result => {
            // update Company and we are done
            return dispatch(setCompany(result.data));
        }).then(() => {
            dispatch(setState(null));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('updateCompanyLogo', dispatch, getState, error, null);
        });
    };
}
