'use strict';
import Client from 'lib/client';
import {constants} from 'reducers/api';
import {handleErrors, getErrorCode} from 'actions/errors';
// external actions
import {loginFromStore} from 'actions/auth';


/**
 * Simple action creator to modify api state
 *
 * @param {string|null} state - e.g. 'ready', 'fetching', 'error'
 */
export function setState(state) {
    return {
        type: constants.API_STATE,
        state
    };
}

/**
 * Simple action creator to add Client to store
 *
 * @param client - API Client (client.js)
 */
function setClient(client) {
    return {
        type: constants.API_CLIENT,
        client
    };
}

/**
 * Update Client links map
 *
 * @param root - path of placement e.g. 'tables'
 * @param payload - links in the path
 */
export function updateClientLinks(root, payload) {
    return {
        type: constants.API_UPDATE_LINKS,
        root,
        payload
    };
}

/**
 * Simple action creator to add Config to store
 *
 * @param config - RAW data for Config model
 */
function setConfig(config) {
    return {
        type: constants.API_CONFIG,
        config
    };
}

/**
 * Simple action creator to set api state to error
 *
 * @param {string|null} error_identifier - name of method where request failed
 * @param {int|null} error_code - response code e.g. 'unknown', 500, 403, etc.
 * @param {string|null} error_message - technical message what happen (error.message)
 */
export function setErrorState(error_identifier, error_code, error_message) {
    return {
        type: constants.API_ERROR_STATE,
        error_identifier,
        error_code,
        error_message
    };
}

/**
 * Initializes the API by obtaining the root
 */
export function initializeApi(apiRoot) {
    return (dispatch, getState) => {
        dispatch(setState('fetching'));

        let client = new Client(apiRoot);
        return client.initClient().then((result) => {
            return dispatch(setClient(client));
        }).then(() => {
            // todo: client.get('config').then((result) => {
            return dispatch(setConfig({customer: 'awesome'}));
        }).then(() => {
            // try to login user from store
            return dispatch(loginFromStore());
        }).then(() => {
            if (getState().api.get('state') === 'fetching') {
                return dispatch(setState('ready'));
            } else {
                // this means, that we are in state 'tos_update'
                return new Promise((resolve, reject) => resolve(true));
            }
        }).catch((error) => {
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 504:
                case 503:
                case 502:
                    dispatch(setState('unavailable'));
                    break;
                default:
                    dispatch(setState('error'));
            }
            return handleErrors('initializeApi', dispatch, getState, error, error_code);
        });
    };
}
