'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import Moment from 'moment';
// Actions
import {fetchUsers, deleteUser} from 'actions/users';
// Components
import DeleteDialog from 'components/core/ui/DeleteDialog';
import Invite from 'components/modules/users/Invite';
import {Row, Col} from 'components/core/ui/Grid';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import UsersIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import EmptyAvatar from '@material-ui/icons/Person';


const styles = theme => ({
    // holder of user picture
    avatarHolder: {
        // style
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '50%',
    },
    avatar: {
        // display something during image loading, also background of empty avatar
        background: theme.palette.grey[300]
    },
    // holder of 'trash' icon, getting rid of tooltip - disabled child warning
    deleteButtonHolder: {
        display: 'inline-flex'
    }
});

/**
 * Manage Users in Portal
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteDialogOpen: false, // open/close status of Delete dialog
            deleteDialogUser: null // marks user to delete
        };
    };

    /**
     * Ensure we have Users fetched
     */
    componentDidMount() {
        // load users if we didn't already
        if (this.props.users === null) {
            this.props.fetchUsers();
        }
    }

    // todo: sorting

    render() {
        return <div>
            <Row wrapSwitch>
                <Col width='66.6667%'>
                    <Card>
                        <CardHeader
                            title={<FormattedMessage id='users.list.title' />}
                            subheader={<FormattedMessage id='users.list.subheader' />}
                            action={
                                <ActionButton iconButton disabled>
                                    <UsersIcon />
                                </ActionButton>
                            }
                        />
                        <StickyTable
                            header={<Table sticky size={5}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell nameAvatar><FormattedMessage id='users.list.table.name' /></TableCell>
                                        <TableCell><FormattedMessage id='users.list.table.email' /></TableCell>
                                        <TableCell><FormattedMessage id='users.list.table.created_at' /></TableCell>
                                        <TableCell><FormattedMessage id='users.list.table.last_access' /></TableCell>
                                        <TableCell numeric><FormattedMessage id='users.list.table.actions' /></TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>}
                            content={<Table size={5}>
                                {this.props.state === 'fetching_users' || this.props.state === 'fetching_company'
                                    ? <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={5}><LinearProgress /></TableCell>
                                    </TableRow>
                                </TableBody>
                                    : this.props.users !== null
                                    ? <TableBody>
                                    {this.props.users.map((user, idx) =>
                                        <TableRow key={idx}>
                                            <TableCell nameAvatar>
                                                <div className={`${this.props.classes.avatarHolder} avatar-holder`}>
                                                    <Avatar alt={`${user.get('first_name')} ${user.get('last_name')}`}
                                                            src={user.get('avatar_url') || undefined}
                                                            className={this.props.classes.avatar}>
                                                        {!user.get('avatar_url') && <EmptyAvatar />}
                                                    </Avatar>
                                                </div>
                                                <span className='text'>{`${user.get('first_name')} ${user.get('last_name')}`}</span>
                                            </TableCell>
                                            <TableCell urlView>{user.get('email')}</TableCell>
                                            <TableCell>{Moment(user.get('created_at')).format('l, LT')}</TableCell>
                                            <TableCell>{user.get('last_access') ? Moment(user.get('last_access')).format('l') : <FormattedMessage id='users.list.table.last_access.never' />}</TableCell>
                                            <TableCell numeric>
                                                {this.props.state === 'deleting_user' && this.props.stateIdentifier === user.get('id')
                                                    ? <IconButton>
                                                    <CircularProgress size={24} />
                                                </IconButton>
                                                    : <Tooltip title={<FormattedMessage id='actions.delete' />}>
                                                    <div className={this.props.classes.deleteButtonHolder}>
                                                        <IconButton disabled={this.props.state !== null || user.get('id') === this.props.user.get('id')}
                                                                    onClick={() => this.props.state === null && user.get('id') !== this.props.user.get('id') ? this.setState({deleteDialogOpen: true, deleteDialogUser: user}) : {}}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                    : <TableBody><TableRow /></TableBody>}
                            </Table>} />
                    </Card>
                </Col>
                <Col width='33.3333%'>
                    <Invite company={this.props.company} />
                </Col>
            </Row>
            <DeleteDialog
                item={this.props.intl.formatMessage({id: 'users.list.deletedialog.item'})}
                items={this.props.intl.formatMessage({id: 'users.list.deletedialog.items'})}
                open={this.state.deleteDialogOpen}
                handleClose={() => this.setState({deleteDialogOpen: false, deleteDialogUser: null})}
                handleConfirm={() => {
                    this.props.deleteUser(this.state.deleteDialogUser);
                    this.setState({deleteDialogOpen: false});
                }}
            />
        </div>;
    }
}

List = withStyles(styles)(List);
List = connect((state) => ({
    state: state.app.get('state'),
    stateIdentifier: state.app.get('identifier'),
    users: state.users.get('users'),
    user: state.auth.get('user'),
    company: state.company.get('company')
}), (dispatch) => bindActionCreators({
    fetchUsers,
    deleteUser
}, dispatch))(List);

export default injectIntl(List);
