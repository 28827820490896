'use strict';
import storage from 'store';
import {constants} from 'reducers/auth';
import {SubmissionError} from 'redux-form';
import {handleErrors, getErrorCode} from 'actions/errors';
import {addMessage, removeMessage} from 'actions/app';
import {setPortletState, setRelatedData, setSelected} from 'actions/portlets/general';
import {setCalendarData} from 'actions/portlets/calendar';
import {setUsers} from 'actions/users';
import {setCompany, setPartnerLevel, fetchCompany} from 'actions/company';
import {setCollection} from 'actions/shared';
import {fetchTable} from 'actions/tables';
import {setState as setApiState} from 'actions/api';


/**
 * Simple action creator to modify auth state
 *
 * @param {string|null} state - e.g. 'ready', 'fetching', 'error'
 */
export function setState(state) {
    return {
        type: constants.AUTH_STATE,
        state
    };
}

/**
 * Simple action creator to add/update User in store
 *
 * @param user - raw User data or null (set's empty user)
 */
export function setUser(user) {
    return {
        type: constants.AUTH_USER,
        user
    };
}

/**
 * Simple action creator to set/remove Invitation in store
 *
 * @param invitation - raw Invitation data or null (removes invitation)
 */
export function setInvitation(invitation) {
    return {
        type: constants.AUTH_INVITATION,
        invitation
    };
}

/**
 * Function to process Login -> not just from Login, but also from Registration, Forgotten Password, etc.
 *
 * @param result - result from request which will sign user, like 'tokens'
 */
export function loginProcedure(result) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        // get values from result
        let token = result.data.id;
        let psk = result.data.key;
        let account_link = result.data.user;
        let company_link = result.data.company;
        let token_link = result.data.url;

        // Save data to storage
        storage.set(`__datastore-${process.env.STORAGE_PREFIX}`,
            {token, psk, account_link, company_link, token_link});

        // Authorize the client
        client.authorize(token, psk);

        // now obtain User
        return client.get(account_link).then(result => {
            return dispatch(setUser(result.data));
        }).then(() => {
            // we need User company
            return dispatch(fetchCompany(company_link));
        }).then(() => {
            return dispatch(postLoginActions());
        }).then(() => {
            // we are logged
            return dispatch(setState('logged'));
        }).catch(error => {
            return handleErrors('loginProcedure', dispatch, getState, error, null);
        });
    };
}

/**
 * Try to login user from store, loadLocalState
 */
export function loginFromStore() {
    return (dispatch, getState) => {
        // get data from storage
        let storage_data = storage.get(`__datastore-${process.env.STORAGE_PREFIX}`);
        if (!storage_data || !storage_data.token || !storage_data.psk || !storage_data.company_link || !storage_data.account_link || !storage_data.token_link) {
            storage.remove(`__datastore-${process.env.STORAGE_PREFIX}`);
            // we don't want to continue
            return;
        }

        // alright, let's try to log user
        dispatch(setState('logging'));

        // Authorize the client
        let client = getState().api.get('client');
        client.authorize(storage_data.token, storage_data.psk);

        // now obtain User
        return client.get(storage_data.account_link).then(result => {
            return dispatch(setUser(result.data));
        }).then(() => {
            // we need User company
            return dispatch(fetchCompany(storage_data.company_link));
        }).then(() => {
            return dispatch(postLoginActions());
        }).then(() => {
            // we are logged
            return dispatch(setState('logged'));
        }).catch(error => {
            dispatch(setState(null));
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                case 401:
                case 403:
                    // de-authorize client
                    client.deauthorize();
                    // clear old storage
                    storage.remove(`__datastore-${process.env.STORAGE_PREFIX}`);
                    return;
            }
            return handleErrors('loginFromStore', dispatch, getState, error, error_code);
        });
    };
}

/**
 * After login in User (with loginProcedure or loginFromStore) perform some actions
 */
export function postLoginActions() {
    return (dispatch, getState) => {
        let state = getState();
        let static_texts = state.tables.getIn(['tables', 'static-texts']);

        // we need static_text, so fetch them and run this method again
        if (!static_texts) {
            return dispatch(fetchTable('static-texts')).then(() => dispatch(postLoginActions()));
        }
        let tos = static_texts.find(el => el.get('name') === 'tos');
        let user = state.auth.get('user');

        // remove all messages (new ones are added after login)
        dispatch(removeMessage(null));

        // check if User have valid ToS
        if (tos && tos.get('version') !== user.get('last_tos_version')) {
            return dispatch(setApiState('tos_update'));
        } else {
            return new Promise((resolve, reject) => resolve(true));
        }
    };
}

/**
 * Login user from provided data
 *
 * @param data - Data containing e-mail and password
 */
export function login(data) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        dispatch(setState('logging'));
        return client.post('tokens', data).then(result => {
            return dispatch(loginProcedure(result));
        }).catch(error => {
            dispatch(setState('login_failed')); // trigger failure animation, loader will reset state
            let error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    if (error.response.data.non_field_errors) {
                        throw new SubmissionError({_error: 'invalid_credentials'});
                    }
                    // display field errors
                    throw new SubmissionError(error.response.data);
                case 401:
                case 403:
                    throw new SubmissionError({_error: 'invalid_credentials'});
            }
            return handleErrors('login', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Clear all stored stuff from Store
 */
export function clearStore() {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');

        // de-authorize client
        client.deauthorize();
        // clear storage
        storage.remove(`__datastore-${process.env.STORAGE_PREFIX}`);
        // clear user
        dispatch(setUser(null));
        // clear fetched stuff which can vary for other User (so not tables)
        dispatch(setCollection(null, null, null));
        dispatch(setPortletState(null, null));
        dispatch(setRelatedData(null, null));
        dispatch(setSelected(null, null));
        dispatch(setCalendarData(null, null, null));
        dispatch(setCompany(null));
        dispatch(setPartnerLevel(null));
        dispatch(setUsers(null));
        return dispatch(removeMessage(null));
    };
}

/**
 * Logout user - clear token, store, user
 */
export function logout() {
    return (dispatch, getState) => {
        let state = getState();
        let client = state.api.get('client');

        // get data from storage
        let storage_data = storage.get(`__datastore-${process.env.STORAGE_PREFIX}`);
        try {
            if (!storage_data || !storage_data.token_link) {
                throw new Error('invalid storage');
            }
        } catch (error) {
            return handleErrors('logout', dispatch, getState, error, null);
        }

        // ok, start logging out
        dispatch(setState('logging_out'));
        // actually delete token from backend
        return client.delete(storage_data.token_link).then(() => {
            // clear store
            dispatch(clearStore());
            // inform user about what we have done
            dispatch(addMessage({intl_id: 'logout.success', type: 'info', path: '/'}));
            // done
            return dispatch(setState(null));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('logout', dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch Invitation from server to display basic information to User so he can registerViaInvitation
 *
 * @param data - Data containing token
 */
export function fetchInvitation(data) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');

        dispatch(setState('fetching_invitation'));
        return client.get('invitation-info', data).then(result => {
            dispatch(setState(null));
            return dispatch(setInvitation(result.data));
        }).catch(error => {
            dispatch(setState(null));
            let error_code = getErrorCode(error);
            switch (error_code) {
                // invalid token
                case 400:
                case 401:
                case 403:
                case 404:
                    dispatch(addMessage({intl_id: 'invitation.error.invalid_token', type: 'error', path: '/'}));
                    return dispatch(setState('invalid_token'));
                // used token
                case 410:
                    dispatch(addMessage({intl_id: 'invitation.error.used_token', type: 'error', path: '/'}));
                    return dispatch(setState('invalid_token'));
            }
            return handleErrors('fetchInvitation', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Register User to Portal with Invitation.
 * Which is basically login (because we already have User registered and token) and then patch with his new password
 *
 * @param data - Data containing new User information and token
 */
export function registerViaInvitation(data) {
    return (dispatch, getState) => {
        let client = getState().api.get('client');
        // remove stuff from data
        let {password2, company, token, ...rest_of_data} = data;

        dispatch(setState('registering_via_invitation'));
        // first make login request
        return client.post('tokens', {token: token}).then(result => {
            // get values from result
            let token = result.data.id;
            let psk = result.data.key;
            let account_link = result.data.user;
            // Authorize the client for patch request
            client.authorize(token, psk);
            // now patch User
            return client.patch(account_link, rest_of_data);
        }).catch(error => {
            dispatch(setState('register_failed')); // trigger failure animation, loader will reset state
            // ensure that client is no longer authorized
            client.deauthorize();
            let error_code = getErrorCode(error);
            switch (error_code) {
                // invalid token
                case 400:
                    // display field errors
                    throw new SubmissionError(error.response.data);
                case 401:
                case 403:
                case 404:
                    dispatch(addMessage({intl_id: 'invitation.error.invalid_token', type: 'error', path: '/'}));
                    return dispatch(setState('invalid_token'));
            }
            return handleErrors('registerViaInvitation', dispatch, getState, error, null);
        });
    };
}
