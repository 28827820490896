'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUITableRow from '@material-ui/core/TableRow';


const styles = theme => ({
    linkRow: {
        cursor: 'pointer' // link cursor
    },
    dragRow: {
        visibility: 'visible !important' // hidden visibility causes rendering error, opacity: 0 is good enough
    }
});

/**
 * Basically MUI TableRow with optional styles
 *  'link' - correct cursor for clickable row
 *  'drag' - DragTableRow for react-sortable-hoc
 */
class TableRow extends React.Component {
    render() {
        // Split some stuff from props
        let {classes, _classes, className, link, drag, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, ...rest_of_classes} = _classes || {};
        let rootClasses = [
            root, className,
            link ? classes.linkRow : '',
            drag ? classes.dragRow : '',
        ].filter(Boolean).join(' ');

        return <MUITableRow
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />;
    }
}

TableRow = withStyles(styles)(TableRow);
export default TableRow;
