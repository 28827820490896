'use strict';
import {createStore, compose, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';
import {MuiThemeProvider} from '@material-ui/core/styles';
import '../style/main.styl';
import applicationStore from './reducers';
import {initializeApi} from 'actions/api';
import {createBrowserHistory} from 'history';
import {routerMiddleware, ConnectedRouter} from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import Application from './components/Application';
import {theme} from './theme/index';
import storage from 'store';
import Moment from 'moment';
// Locale
import {addLocaleData} from 'react-intl';
import {Provider} from 'react-intl-redux';
import enLocaleData from 'react-intl/locale-data/en';
import csLocaleData from 'react-intl/locale-data/cs';
import deLocaleData from 'react-intl/locale-data/de';
import esLocaleData from 'react-intl/locale-data/es';
import frLocaleData from 'react-intl/locale-data/fr';
import ruLocaleData from 'react-intl/locale-data/ru';
addLocaleData([...enLocaleData, ...csLocaleData, ...deLocaleData, ...esLocaleData, ...frLocaleData, ...ruLocaleData]);
// Strings
import enTexts from 'locale/en';
import csTexts from 'locale/cs';
import deTexts from 'locale/de';
import esTexts from 'locale/es';
import frTexts from 'locale/fr';
import ruTexts from 'locale/ru';
// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';

// Sentry
if (process.env.SENTRY_DSN) {
    Raven.config(process.env.SENTRY_DSN).install();
}

const history = createBrowserHistory({basename: process.env.PATH_PREFIX});

function getInitialState() {
    // get language from storage
    let locale = storage.get(`__datastore-${process.env.STORAGE_PREFIX}-lang`);
    // we don't have lang, guess it
    if (!locale) {
        locale = window.navigator.userLanguage || window.navigator.language;
    }

    // make sure that we have translated lang
    if (!['en', 'keys'].includes(locale)) { // 'cs', 'fr'
        locale = 'en';
    }

    // get proper messages
    let messages = null;
    switch (locale) {
        case 'en':
            messages = enTexts;
            break;
        case 'cs':
            messages = csTexts;
            break;
        case 'de':
            messages = deTexts;
            break;
        case 'es':
            messages = esTexts;
            break;
        case 'fr':
            messages = frTexts;
            break;
        case 'ru':
            messages = ruTexts;
            break;
        case 'keys':
            messages = Object.keys(enTexts).reduce((o, key) => Object.assign(o, {[key]: key}), {});
            locale = 'en-gb';
            break;
    }
    // support for missing translation string
    messages = locale === 'en' ? messages : {...enTexts, messages};

    // localize other libs
    Moment.locale(locale);

    return {
        intl: {
            locale: locale,
            messages: messages
        }
    };
}

const store = createStore(
    applicationStore(history), getInitialState(), compose(
        // Add redux thunk
        applyMiddleware(reduxThunk, routerMiddleware(history)),

        // Enable redux dev tools, if installed in chrome and not built for production
        process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);
store.dispatch(initializeApi(process.env.API_URL));

const render = Component => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Component />
                </ConnectedRouter>
            </Provider>
        </MuiThemeProvider>,
        document.getElementById('react-container'),
    );
};

render(Application);
