'use strict';
import React from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
// material-ui
import MUICircularProgress from '@material-ui/core/CircularProgress';
// icons
import SuccessIcon from '@material-ui/icons/Done';
import FailureIcon from '@material-ui/icons/Close';


const animationLength = 500; // duration of success animation in MS
const styles = theme => ({
    // holder of everything
    root: {
        position: 'relative'
    },
    // circular by itself
    progress: {
        opacity: '1',
        // allow animation
        transition: theme.transitions.create('opacity',
            {duration: animationLength / 2}),
        '&.anim': {
            // hide
            opacity: '0'
        }
    },
    // success or failure icon
    icon: {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        margin: '0 auto',
        // correct size
        fontSize: (props) => props.size ? `${props.size}px` : '40px',
        width: (props) => props.size ? `${props.size}px` : '40px',
        // hide by default
        opacity: '0',
        // allow animation
        transition: theme.transitions.create(['opacity', 'color'],
            {duration: animationLength / 2}),
        transitionDelay: `${(animationLength / 2)}ms`,
        // loader color
        color: theme.palette.primary[500],
        '&.anim': {
            // display icon
            opacity: '1',
            // recolor
            '&.success': {
                color: theme.palette.success[500]
            },
            '&.failure': {
                color: theme.palette.danger[500]
            }
        }
    }
});

/**
 * CircularProgress with additional success/failure/postAnimation functionality like CardActionsLoader
 */
class CircularProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false, // successfully finished
            failure: false // not successfully finished
        };
    }

    /**
     * Check if we should immediately run animation
     */
    componentDidMount() {
        // in case we already have success true, run animation
        if (this.props.success) {
            setTimeout(() => {
                this.setState({success: true});
            }, 300); // react too fast
            setTimeout(() => {
                this.props.postAnimation(true);
            }, (animationLength + 1300));
        } else if (this.props.failure) {
            setTimeout(() => {
                this.setState({failure: true});
            }, 300); // react too fast
            setTimeout(() => {
                this.props.postAnimation(false);
            }, (animationLength + 1300));
        }
    }

    /**
     * Run animation when success or failure change in props
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // loading finished, run animation
        if (!prevProps.success && this.props.success) {
            this.setState({success: true});
            setTimeout(() => {
                this.props.postAnimation(true);
            }, (animationLength + 1000));
        } else if (!prevProps.failure && this.props.failure) {
            this.setState({failure: true});
            setTimeout(() => {
                this.props.postAnimation(false);
            }, (animationLength + 1000));
        }
    }

    render() {
        // Split some stuff from props
        let {classes, _classes, className, success, failure, postAnimation, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, progress, icon, ...rest_of_classes} = _classes || {};

        let rootClasses = [
            root, classes.root
        ].filter(Boolean).join(' ');

        let progressClasses = [
            classes.progress, progress, className
        ].filter(Boolean).join(' ');

        let iconClasses = [
            classes.icon, icon
        ].filter(Boolean).join(' ');

        // success and failure with animation
        let successIconClasses = iconClasses;
        let failureIconClasses = iconClasses;
        if (this.state.success) {
            progressClasses = `${progressClasses} anim`;
            successIconClasses = `${successIconClasses} anim success`;
        } else if (this.state.failure) {
            progressClasses = `${progressClasses} anim`;
            failureIconClasses = `${failureIconClasses} anim failure`;
        }

        return <div className={rootClasses}>
            <MUICircularProgress className={progressClasses} {...rest_of_props} />
            {this.props.postAnimation && <MuiThemeProvider theme={altTheme}>
                <SuccessIcon className={successIconClasses} />
                <FailureIcon className={failureIconClasses} />
            </MuiThemeProvider>}
        </div>;
    }
}

CircularProgress = withStyles(styles)(CircularProgress);
export default CircularProgress;
