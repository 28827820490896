'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Card from '@material-ui/core/Card';
// Icons
import RefreshIcon from '@material-ui/icons/Refresh';

/**
 * ErrorCard to display #404, unavailable API and other errors
 */
class ErrorCard extends React.Component {
    render() {
        return <Card>
            <CardHeader
                title={this.props.title}
                subheader={this.props.text}
                action={
                    <MuiThemeProvider theme={altTheme}>
                        <ActionButton iconButton disabled>
                            {this.props.icon}
                        </ActionButton>
                    </MuiThemeProvider>
                }
            />
            {this.props.refreshIcon &&
                <CardActions>
                    <Button variant='contained' color='primary'
                            onClick={() => window.location.reload()}>
                        <RefreshIcon />
                        <FormattedMessage id='errors.refresh' />
                    </Button>
                </CardActions>
            }
        </Card>;
    }
}

export default injectIntl(ErrorCard);
