'use strict';
import React from 'react';
// material-ui
import CardContent from '@material-ui/core/CardContent';


/**
 * Simple HTML portlet to renders HTML
 */
class HTML extends React.Component {
    // todo: this doesn't trigger <script />

    render() {
        if (this.props.portlet.getIn(['config', 'portlet_data', 'card'])) {
            return <CardContent dangerouslySetInnerHTML={{__html: this.props.portlet.getIn(['config', 'portlet_data', 'html'])}} />;
        } else {
            return <div dangerouslySetInnerHTML={{__html: this.props.portlet.getIn(['config', 'portlet_data', 'html'])}} />;
        }
    }
}

export default HTML;
