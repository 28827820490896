'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Icon representing Text Document
 */
export default class Document extends React.Component {
    static defaultProps = {
        viewBox: '0 0 58 58'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: theme.palette.grey[300]}} points='51.5,14 37.5,0 6.5,0 6.5,58 51.5,58' />
            <polygon style={{fill: theme.palette.grey[500]}} points='37.5,0 37.5,14 51.5,14' />
            <path style={{fill: theme.palette.primary[500]}} d='M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z' />
            <path style={{fill: theme.palette.primary[500]}} d='M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z' />
            <path style={{fill: theme.palette.primary[500]}} d='M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z' />
            <path style={{fill: theme.palette.primary[500]}} d='M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z' />
            <path style={{fill: theme.palette.primary[500]}} d='M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z' />
        </svg>;
    }
}
