'use strict';
import React from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, formValueSelector} from 'redux-form';
import validator from 'lib/valitator';
import {altTheme} from 'theme/index';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {addMessage} from 'actions/app';
import {setState as setAuthState, fetchInvitation, setInvitation, registerViaInvitation, loginProcedure} from 'actions/auth';
import {simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import ToSDialog from 'components/core/ui/ToSDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import InvitationIcon from '@material-ui/icons/AssignmentInd';
import SubmitIcon from '@material-ui/icons/Done';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const styles = theme => ({
    // Tos Field
    tos: {
        margin: '0 -7px 0 auto', // left align on side with rest of form
        '& label': {
            // flip label and checkbox
            margin: '0',
            flexDirection: 'row-reverse'
        }
    }
});

/**
 * Registration via invitation
 */
class Invitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasswords: false, // flag to display/hide passwords
            tosDialogOpen: false // open/close status of ToS dialog
        };
    };

    /**
     * During initialization fetch invitation data
     */
    componentDidMount() {
        this.props.fetchInvitation({token: this.props.match.params.token});
    }

    /**
     * Clear invitation on unmount
     */
    componentWillUnmount() {
        this.props.setInvitation(null);
    }

    /**
     * In case of invalid invitation token, redirect to Login
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // in case of invalid token redirect to login
        if (this.props.authState === 'invalid_token') {
            this.props.setAuthState(null);
            this.props.history.push('/');
        }
    }

    render() {
        return <Card>
            <CardHeader
                title={<FormattedMessage id='invitation.title' />}
                subheader={<FormattedMessage id='invitation.subheader' />}
                action={
                    <ActionButton iconButton disabled>
                        <InvitationIcon />
                    </ActionButton>
                }
            />
            <CardContent>
                {this.props.authState === 'fetching_invitation'
                    ? <LinearProgress />
                    : <Form onSubmit={this.props.handleSubmit} alignCenter>
                    <Field name='first_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'invitation.form.fields.first_name'})}*`} />
                    <Field name='last_name' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'invitation.form.fields.last_name'})}*`} />
                    <SpaceDivider none />
                    <Field name='email' type='email' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'invitation.form.fields.email'})}*`}
                           helperText={this.props.initialValues.email !== this.props.selectedEmail ? <FormattedMessage id='users.detail.basic.emailconfirm.info' /> : undefined}
                    />
                    <Field disabled name='company' fieldType='TextField' label={<FormattedMessage id='invitation.form.fields.company' />} />
                    <SpaceDivider none />
                    <Field name='password' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'invitation.form.fields.password'})}*`} />
                    <FieldWithIconHolder>
                        <Field withIcon name='password2' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'invitation.form.fields.password2'})}*`} />
                        <FieldIcon onClick={() => this.setState({showPasswords: !this.state.showPasswords})}>
                            {this.state.showPasswords ? <VisibilityOff /> : <Visibility />}
                        </FieldIcon>
                    </FieldWithIconHolder>
                    <SpaceDivider />
                    <MuiThemeProvider theme={altTheme}>
                        <Field name='tos' fieldType='Checkbox' color='primary'
                               label={<FormattedMessage id='tosfield.description'
                                                        values={{link: <a href='#' className='hover-border'
                                                                          onClick={(e) => { e.preventDefault(); this.setState({tosDialogOpen: true}); }}>
                                                            <FormattedMessage id='tosfield.description.link' />
                                                        </a>}} />}
                               className={this.props.classes.tos} />
                    </MuiThemeProvider>
                </Form>}
                <ToSDialog open={this.state.tosDialogOpen} handleClose={() => this.setState({tosDialogOpen: false})} />
            </CardContent>
            {['registering_via_invitation', 'posting_invitation_login', 'posted_invitation_login', 'register_failed'].includes(this.props.authState)
                ? <CardActionsLoader failure={this.props.authState === 'register_failed'}
                                     postAnimation={success => this.props.setAuthState(null)} />
                : <CardActions>
                <Button variant='contained' color='secondary' type='submit'
                        disabled={this.props.authState !== null}
                        onClick={() => this.props.authState === null ? this.props.handleSubmit() : {}}>
                    <SubmitIcon />
                    <FormattedMessage id='invitation.form.submit' />
                </Button>
            </CardActions>}
        </Card>;
    }
}

let validate = (data, props) => {
    const errors = {};

    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'invitation.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);
    validator.isTrue(
        props.intl.formatMessage({id: 'tosfield.error.required'}),
        errors, 'tos', data.tos
    );

    return errors;
};

Invitation = withStyles(styles)(Invitation);
Invitation = reduxForm({
    form: 'invitationForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => (
        dispatch(registerViaInvitation(values)).then(() => {
            // everything is alright, let's login User
            dispatch(simplePost('invitation_login', 'tokens', {token: values.token}, {setState: props.setAuthState})).then((result) => {
                dispatch(loginProcedure(result)).then(() => {
                    props.history.push('/');
                    return dispatch(addMessage({intl_id: 'invitation.success', path: '/'}));
                });
            });
        })
    )
})(Invitation);

Invitation = connect((state, props) => ({
    authState: state.auth.get('state'),
    invitation: state.auth.get('invitation'),
    selectedEmail: formValueSelector('invitationForm')(state, 'email'),
    initialValues: {
        company: state.auth.getIn(['invitation', 'company', 'name']),
        first_name: state.auth.getIn(['invitation', 'first_name']),
        last_name: state.auth.getIn(['invitation', 'last_name']),
        email: state.auth.getIn(['invitation', 'email']),
        token: props.match.params.token
    }
}), (dispatch) => bindActionCreators({
    setAuthState,
    addMessage,
    fetchInvitation,
    setInvitation,
    loginProcedure,
    simplePost,
    registerViaInvitation
}, dispatch))(Invitation);

export default injectIntl(withRouter(Invitation));
