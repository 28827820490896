'use strict';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {altTheme} from 'theme/index';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Actions
import {setState, addMessage} from 'actions/app';
import {fetchItems, simplePost} from 'actions/shared';
import {setPortletState, setSelected} from 'actions/portlets/general';
import {fetchTable} from 'actions/tables';
import Field from 'components/core/ui/Field';
import StickyTable from 'components/core/ui/StickyTable';
// Components
import Form from 'components/core/ui/Form';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// Icons
import CheckIcon from '@material-ui/icons/Check';
import UnCheckIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Undo';
import SubmitIcon from '@material-ui/icons/Send';


/**
 * Displays table with Leads. Clicking on them will open Contact Dialog
 */
class Leads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedItems: [], // stored this.props.items (leads) in sorted order
            sortName: 'created_at', // default value of sorting
            sortDirection: 'asc', // default direction of sorting ('desc' / 'asc')
            tableHead: [
                {
                    sortName1: 'first_name', sortName2: 'last_name',
                    label1: <FormattedMessage id='portlets.leads.table.first_name' />,
                    label2: <FormattedMessage id='portlets.leads.table.last_name' />
                },
                {sortName: 'created_at', label: <FormattedMessage id='portlets.leads.table.created_at' />},
                {sortName: 'city', label: <FormattedMessage id='portlets.leads.table.location' />},
                {sortName: 'company', label: <FormattedMessage id='portlets.leads.table.company' />},
                {sortName: 'contact_made', label: <FormattedMessage id='portlets.leads.table.contact_made' />, numeric: true},
            ]
        };
    }

    /**
     * During initialization sort items
     */
    componentDidMount() {
        // do we have data fetched?
        if (this.props.portletState !== 'ready' || this.props.loaded === false) {
            this.props.setPortletState(this.props.portlet, 'fetching');
            this.props.fetchItems(ImmutableMap, `portlet-${this.props.portlet.getIn(['config', 'id'])}-related`, this.props.portlet.getIn(['config', 'portlet_data', 'leads']), null, null, {affect_state: false}).then(() => {
                this.props.setPortletState(this.props.portlet, 'ready');
            }).catch(() => {
                this.props.setPortletState(this.props.portlet, 'error');
            });
        } else {
            this.sortItems(this.state.sortName, true);
        }

        // check if we have Countries loaded
        if (this.props.countries === null) {
            this.props.fetchTable('countries');
        }
    }

    /**
     * Watch for update of Collection to sort records
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // watch for location change
        if (prevProps.items !== this.props.items) {
            this.sortItems(this.state.sortName, true);
        }
    }

    /**
     * Locally sort Items by sortName
     *
     * @param sortName - field to sort with
     * @param first_time - First time sorting? Let's get items from props and not state
     */
    sortItems(sortName, first_time = false) {
        let sortDirection = this.state.sortDirection;
        // no sortName change? switch direction
        if (!first_time && this.state.sortName === sortName) {
            sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }
        // sort items
        let sortedItems = first_time ? this.props.items.toArray() : this.state.sortedItems;
        sortedItems = sortedItems.slice().sort((a, b) => {
            let a_field = typeof a.get(sortName) === 'string' ? a.get(sortName).toLowerCase() : a.get(sortName);
            let b_field = typeof b.get(sortName) === 'string' ? b.get(sortName).toLowerCase() : b.get(sortName);
            if (a_field < b_field) {
                return sortDirection === 'desc' ? -1 : 1;
            } else if (a_field > b_field) {
                return sortDirection === 'desc' ? 1 : -1;
            } else {
                return 0;
            }
        });
        // set to state
        this.setState({sortedItems: sortedItems, sortName: sortName, sortDirection: sortDirection});
    }

    render() {
        return <React.Fragment>
            <StickyTable
                header={<Table sticky size={this.state.tableHead.length}>
                    <TableHead>
                        <TableRow>
                            {this.state.tableHead.map((item, idx) =>
                                    <TableCell key={idx} numeric={item.numeric}>
                                        {item.sortName1 && item.sortName2
                                            ? <span>
                                <TableSortLabel active={this.state.sortName === item.sortName1}
                                                direction={this.state.sortDirection}
                                                onClick={() => this.sortItems(item.sortName1)}>
                                    {item.label1}
                                </TableSortLabel>
                                <TableSortLabel active={this.state.sortName === item.sortName2}
                                                direction={this.state.sortDirection}
                                                onClick={() => this.sortItems(item.sortName2)}>
                                    {item.label2}
                                </TableSortLabel>
                            </span>
                                            : <TableSortLabel active={this.state.sortName === item.sortName}
                                                              direction={this.state.sortDirection}
                                                              onClick={() => this.sortItems(item.sortName)}>
                                            {item.label}
                                        </TableSortLabel>}
                                    </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                </Table>}
                content={<Table size={this.state.tableHead.length}>
                    <TableBody>
                        {this.props.portletState === 'fetching'
                            ? <TableRow>
                            <TableCell colSpan={this.state.tableHead.length}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                            : this.props.portletState === 'error'
                            ? <CardContent>
                            <Typography variant='subtitle2'><FormattedMessage id='portlets.general.fetch_error' /></Typography>
                        </CardContent>
                            : !this.props.items.size
                            ? <TableRow>
                            <TableCell colSpan={this.state.tableHead.length}>
                                <FormattedMessage id='portlets.leads.table.empty' />
                            </TableCell>
                        </TableRow>
                            : this.state.sortedItems.map((item, idx) =>
                            <TableRow key={idx} link={!item.get('contact_made')} hover={!item.get('contact_made')}
                                      selected={this.props.selected && item.get('id') === this.props.selected.get('id')}
                                      onClick={() => !item.get('contact_made') ? this.props.setSelected(this.props.portlet, item) : {}}>
                                <TableCell>{`${item.get('first_name')} ${item.get('last_name')}`}</TableCell>
                                <TableCell>{Moment(item.get('created_at')).format('l, LT')}</TableCell>
                                <TableCell>{`${item.get('city')}${(this.props.countries && this.props.countries.find(el => el.get('id') === item.get('country'))) && ` (${this.props.countries.find(el => el.get('id') === item.get('country')).get('name')})`}`}</TableCell>
                                <TableCell>{item.get('company')}</TableCell>
                                <TableCell numeric>{item.get('contact_made') ? <CheckIcon color='primary' /> : <UnCheckIcon />}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>} />
            <Dialog open={!!this.props.selected} onClose={() => this.props.setSelected(this.props.portlet, null)}>
                <DialogTitle><FormattedMessage id='portlets.leads.dialog.title' /></DialogTitle>
                <DialogContent>
                    {this.props.selected
                        ? <DialogContentText>
                        <FormattedMessage id='portlets.leads.dialog.info'
                                          values={{
                                              name: <strong>{this.props.selected.get('first_name')} {this.props.selected.get('last_name')}</strong>,
                                              location: <strong>{this.props.selected.get('city')}{this.props.countries.find(el => el.get('id') === this.props.selected.get('country')) && ` (${this.props.countries.find(el => el.get('id') === this.props.selected.get('country')).get('name')})`}</strong>
                                          }} />
                    </DialogContentText>
                        : <SpaceDivider height='40px' />}
                    <SpaceDivider />
                    <Form onSubmit={this.props.handleSubmit} alignCenter>
                        <Field name='text' fieldType='TextArea' label={`${this.props.intl.formatMessage({id: 'portlets.leads.dialog.form.fields.text'})}*`} />
                    </Form>
                    <SpaceDivider double />
                </DialogContent>
                {['posting_contact_lead', 'posted_contact_lead', 'failed_contact_lead'].includes(this.props.state)
                    ? <CardActionsLoader dialogActions
                                         success={this.props.state === 'posted_contact_lead'}
                                         failure={this.props.state === 'failed_contact_lead'}
                                         postAnimation={success => {
                                             // clear state
                                             this.props.setState(null);
                                             if (success) {
                                                 // success message
                                                 this.props.addMessage({
                                                     intl_id: 'portlets.leads.dialog.success',
                                                     path: 'on-change'
                                                 });
                                                 // close
                                                 this.props.setSelected(this.props.portlet, null);
                                             }
                                         }} />
                    : <MuiThemeProvider theme={altTheme}>
                    <DialogActions>
                        <Button onClick={() => this.props.setSelected(this.props.portlet, null)}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        <Button disabled={this.props.state !== null}
                                variant='contained' color='primary' type='submit'
                                onClick={() => this.props.state === null ? this.props.handleSubmit() : {}}>
                            <SubmitIcon />
                            <FormattedMessage id='portlets.leads.dialog.submit' />
                        </Button>
                    </DialogActions>
                </MuiThemeProvider>}
            </Dialog>
        </React.Fragment>;
    }
}

let validate = (data) => {
    const errors = {};

    validator.isNotNull(null, errors, 'text', data.text);

    return errors;
};

Leads = reduxForm({
    form: 'leadsForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmit: (values, dispatch, props) => {
        return dispatch(simplePost('contact_lead', props.selected.get('contact_url'), values)).then(() => {
            return dispatch(fetchItems(ImmutableMap, `portlet-${props.portlet.getIn(['config', 'id'])}-related`, props.portlet.getIn(['config', 'portlet_data', 'leads']), null, null, {affect_state: false})).then(() => {
                return dispatch(reduxFormReset('leadsForm'));
            });
        });
    }
})(Leads);

Leads = connect((state, props) => ({
    state: state.app.get('state'),
    portletState: state.portlets_general.getIn(['state', props.portlet.get('url')]),
    loaded: !!state.shared.getIn(['loaded', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]),
    items: state.shared.getIn(['items', `portlet-${props.portlet.getIn(['config', 'id'])}-related`]) || ImmutableList(),
    selected: state.portlets_general.getIn(['selected', props.portlet.getIn(['config', 'id'])]) || null,
    countries: state.tables.getIn(['tables', 'countries'])
}), (dispatch) => bindActionCreators({
    setSelected,
    setPortletState,
    fetchItems,
    simplePost,
    setState,
    addMessage,
    fetchTable,
    reduxFormReset
}, dispatch))(Leads);

export default injectIntl(Leads);
