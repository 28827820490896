'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Icon representing Website (html, php, etc.)
 */
export default class Web extends React.Component {
    static defaultProps = {
        viewBox: '0 0 504.124 504.124'
    };

    render() {
        return <svg {...this.props}>
            <rect style={{fill: theme.palette.grey[300]}} width='504.124' height='504.124' />
            <path style={{fill: theme.palette.primary[500]}} d='M47.262,31.508c-8.665,0-15.754,7.089-15.754,15.754s7.089,15.754,15.754,15.754 s15.754-7.089,15.754-15.754S55.926,31.508,47.262,31.508z M94.523,31.508c-8.665,0-15.754,7.089-15.754,15.754 s7.089,15.754,15.754,15.754s15.754-7.089,15.754-15.754S103.188,31.508,94.523,31.508z M141.785,31.508 c-8.665,0-15.754,7.089-15.754,15.754s7.089,15.754,15.754,15.754s15.754-7.089,15.754-15.754S150.449,31.508,141.785,31.508z' />
            <path style={{fill: theme.palette.primary[500]}} d='M47.262,94.523h409.6c8.665,0,15.754,7.089,15.754,15.754v346.585 c0,8.665-7.089,15.754-15.754,15.754h-409.6c-8.665,0-15.754-7.089-15.754-15.754V110.277 C31.508,101.612,38.597,94.523,47.262,94.523z' />
            <path style={{fill: theme.palette.primary[800]}} d='M110.277,165.415h228.431c4.332,0,7.877,3.545,7.877,7.877v7.877c0,4.332-3.545,7.877-7.877,7.877 H110.277c-4.332,0-7.877-3.545-7.877-7.877v-7.877C102.4,168.96,105.945,165.415,110.277,165.415z' />
            <path style={{fill: theme.palette.secondary[500]}} d='M204.8,220.554h-94.523c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877 H204.8c4.332,0,7.877-3.545,7.877-7.877v-7.877C212.677,224.098,209.132,220.554,204.8,220.554z M181.169,330.831h-70.892 c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877h70.892c4.332,0,7.877-3.545,7.877-7.877v-7.877 C189.046,334.375,185.502,330.831,181.169,330.831z M393.846,330.831h-47.262c-4.332,0-7.877,3.545-7.877,7.877v7.877 c0,4.332,3.545,7.877,7.877,7.877h47.262c4.332,0,7.877-3.545,7.877-7.877v-7.877C401.723,334.375,398.178,330.831,393.846,330.831z ' />
            <path style={{fill: theme.palette.primary[800]}} d='M393.846,220.554H252.062c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877 h141.785c4.332,0,7.877-3.545,7.877-7.877v-7.877C401.723,224.098,398.178,220.554,393.846,220.554z M393.846,385.969H110.277 c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877h283.569c4.332,0,7.877-3.545,7.877-7.877v-7.877 C401.723,389.514,398.178,385.969,393.846,385.969z' />
            <path style={{fill: theme.palette.secondary[500]}} d='M299.323,275.692H110.277c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877 h189.046c4.332,0,7.877-3.545,7.877-7.877v-7.877C307.2,279.237,303.655,275.692,299.323,275.692z M299.323,330.831h-70.892 c-4.332,0-7.877,3.545-7.877,7.877v7.877c0,4.332,3.545,7.877,7.877,7.877h70.892c4.332,0,7.877-3.545,7.877-7.877v-7.877 C307.2,334.375,303.655,330.831,299.323,330.831z' />
        </svg>;
    }
}
