'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// material-ui
import MUICardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    // holder of CardHeader
    cardHeaderHolder: {
        // contains back button
        '&.back': {
            position: 'relative',
            paddingLeft: `${48 + theme.spacing(1)}px`, // increase left padding by size of button
            // icon button
            '& > button': {
                position: 'absolute',
                top: `${theme.spacing(1)}px`,
                left: `${theme.spacing(2)}px`
            }
        }
    },
    // cardHeader + cardContent (subheader) close together
    cardHeaderRoot: {
        paddingBottom: '0',
        alignItems: 'flex-start',
        minHeight: '64px',
        '&.center': {
            textAlign: 'center'
        }
    },
    cardSubheader: {
        paddingTop: '0',
        '&.center': {
            textAlign: 'center'
        }
    }
});

/**
 * Extends CardHeader from material-ui to rewrite some of the styles
 */
class CardHeader extends React.Component {
    render() {
        // Split some stuff from props
        let {subheader, backButton, className, classes, _classes, center, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root, action, subheaderText, holder, ...rest_of_classes} = _classes || {};
        let holderClasses = [classes.cardHeaderHolder, holder, backButton ? 'back' : ''].filter(Boolean).join(' ');
        let rootClasses = [classes.cardHeaderRoot, root, className, center ? 'center' : ''].filter(Boolean).join(' ');
        let subheaderClasses = [classes.cardSubheader, center ? 'center' : ''].filter(Boolean).join(' ');
        let actionClasses = [classes.cardHeaderAction, action].filter(Boolean).join(' ');

        return <div>
            <div className={holderClasses}>
                {backButton && backButton}
                <MUICardHeader
                    classes={{
                        root: rootClasses,
                        action: actionClasses,
                        ...rest_of_classes
                    }}
                    {...rest_of_props} />
            </div>
            <CardContent className={subheaderClasses}>
                <Typography color='textSecondary' className={subheaderText}>{subheader}</Typography>
            </CardContent>
        </div>;
    }
}

CardHeader = withStyles(styles)(CardHeader);
export default CardHeader;
