'use strict';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {PagePortlet} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import BasePortlet from 'components/modules/portlets/Base';
import Submissions from 'components/modules/portlets/Submissions';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';


/**
 * Dynamic Page to renders Page with Portlets from backend
 *
 * this.props.page provided from Application.js
 */
class DynamicPage extends React.Component {
    /**
     * During mount fetch Page Portlets if needed
     */
    componentDidMount() {
        // check if we have portlets fetched
        if (this.props.loaded === false) {
            this.props.fetchItems(PagePortlet, `portlets-${this.props.page.get('slug')}`, this.props.page.get('page_portlet_set'));
        }
    }

    render() {
        return <React.Fragment>
            {this.props.state === `fetching_items_portlets-${this.props.page.get('slug')}`
                ? <React.Fragment>
                <SpaceDivider double />
                <LinearProgress />
                <SpaceDivider double />
            </React.Fragment>
                : <Row wrap undivider>
                {this.props.page.get('slug') === 'submissions' && <React.Fragment>
                    <Col width='100%' divider>
                        <Submissions />
                    </Col>
                </React.Fragment>}
                {this.props.items.map((portlet, idx) =>
                    <Col key={idx} width={portlet.getWidth()} divider>
                        <BasePortlet portlet={portlet} />
                    </Col>
                )}
            </Row>}
        </React.Fragment>;
    }
}

DynamicPage = connect((state, props) => ({
    state: state.app.get('state'),
    loaded: !!state.shared.getIn(['loaded', `portlets-${props.page.get('slug')}`]),
    items: state.shared.getIn(['items', `portlets-${props.page.get('slug')}`]) || ImmutableList()
}), (dispatch) => bindActionCreators({
    fetchItems
}, dispatch))(DynamicPage);

export default injectIntl(withRouter(DynamicPage));
