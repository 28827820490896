'use strict';
import {hot} from 'react-hot-loader/root';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch, Redirect} from 'react-router';
import validator, {initValidator} from 'lib/valitator';
import {connect} from 'react-redux';
// Components
import FormRestoration from 'components/core/ui/FormRestoration';
import CustomerLogo from 'components/core/ui/CustomerLogo';
import Error from 'components/core/ui/Error';
import Messages from 'components/core/ui/Messages';
import Header from 'components/core/ui/Header';
import Menu from 'components/core/ui/Menu';
import Footer from 'components/core/ui/Footer';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Container} from 'components/core/ui/Grid';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// Pages
import Login from 'components/modules/auth/Login';
import ForgottenPassword from 'components/modules/auth/ForgottenPassword';
import Register from 'components/modules/auth/Register';
import Invitation from 'components/modules/auth/Invitation';
import ToSUpdate from 'components/modules/users/ToSUpdate';
import Profile from 'components/modules/users/Detail';
import ConfirmEmail from 'components/modules/users/ConfirmEmail';
import Users from 'components/modules/users/List';
import Company from 'components/modules/company/Detail';
import RemoteAction from 'components/modules/app/RemoteAction';
import DynamicPage from 'components/modules/DynamicPage';
import NotFoundPage from 'components/modules/NotFoundPage';
// Icons
import ErrorIcon from '@material-ui/icons/Error';
import UnavailableIcon from '@material-ui/icons/SignalCellularOff';


const global = theme => ({
    '@global': {
        '*, *:before, *:after': {
            boxSizing: 'border-box',
            outline: '0 !important'
        },
        'button::-moz-focus-inner': {
            border: '0'
        },
        'html': {
            minHeight: '100%',
            position: 'relative',
            overflowY: 'scroll'
        },
        'body': {
            background: theme.palette.grey[100],
            margin: '0',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.body2.lineHeight,
            color: theme.palette.text['primary'],
            // size of footer
            paddingBottom: '73px',
            [theme.breakpoints.up('md')]: {
                paddingBottom: '53px'
            }
        },
        'p': {
            ...theme.typography.body2
        },
        'h1': {
            ...theme.typography.h1
        },
        'h2': {
            ...theme.typography.h2
        },
        'h3': {
            ...theme.typography.h3
        },
        'h4': {
            ...theme.typography.h4
        },
        'h5': {
            ...theme.typography.h5
        },
        'h6': {
            ...theme.typography.h6
        },
        '.subtitle1': {
            ...theme.typography.subtitle1
        },
        '.subtitle2': {
            ...theme.typography.subtitle2
        },
        '.text-right': {
            textAlign: 'right'
        },
        '.text-center': {
            textAlign: 'center'
        },
        '.text-justify': {
            textAlign: 'justify'
        },
        '.notmargin': {
            marginTop: '0'
        },
        '.nobmargin': {
            marginBottom: '0'
        },
        '.nomargin': {
            margin: '0'
        },
        'a': {
            lineHeight: '1', // for better v-align
            display: 'inline-block',
            verticalAlign: 'middle',
            color: theme.palette.primary[500],
            textDecoration: 'none',
            transition: theme.transitions.create('color',
                {duration: theme.transitions.duration.short}),
            // optional hover-border effect
            '&.hover-border': {
                verticalAlign: 'baseline'
            },
            '&.hover-border:after': {
                content: "''",
                width: '0',
                height: '1px',
                background: 'transparent',
                display: 'block',
                margin: '0 auto',
                transition: theme.transitions.create(['background', 'width'],
                    {duration: theme.transitions.duration.short}),
            },
            '&:hover': {
                color: theme.palette.primary[700]
            },
            '&.hover-border:hover:after': {
                background: theme.palette.primary[700],
                width: '100%'
            }
        },
        'hr': {
            margin: `${theme.spacing(2)}px auto`,
            height: '1px',
            width: '100%',
            border: 'none',
            background: theme.palette.grey[300]
        },
        'img, svg, iframe': {
            maxWidth: '100%',
            border: '0'
        },
        // 16:9 for iframes
        '.embed': {
            paddingBottom: '56.25%',
            position: 'relative',
            background: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            '& iframe': {
                position: 'absolute',
                left: '0',
                top: '0',
                bottom: '0',
                right: '0',
                height: '100% !important',
                width: '100% !important'
            }
        },
        'table': {
            tableLayout: 'fixed',
            borderSpacing: '0',
            borderCollapse: 'collapse',
            width: '100%',
            maxWidth: '100%',
            '& tr, & td': {
                padding: '0',
                margin: '0'
            }
        },
        'pre': {
            fontFamily: "'Roboto Mono', monospace",
            margin: 0
        },
        'code': {
            display: 'block',
            fontFamily: "'Roboto Mono', monospace",
            padding: `${theme.spacing(1)}px`,
            background: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius
        }
    },
    contentHolder: {
        display: 'flex',
        width: '100%'
    },
    main: {
        flexGrow: '1',
        minWidth: '0'
    }
});

const styles = theme => ({
    ...global(theme)
});

/**
 * Main application to render routes
 */
class Application extends React.Component {
    constructor(props) {
        super(props);
        // shared routes between logged and unlogged user
        this.sharedRoutes = [
            <Route key='/settings/confirm-email/:token' path='/settings/confirm-email/:token' exact component={ConfirmEmail} />,
            <Route key='/remote-action/:token' path='/remote-action/:token' exact component={RemoteAction} />
        ];
    }

    /**
     * Localize validator
     */
    componentDidMount() {
        validator.defaultMessages = {
            isNotNull: this.props.intl.formatMessage({id: 'errors.validator.required'}),
            isURL: this.props.intl.formatMessage({id: 'errors.validator.url'}),
            isEmail: this.props.intl.formatMessage({id: 'errors.validator.email'}),
            isNumeric: this.props.intl.formatMessage({id: 'errors.validator.number'}),
            isHexColor: this.props.intl.formatMessage({id: 'errors.validator.hexcolor'}),
            isIP: this.props.intl.formatMessage({id: 'errors.validator.ipaddress'}),
            isURLList: [
                this.props.intl.formatMessage({id: 'errors.validator.url_list_1'}),
                this.props.intl.formatMessage({id: 'errors.validator.url_list_2'})
            ],
            isSlug: this.props.intl.formatMessage({id: 'errors.validator.slug'}),
            isDateTime: this.props.intl.formatMessage({id: 'errors.validator.datetime'}),
            __generic__: this.props.intl.formatMessage({id: 'errors.validator.generic'})
        };
        initValidator(validator);
    }

    /**
     * Listen to history to make Page jump to top
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // route change, excluding '/add' -> '/detail' change
        let prevLocationSplit = prevProps.location.pathname.split('/');
        let newLocationSplit = this.props.location.pathname.split('/');
        let prevLocationPop = prevLocationSplit.pop();
        newLocationSplit.pop();
        if (prevProps.location.pathname !== this.props.location.pathname && !(prevLocationPop === 'add' && prevLocationSplit.toString() === newLocationSplit.toString())) {
            window.scrollTo(0, 0); // jump to top (as normal website behaviour)
        }
    }

    render() {
        return <React.Fragment>
            {this.props.config === null
                ? <React.Fragment />
                : this.props.config.get('customer') === 'snom'
                    ? <Helmet>
                    <title>Snom Portal</title>
                    <link rel='shortcut icon' href={require('img/favicons/favicon_snom.ico')} />
                </Helmet>
                    : this.props.config.get('customer') === 'asisi'
                    ? <Helmet>
                    <title>Asisi Portal</title>
                    <link rel='shortcut icon' href={require('img/favicons/favicon_asisi.ico')} />
                </Helmet>
                    : <Helmet>
                    <title>Portal</title>
                    <link rel='shortcut icon' href={require('img/favicons/favicon.ico')} />
                </Helmet>}
            <Header />
            <div className={this.props.classes.contentHolder}>
                {this.props.apiState === 'ready' && this.props.authState === 'logged' && <Menu />}
                <div className={this.props.classes.main}>
                    <SpaceDivider grid />
                    {this.props.apiState === 'ready' && this.props.authState !== 'logging_out'
                        ? this.props.authState === 'logged'
                            ? <Container>
                        <Error />
                        <Messages />
                        <Switch>
                            {this.sharedRoutes.map((route, idx) =>
                                route
                            )}
                            {this.props.pages_loaded && this.props.pages_items.map((page, idx) =>
                                <Route key={page.slug} path={idx === 0 ? '/' : `/${page.slug}`} exact component={() => <DynamicPage page={page} />} />
                            )}
                            {(this.props.pages_loaded && this.props.pages_items.first()) && <Redirect to='/' exact path={`/${this.props.pages_items.first().slug}`} />}
                            <Route path='/settings/profile' exact component={Profile} />
                            {this.props.user.isAdmin() && [
                                <Route key='/settings/users' path='/settings/users' exact component={Users} />,
                                <Route key='/settings/company' path='/settings/company' exact component={Company} />
                            ].map((route, idx) =>
                                route
                            )}
                            {this.props.pages_loaded &&
                                <Route component={NotFoundPage} />
                            }
                        </Switch>
                    </Container>
                            : <Container limited>
                        <Error />
                        <Messages />
                        <CustomerLogo className={'nomargin'} noPartnerLevel />
                        <SpaceDivider />
                        <Switch>
                            {this.sharedRoutes.map((route, idx) =>
                                route
                            )}
                            <Route path={['/registration', '/registration/:token']} exact component={Register} />
                            <Route path='/invitation/:token' exact component={Invitation} />
                            <Route path={['/forgotten-password', '/forgotten-password/:token/:id']} exact component={ForgottenPassword} />
                            <Route path='/' exact component={Login} />
                            <Redirect to='/' />
                        </Switch>
                    </Container>
                        : this.props.apiState === 'fetching' || this.props.authState === 'logging_out'
                        ? <Container limited>
                        <Card>
                            <CardContent>
                                <SpaceDivider loading />
                                <LinearProgress />
                                <SpaceDivider loading />
                            </CardContent>
                        </Card>
                    </Container>
                        : this.props.apiState === 'tos_update'
                        ? <Container limited>
                        <Error />
                        <Messages />
                        <Route path='/' component={ToSUpdate} />
                    </Container>
                        : this.props.apiState === 'unavailable'
                        ? <Container limited>
                        <ErrorCard
                            refreshIcon
                            title={<FormattedMessage id='errors.unavailable.title' />}
                            text={<FormattedMessage id='errors.unavailable.text' />}
                            icon={<UnavailableIcon color='secondary' />} />
                    </Container>
                        : this.props.apiState === 'error'
                        ? <Container limited>
                        <ErrorCard
                            refreshIcon
                            title={<FormattedMessage id='errors.general.title' />}
                            text={<FormattedMessage id='errors.general.text' />}
                            icon={<ErrorIcon color='secondary' />} />
                    </Container>
                        : <div />}
                    <SpaceDivider grid />
                    <Footer />
                </div>
            </div>
            <FormRestoration />
        </React.Fragment>;
    }
}

Application = withStyles(styles)(Application);
Application = connect((state) => ({
    apiState: state.api.get('state'),
    config: state.api.get('config'),
    authState: state.auth.get('state'),
    user: state.auth.get('user'),
    pages_loaded: state.shared.getIn(['loaded', 'pages']),
    pages_items: state.shared.getIn(['items', 'pages'])
}))(Application);

Application = withRouter(injectIntl(Application));
// add react-hot-loader
if (process.env.NODE_ENV !== 'production') {
    Application = hot(Application);
}
export default Application;
