'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import Moment from 'moment';
// Components
import {Container} from 'components/core/ui/Grid';
import ToSDialog from 'components/core/ui/ToSDialog';


const styles = theme => ({
    root: {
        // position
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        // fixed size of footer
        height: '73px',
        [theme.breakpoints.up('md')]: {
            height: '53px'
        },
        // line
        '& hr': {
            marginTop: '0'
        },
        // text
        '& p': {
            margin: '0',
            paddingBottom: `${theme.spacing(2)}px`,
            textAlign: 'center',
            // responsive large
            [theme.breakpoints.up('md')]: {
                '& > br': {
                    display: 'none'
                }
            }
        }
    }
});

/**
 * Application Footer
 */
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tosDialogOpen: false // open/close status of ToS dialog
        };
    };

    render() {
        return <div className={`${this.props.classes.root} mui-fixed`}>
            <Container full>
                <hr />
                <p>
                    <span>&copy;&nbsp;{Moment().year()}{' Awesome Developers UG. '}</span>
                    <br />
                    <span><FormattedMessage id='footer.rights' />{'. '}<a href='#' className='hover-border' onClick={(e) => { e.preventDefault(); this.setState({tosDialogOpen: true}); }}>
                        <FormattedMessage id='footer.tos' />
                    </a>{' '}</span>
                </p>
            </Container>
            <ToSDialog open={this.state.tosDialogOpen} handleClose={() => this.setState({tosDialogOpen: false})} />
        </div>;
    }
}

Footer = withStyles(styles)(Footer);
export default injectIntl(Footer);
