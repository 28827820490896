'use strict';
import React from 'react';
import {theme} from 'theme/index';


/**
 * Icon representing Archive (zip, rar, etc.)
 */
export default class Archive extends React.Component {
    static defaultProps = {
        viewBox: '0 0 56 56'
    };

    render() {
        return <svg {...this.props}>
            <polygon style={{fill: theme.palette.grey[300]}} points='51.5,14 37.5,0 6.5,0 6.5,58 51.5,58' />
            <polygon style={{fill: theme.palette.grey[500]}} points='37.5,0 37.5,14 51.5,14' />
            <g transform='translate(1.5,2)'>
                <rect style={{fill: theme.palette.primary[500]}} x='26.5' y='42' width='2' height='14' />
                <polygon style={{fill: theme.palette.primary[500]}} points='24.5,25 24.5,27 26.5,27 26.5,29 24.5,29 24.5,31 26.5,31 26.5,34 28.5,34 28.5,31 30.5,31 30.5,29 28.5,29 28.5,27 30.5,27 30.5,25 28.5,25 28.5,23 30.5,23 30.5,21 28.5,21 28.5,19 26.5,19 26.5,21 24.5,21 24.5,23 26.5,23 26.5,25 ' />
                <rect style={{fill: theme.palette.primary[500]}} x='23.5' y='34' width='8' height='8' />
                <path style={{fill: theme.palette.primary[800]}} d='m 32.5,43 -10,0 0,-10 10,0 0,10 z m -8,-2 6,0 0,-6 -6,0 0,6 z' />
                <rect style={{fill: theme.palette.primary[800]}} x='23.5' y='36' width='8' height='2' />
            </g>
        </svg>;
    }
}
