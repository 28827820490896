'use strict';
import {constants} from 'reducers/files';


/**
 * Simple action creator to add File to store
 *
 * @param file - File from DropFile component
 */
export function setFile(file) {
    return {
        type: constants.FILES_STORE_FILE,
        file
    };
}
