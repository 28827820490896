'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// Actions
import {removeMessage} from 'actions/app';
// Components
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
    card: {
        background: theme.palette.grey[500],
        border: '0',
        // green card
        '&.success': {
            background: theme.palette.success[500]
        },
        // red card
        '&.error': {
            background: theme.palette.danger[500]
        }
    },
    cardHeader: {
        alignItems: 'center', // center no matter how many lines text have
        marginBottom: `-${theme.spacing(1)}px` // ensure that top and bottom spaces are the same
    },
    cardHeaderTitle: {
        // lower the size of text to indicate 'message'
        ...theme.typography.subtitle1
    },
    cardText: {
        // readable color (since we have background)
        color: theme.palette.common.white,
        // change style of link
        '& a': {
            fontWeight: theme.typography.fontWeightMedium,
            '&, &:hover': {
                color: theme.palette.common.white
            },
            '&.hover-border': {
                '&:after, &:hover:after': {
                    background: theme.palette.common.white
                }
            }
        }
    }
});

/**
 * Renders informative messages for logged User
 */
class Messages extends React.Component {
    /**
     * Watch for router location change to close the message
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // check for change
        if (this.props.messages.size && this.props.location.pathname !== prevProps.location.pathname) {
            // iterate through message
            this.props.messages.map((message, idx) => {
                // check if message path doesn't match or remove path is reached
                if ((message.get('path') !== null && (message.get('path') === 'on-change' || !this.props.location.pathname.startsWith(message.get('path')))) ||
                    (message.get('remove_paths') !== null && message.get('remove_paths').filter(path => this.props.location.pathname.startsWith(path)).size)) {
                    this.props.removeMessage(idx);
                }
            });
        }
    }

    render() {
        return <div>
            {this.props.messages.map((message, idx) =>
                <div key={idx}>
                    <Card className={`${this.props.classes.card} ${message.get('type')}`}>
                        <CardHeader
                            title={<span>{message.get('intl_id')
                                ? <FormattedMessage id={message.get('intl_id')}
                                                    values={message.get('intl_values') ? message.get('intl_values').toJS() : {}} />
                                : message.get('text')}
                            </span>}
                            action={<div>
                                <Tooltip title={<FormattedMessage id='actions.close' />}>
                                    <ActionButton iconButton onClick={() => this.props.removeMessage(idx)}>
                                        <CloseIcon className={this.props.classes.cardText} />
                                    </ActionButton>
                                </Tooltip>
                            </div>}
                            _classes={{
                                root: this.props.classes.cardHeader,
                                title: `${this.props.classes.cardHeaderTitle} ${this.props.classes.cardText}`
                            }}
                        />
                    </Card>
                    <SpaceDivider grid />
                </div>
            )}
        </div>;
    }
}

Messages = withStyles(styles)(Messages);
Messages = connect((state) => ({
    state: state.app.get('state'),
    messages: state.app.get('messages')
}), (dispatch) => bindActionCreators({
    removeMessage
}, dispatch))(Messages);

export default injectIntl(withRouter(Messages));
