'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {updateProfile} from 'actions/users';
// Components
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


/**
 * Changes User password, requires current password, new password and confirmation
 */
class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasswords: false // flag to display/hide passwords
        };
    }

    render() {
        return <div>
            <CardHeader
                title={<FormattedMessage id='users.detail.password.title' />}
                subheader={<FormattedMessage id='users.detail.password.subheader' />}
            />
            <CardContent>
                <Form onSubmit={this.props.handleSubmit} alignCenter>
                    <Field name='current_password' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='users.detail.password.form.fields.current_password' />} />
                    <SpaceDivider none />
                    <Field name='password' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='users.detail.password.form.fields.password' />} />
                    <SpaceDivider none />
                    <FieldWithIconHolder>
                        <Field withIcon name='password2' type={this.state.showPasswords ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='users.detail.password.form.fields.password2' />} />
                        <FieldIcon onClick={() => this.setState({showPasswords: !this.state.showPasswords})}>
                            {this.state.showPasswords ? <VisibilityOff /> : <Visibility />}
                        </FieldIcon>
                    </FieldWithIconHolder>
                </Form>
                <SpaceDivider />
            </CardContent>
        </div>;
    }
}

let validate = (data, props) => {
    const errors = {};

    validator.isNotNull(null, errors, 'current_password', data.current_password) &&
    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'users.detail.password.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);

    return errors;
};

PasswordChange = reduxForm({
    form: 'userPasswordForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => (
        dispatch(updateProfile(values)).then(() => {
            return dispatch(reduxFormReset('userPasswordForm')); // clear form
        })
    )
})(PasswordChange);

PasswordChange = connect((state) => ({
    user: state.auth.get('user'),
    initialValues: {
        url: state.auth.getIn(['user', 'url'])
    }
}), (dispatch) => bindActionCreators({
    updateProfile,
    reduxFormReset
}, dispatch))(PasswordChange);

export default injectIntl(PasswordChange);
